(function ($) {

	$.fn.detachWithPlaceholder = function () {
		return this.each(function () {
			var element = $(this);

			if (!element.data('detached-placeholder')) {
				var placeholder = $('<span>', {
					css: {
						position: 'absolute',
						top: -99999,
						left: -99999
					}
				}).insertAfter(element);

				element.detach();
				element.data('detached-placeholder', placeholder);
			}
		});
	};

	$.fn.attachToPlaceholder = function () {
		return this.each(function () {
			var element = $(this);
			var placeholder = element.data('detached-placeholder');

			if (placeholder) {
				placeholder.replaceWith(element);
				element.removeData('detached-placeholder');

				placeholder = null;
			}
		});
	};
}(jQuery));