// Built with this tutorial: https://www.sitepoint.com/build-javascript-countdown-timer-no-dependencies/
/* eslint-disable */

$(function () {
	'use strict';
    
    var countdown = document.querySelector('[data-countdown-date]');
	
	function getTimeRemaining(endtime){
		var total = new Date(endtime).getTime() - new Date().getTime();
		// var total = Date.parse(endtime) - Date.parse(new Date());
		var seconds = Math.floor( (total/1000) % 60 );
		var minutes = Math.floor( (total/1000/60) % 60 );
		var hours = Math.floor( (total/(1000*60*60)) % 24 );
		var days = Math.floor( total/(1000*60*60*24) );
      
		return {
			total: total,
			days: days,
			hours: hours,
			minutes: minutes,
			seconds: seconds
		};
	}

	function initializeCountdown(id, endtime) {
		var countdown = document.getElementById(id);
		var numberOfDays = countdown.querySelector('[data-counting="days"]');
		var numberOfHours = countdown.querySelector('[data-counting="hours"]');
		var numberOfMinutes = countdown.querySelector('[data-counting="minutes"]');
		var numberOfSeconds = countdown.querySelector('[data-counting="seconds"]');
        var unitDays = countdown.querySelector('[data-counting-unit="days"]');
		var unitHours = countdown.querySelector('[data-counting-unit="hours"]');
		var unitMinutes = countdown.querySelector('[data-counting-unit="minutes"]');
		var unitSeconds = countdown.querySelector('[data-counting-unit="seconds"]');
		
		function updateCountdown() {
			var t = getTimeRemaining(endtime);
			if(numberOfDays) { numberOfDays.innerHTML = t.days; }
			if(numberOfHours) { numberOfHours.innerHTML = t.hours; }
			if(numberOfMinutes) { numberOfMinutes.innerHTML = t.minutes; }
			if(numberOfSeconds) { numberOfSeconds.innerHTML = t.seconds; }

			if (t.total <= 0) {
				clearInterval(timeinterval);
                numberOfDays.innerHTML = '0';
                numberOfHours.innerHTML = '0';
                numberOfMinutes.innerHTML = '0';
                numberOfSeconds.innerHTML = '0';
			}

			// Singular or Plural for the units
			if(numberOfDays && unitDays) {
				if ( numberOfDays.innerHTML === '1') {
					unitDays.innerHTML = unitDays.getAttribute('data-translation-unit');
				} else {
					unitDays.innerHTML = unitDays.getAttribute('data-translation-units');
				}
			}
            if(numberOfHours && unitHours) {
				if ( numberOfHours.innerHTML === '1') {
					unitHours.innerHTML = unitHours.getAttribute('data-translation-unit');
				} else {
					unitHours.innerHTML = unitHours.getAttribute('data-translation-units');
				}
			}

			if(numberOfMinutes && unitMinutes) {
				if ( numberOfMinutes.innerHTML === '1') {
					unitMinutes.innerHTML = unitMinutes.getAttribute('data-translation-unit');
				} else {
					unitMinutes.innerHTML = unitMinutes.getAttribute('data-translation-units');
				}
			}

			if(numberOfSeconds && unitSeconds) {
				if ( numberOfSeconds.innerHTML === '1') {
					unitSeconds.innerHTML = unitSeconds.getAttribute('data-translation-unit');
				} else {
					unitSeconds.innerHTML = unitSeconds.getAttribute('data-translation-units');
				}
			}
		}
		updateCountdown();
		var timeinterval = setInterval(updateCountdown, 1000);
	}

    // To avoid that the script is running all over I'm checking if the element is present
    if(countdown) {
	    var countDownDate = countdown.getAttribute('data-countdown-date');
        initializeCountdown('c-countdown__counter', countDownDate);
    }

});