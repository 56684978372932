var doNotClosePopup = false;
var testBool = false;
(function ($) {
	'use strict';

	// All added dialogs
	var dialogs = [];

	// z-index of topmost dialog
	var z = 1;

	// Dialog container
	var container;

	/**
	Dialog
	*/
	var Dialog = function (options) {
		var self = this;

		this.options = $.extend({
			// Extra dialog class
			extraClass: '',
			showDialogTitle: true,
			closeText: '',
			onlyDesktop: false
		}, options || {});

		// No previous dialog has been created
		if (!container && this.isDialog()) {
			// Create container
			container = $('<div>', {
				id: 'dialogs'
			}).appendTo('#body');
		}

		// Create dialog
		this.dialogElement = $('<div>', {
			'class': 'dialog',
			'id': 'dialog-' + z,
			'role': 'dialog'
		});

		this.dialogElement.addClass(this.options.extraClass);

		// Create and append content container
		this.contentElement = $('<div>', {
			'class': 'dialog-content',
			'aria-live': 'assertive'
		}).appendTo(this.dialogElement);

		// Create and append close button
		this.closeElement = $('<button>', {
			'type': 'button',
			'class': 'dialog-close',
			'aria-label': this.options.closeText,
			'html': this.options.closeText,
			'click': function (e) {
				e.preventDefault();
				self.close(true);
			}
		}).prependTo(this.dialogElement);

		dialogs.push(this);
	};

	Dialog.prototype = {
		dispose: function () {
			var self = this;
			// Remove elements
			this.dialogElement.remove();

			// Remove from array
			$.each(dialogs, function (index, dialog) {
				if (dialog === self) {
					dialogs.splice(index, 1);
					// Exit each loop
					return false;
				}
			});
		},
		setContent: function (content) {
			this.contentElement.empty().append(content);
			if (this.isDialog()) {
				this.position();
			}

			this.dialogElement.trigger('load.netrdialog', this);
		},
		setTitle: function (title) {
			if (this.options.showDialogTitle) {
				// Visible dialog title
				this.dialogElement.attr('aria-labelledby', 'dialog-title-' + (z - 1));
				$('<div class="dialog-title"><h1 id="dialog-title-' + (z - 1) + '">' + title + '</h1></div>').prependTo(this.contentElement);
			}
			else {
				// Visually hidden dialog title
				this.dialogElement.attr('aria-label', title);
			}
		},
		open: function (element) {
			var self = this;

			if (element) {
				element.after(this.dialogElement);
				// this.dialogElement.appendTo(container);
			}
			else {
				if (container.find(this.dialogElement).length) {
					return;
				}

				container.appendTo('#body');
				this.dialogElement.appendTo(container);
				this.position();

				// Add tabindex attribute so the dialog can gain focus
				this.dialogElement.attr('tabindex', '-1').focus().removeAttr('tabindex');

				// Prevent dabbing outside the dialog
				$(document).on('focus.netrdialog', '*', function (e) {
					/*
						NOTE: [ReCaptcha] Clicks or focus on recaptcha element should count as
						click/focus inside a dialog, we don't want the dialog to close or move the
						focus to another element.

						We check if the target is <body> because IE11 for some reason gives it focus
						when the user clicks inside a dialog.

						We check if the target has title='recaptcha challenge'. Is true when the user
						clicks on the recaptcha overlay. If this doesn't work, check if the title is
						correct in the recaptcha.
					*/
					/*
					var noRecaptchaClicked = parent.find('iframe').filter(function(){
						return this.value.toLowerCase().indexOf('recaptcha') != -1;
					}).length === 0;
					*/
					var first = !$.contains(self.dialogElement.get(0), e.target);
					var second = e.target !== $('body')[0];
					var noRecaptcha = $('body').find('[title="recaptcha-test"]').length === 0 && e.target.title !== 'recaptcha challenge';

					//	console.log('Above: ', first, second, noRecaptcha);

					if (first && second && noRecaptcha) {
						e.stopPropagation();
						// Put focus on the first focusable element in the dialog
						var firstElement = self.dialogElement.find('input, select, textarea, button, object, a, [tabindex]').filter(function () {
							var element = $(this);
							return (!element.is(':disabled') && (element.attr('tabindex') !== '-1'));
						}).eq(0);

						firstElement.focus();
					}
				});
				// Close the dialog when the ESC key is pressed
				$(document).on('keydown.netrdialog', function (e) {
					if (e.keyCode === 27) { // ESC
						// Close all dialogs
						$.each(dialogs, function () {
							this.close();
						});
					}
				});
				// Close the dialog when something outside it is clicked or tapped
				$(document).bind('click.netrdialog touchend.netrdialog', function (e) {
					// We checking for two different titles on the recaptcha iframe because they are different on browsers for some reason.
					var parent = $(e.target).parent();
					// console.log();

					var noRecaptchaClicked = testBool == false ?

						parent.find('[title="recaptcha-test"]').length === 0 && parent.find('[title="recaptcha challenge"]').length === 0 :

						parent.find('iframe').filter(function () {
							return this.title.toLowerCase().indexOf('recaptcha') == -1;
						}).length === 0;

					var first = !jQuery.contains(self.dialogElement.get(0), e.target);
					var second = (self.dialogElement.get(0) !== e.target);

					//	console.log('Below: ', first, second, noRecaptchaClicked);

					if (first && second && noRecaptchaClicked) {
						e.preventDefault();
						self.close();
					}
				});

				// Trigger open event
				this.dialogElement.trigger('open.netrdialog', this);

				// Update modal bg height on resize
				var ready_for_resize = true;
				$(window).on('resize', function () {
					if (ready_for_resize) {
						container.css('height', ($(document).height()));
						ready_for_resize = false;
						setTimeout(function () {
							ready_for_resize = true;
						}, 50);
					}
				});
			}
			this.position();
		},
		close: function (isInitiatedByTheCloseButton) {
			// SMRT: Initially built for SMRT
			if (doNotClosePopup || (isInitiatedByTheCloseButton != true && $(this.dialogElement).find('#email-form').length > 0)) {
				doNotClosePopup = false;
				return;
			}

			// Remove events from document
			$(document).off('focus.netrdialog', '*').off('keydown.netrdialog click.netrdialog touchend.netrdialog');

			// Trigger close event
			// eslint-disable-next-line new-cap
			var close_event = $.Event('close.netrdialog');
			this.dialogElement.trigger(close_event);

			if (!close_event.isDefaultPrevented() && this.dialogElement && container) {
				this.dialogElement.detach();
				container.detach();
			}
		},
		position: function () {
			// Set container size
			container.css('height', ($(document).height()));
			// Increment maximum z value
			z++;
			
			var topValue = Math.max($(document).scrollTop() + 20, $(document).scrollTop() + ($(window).height() / 2) - (this.dialogElement.height() / 2));
			var minimumHeight = 0;

			if (this.options.dialogTop == true) {
				topValue = Math.max($(document).scrollTop() + 20);
				minimumHeight = '400px';
			}
			
			//console.log($(window).width() < 900);
			//console.log(this.options.dialogFullInIpad == true);

			if (this.options.dialogFullInIpad == true && $(window).width() < 900) {
				topValue = Math.max($(document).scrollTop() + 20);
				minimumHeight = Math.max($(window).height() - 200);
			}

			this.dialogElement.css({
				top: topValue,
				left: ($(window).width() / 2) - (this.dialogElement.outerWidth() / 2),
				zIndex: z,
				minHeight: minimumHeight
			});
		},
		isDialog: function () {
			// Test to see if content should be opened in a dialog
			
			try {
				return !(window.matchMedia('only screen and (max-width:900px)').matches && this.options.onlyDesktop);
			}
			catch (e) {
				return true;
			}
		},
		openTransferDialog: function (url/* , siteName */) {
			this.dialogElement.addClass('dialog-transfer');
			// this.setContent(netr.string.translate('transfer.youAreBeingTransfered').replace('{0}', siteName));
			this.setContent(netr.string.translate('transfer.youAreBeingTransfered'));
			this.open();
			$('html').unbind('click.netrdialog touchend.netrdialog');
			// Redirect to URL
			setTimeout(function () {
				document.location = url.toString();
			}, 2000);
		}
	};

	/**
	Create an independent dialog
	*/
	$.netrdialog = function (options) {
		return new Dialog(options);
	};

	/**
	Open the source of a link element in a dialog
	@param {Object}  options  Options
	@memberOf $.fn
	*/
	$.fn.netrdialog = function (method, options) {
		if (typeof method === 'string') {
			switch (method) {
				case 'getdialog':
					return this.data('netrdialog');
				case 'setcontent':
					var content = options;
					return this.data('netrdialog').setContent(content);
				case 'settitle':
					return this.data('netrdialog').setTitle(options);
				case 'open':
					this.data('netrdialog').open();
					break;
				case 'close':
					this.data('netrdialog').close();
					break;
				default:
					break;
			}
		}
		else {
			// Set options
			options = $.extend({
				// Should data be loaded every time?
				persistent: false,
				// Hijack any form submissions
				hijackForms: false,
				// Prevent form submission outside of dialog
				warningSubmitDialog: false,
				// Extra dialog class
				extraClass: '',
				// Alt text for the close button image
				closeText: 'Stäng',
				// A generic error message
				errorMessage: '<p>Ett fel har tyvärr uppstått. Var vänlig försök igen.</p>',
				// Set to false to disable the dialog title
				showDialogTitle: true,
				onlyDesktop: false
			}, $.isPlainObject(method) ? method : ($.isPlainObject(options) ? options : {}));

			var clickHandler = function (element, e) {

				// Special demand to show a warning dialog if "Random length" radio-button is selected on product-page
				if(options.warningSubmitDialog && element.hasClass('warning-form-dialog')) {
					var elementSelector = element.attr('data-selector');
					var elementSelectorInput = element.attr('data-selector-input');
					var elementSelectorRadio = element.attr('data-selector-radio');
					var elementForm = element.closest('form');
					if(elementForm.find('.quantity-selection__type[value="' + elementSelector + '"]').is(':checked') && elementForm.find('[name="' + elementSelectorInput + '"]').val() !== '' && elementForm.find('[name="' + elementSelectorRadio + '"]').is(':checked')) {
						e.preventDefault();
					}
					else {
						return;
					}
				}
				else {
					e.preventDefault();
				}

				var dialog = element.data('netrdialog');

				if (!dialog) {
					// Create a new dialog
					element.data('netrdialog', dialog = new Dialog(options));

					dialog.dialogElement.bind({
						'close.netrdialog': function (e) {
							// Let the same event bubble away
							// to the connected element.
							element.trigger(e, dialog);

							if (!e.isDefaultPrevented()) {
								element.focus();

								if (!options.persistent) {
									dialog.dispose();
									// Remove references to dialog
									element.data('netrdialog', dialog = null);
								}
								else {
									// Remove listener for clicks outside dialog
									$(document).off('click.netrdialog touchend.netrdialog');
								}
							}
						},
						'open.netrdialog': function (e) {
							// Let the same event bubble away
							// to the connected element.
							element.trigger(e, dialog);
						},
						'load.netrdialog': function (e) {
							if (options.hijackForms) {
								var forms = dialog.contentElement.find('form');

								forms.each(function () {
									var form = $(this);
									var button;

									// Observe button clicks
									form.on('click', '[type=submit]', function () {
										button = $(this);
									});

									// Observe submit event
									form.submit(function (e) {
										e.preventDefault();

										var data = form.serialize();

										if (button && button.length) {
											data += ('&' + button.attr('name') + '=' + encodeURIComponent(button.val()));
										}

										$.getFragment({
											url: form.attr('action'),
											type: (form.attr('method') || 'post'),
											data: data,
											async: false,
											success: function (data) {
												if (data.length) {
													dialog.setContent(data);
													// If a callback function was supplied, run it with the loaded
													// data as a jQuery object
													if (typeof (options.callback) === 'function') {
														options.callback($(data));
													}
												}
											},
											error: function () {
												dialog.setContent($(options.errorMessage));
											}
										});
									});
								});
							}

							if (options.warningSubmitDialog) {
								if(dialog.contentElement.find('.cancel').length) {
									dialog.contentElement.find('.cancel').click(function() {
										$.each(dialogs, function () {
											this.close();
										});
									});
								}
								if(dialog.contentElement.find('.confirm').length) {
									dialog.contentElement.find('.confirm').click(function() {
										$.each(dialogs, function () {
											this.close();
										});
										element.closest('form').submit();
									});
								}
							}

							// Let the same event bubble away
							// to the connected element.
							element.trigger(e, dialog);

							// Trigger new content when reloading content in dialog.
							if (options.open) {
								$('body').trigger('newcontent', [{ target: dialog.contentElement }]);
							}
						}
					});
				}

				if (!dialog.contentElement.children().length || !options.persistent) {
					var attr = element.attr('href') ? 'href' : 'data-href';
					if (element.attr(attr).match(/^#/)) {
						// Get element from current page
						dialog.setContent($(element.attr(attr)).clone());
					}
					else {
						// Get element by ajax
						$.getFragment({
							url: element.attr('href'),
							async: options.loadAsync ? true : false,
							success: function (data) {
								if (data.length) {
									dialog.setContent(data);

									// If a callback function was supplied, run it with the loaded
									// data as a jQuery object
									if (typeof (options.callback) === 'function') {
										options.callback($(data));
									}
								}
							},
							error: function () {
								dialog.setContent($(options.errorMessage));
							}
						});
					}
					// Add a title if the triggering element has a data-dialog-title attribute
					var title = element.data('dialog-title');
					if (title) {
						dialog.setTitle(title);
					}
				}

				// Just a tiiiny delay to be sure content has loaded
				setTimeout(function () {
					if (!dialog.isDialog()) {
						element.after(dialog.contentElement)
							.trigger('open.netrdialog')
							.attr('data-dialog-only-desktop', 'open')
							.unbind('click')
							.click(function (e) {
								e.preventDefault();
								$(this).next('.dialog-content').toggleClass('collapsed');
							});
					}
					else {
						dialog.open();
					}
				}, 100);
			};

			var self = this;

			if (!options.onlyDesktop || (options.onlyDesktop && window.matchMedia('screen and (max-width:900px)').matches)) {
				self.click(function (e) {
					clickHandler($(this), e);
				});
			}

			$.breakpoint((function () {
				return {
					condition: function () {
						return !netr.forceDesktopLayout && window.matchMedia('screen and (min-width:901px)').matches && options.onlyDesktop;
					},
					enter: function () {
						self.attr('data-dialog-only-desktop', 'true').next('.dialog-content').detach();
						$(self).data('netrdialog', false);
						self.click(function (e) {
							clickHandler($(self), e);
						});
					}
				};
			}()));
		}

		// Open on init?
		if (options.open) {
			this.click();
		}

		return this;
	};
}(jQuery));
