$(function () {
	'use strict';

	var button = $('[data-toggle-loading-message]');
	var form = button.parents('form');

	function showLoadingMsg () {
		button.addClass('loading');
		button.find('> span').text(button.data('toggle-loading-message'));
	}

	$(document)
		.on('submit', form, function () {
			showLoadingMsg();
		})
		.on('click', '[data-toggle-loading-message]', function () {
			showLoadingMsg();
		});

	$('.button').each(function () {
		var btn = $(this);

		if (!btn.find('> span').length) {
			btn.wrapInner('<span />');
		}
	});
});
