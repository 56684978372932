/*
 * Check if the browser supports the CSS object-fit property.
 * If it doesn't, set the source of the relevant img element as the background
 * image of the container.
 */
(function ($) {
	'use strict';

	var supportsObjectFit = function () {
		var style = document.createElement('styletest').style;
		if (style['objectFit'] !== undefined) {
			return true;
		}
		return false;
	};

	$.fn.objectFit = function (settings) {
		var config = {
			imageSelector: 'img'
		};
		if (settings) {
			$.extend(config, settings);
		}

		this.each(function () {
			if (!supportsObjectFit()) {
				var container = $(this);
				var imgUrl = container.find(config.imageSelector).prop('src');
				if (imgUrl) {
					container.css('backgroundImage', 'url(' + imgUrl + ')').addClass('object-fit');
				}
			}
		});
	};

	$.fn.objectUnfit = function () {
		this.each(function () {
			if (!supportsObjectFit()) {
				$(this).css('backgroundImage', 'none').removeClass('object-fit');
			}
		});
	};
}(jQuery));
