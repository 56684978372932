// Equal heights
(function () {
	'use strict';

	$.breakpoint((function () {
		return {
			condition: function () {
				return window.matchMedia && window.matchMedia('only screen and (min-width: 701px)').matches;
			},

			enter: function () {
				var groups = [];
				var items  = $('[data-equal-height-group]');

				// Find unique groups.
				items.each(function () {
					var group_name = $(this).data('equal-height-group');

					if ($.inArray(group_name, groups) === -1) {
						groups.push(group_name);
					}
				});

				function justifyGroups () {
					$.each(groups, function (index, group) {
						$('[data-equal-height-group="' + group + '"]').justify();
					});
				}

				justifyGroups();

				$(window).on('resize.justifyGroups', justifyGroups);
			},

			exit: function () {
				$(window).off('resize.justifyGroups');

				// Timeout needed for this to work :-/
				setTimeout(function () {
					$('[data-equal-height-group]').css('min-height', '0');
				}, 50);
			}
		};
	}()));
}());