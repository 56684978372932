/* globals google */

/**
* @fileoverview Wrapper methods for Google Maps API v3
*/

(function ($) {
	'use strict';

	/**
	 * NetRelations Google Maps wrapper constructor
	 * @constructor
	 *
	 * @requires Google Maps API v3.0 or later
	 * @requires jQuery 1.5.2 or later
	 *
	 * @param {jQuery} el The element where the map will be inserted
	 * @param {Object} options An options object. See {@link NetRGoogleMap.options} for a list of options
	 */
	var NetRGoogleMap = function (el, options) {
		var guiBase = $('html').attr('data-ssg-gui-base') ||
			$('html').attr('data-gui-base');

		this.options = $.extend(true, {}, {
			// Initial zoom level
			zoom: 1,
			// Initial center coordinates
			center: new google.maps.LatLng(20, 10),
			// Options for map type control
			mapTypeControlOptions: {
				mapTypeIds: [
					google.maps.MapTypeId.ROADMAP,
					google.maps.MapTypeId.HYBRID
				],
				style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
			},
			// Initial Map mapTypeId
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			// Zooming by use of scrollwheel on or off
			googleMapOptions: {
				scrollwheel: false,
				streetViewControl: false,
				mapTypeControl: false
			},
			iconset: 'default',
			iconsets: {
				'default': {
					icon: {
						url: guiBase + 'i/map/marker-white.svg',
						size: new google.maps.Size(31, 47),
						anchor: new google.maps.Point(15, 47),
						labelOrigin: new google.maps.Point(15, 16)
					}
				}
			}
		}, options || {});

		this.container = el;

		this.markers = [];

		this.infoWindows = [];

		// Replace static map with dynamic map
		this.mapContainer = $('<div class="googlemap-container">');
		this.container.empty().append(this.mapContainer);

		this.googlemap = new google.maps.Map(this.mapContainer[0], this.options.googleMapOptions);

		// If a list of elements has been passed in, parse it for coordinates and info
		if (this.options.vcards) {
			this.parseVCards({
				vcards: this.options.vcards
			});
		}

	};

	NetRGoogleMap.prototype = {

		/**
		* Creates a marker on the map, with info bubble displaying on mouseover
		* @returns An instance of google.maps.Marker
		*/
		addMarker: function (options) {
			var self = this;

			options = $.extend({
				latlng: '',
				info: '',
				iconset: false,
				zoomTo: false
			}, options || {});

			// Create new marker
			var marker_options = {
				map: this.googlemap,
				position: options.latlng,
				// Set z-index based on latitude to get a somewhat correct layer order
				zIndex: Math.abs(100000 - Math.floor(options.latlng.lat() * 10000))
			};

			if (options.iconset) {
				marker_options.icon = this.options.iconsets[options.iconset].icon;
			}

			var marker = new google.maps.Marker(marker_options);

			if (options.info) {
				google.maps.event.addListener(marker, 'click', function () {
					self.showInfoWindow(marker, options.info);
				});
			}

			this.markers.push(marker);

			if (options.zoomTo !== false) {
				this.zoomToMarker({
					marker: marker
				});
			}

			return marker;
		},

		/**
		 * Adjust center and zoomlevel to match the maps markers
		 */
		fitAroundMarkers: function (options) {
			var self = this;

			options = $.extend({
				zoomLevel: 13
			}, options || {});

			var bounds = new google.maps.LatLngBounds();
			$.each(this.markers, function (index, marker) {
				bounds.extend(marker.getPosition());
			});

			google.maps.event.addListenerOnce(this.googlemap, 'zoom_changed', function () {
				if (self.googlemap.getZoom() > options.zoomLevel) {
					self.googlemap.setZoom(options.zoomLevel);
				}
			});

			// Shrink to fit all markers
			this.googlemap.fitBounds(bounds);
		},

		/**
		 * Zooms to a specific marker
		 */
		zoomToMarker: function (options) {
			options = $.extend({
				marker: '',
				zoomLevel: 13
			}, options || {});
			this.googlemap.setCenter(options.marker.getPosition());
			this.googlemap.setZoom(options.zoomLevel);
		},

		/**
		 * Displays an infowindow for a marker
		 *
		 * @param {google.maps.Marker}  marker  The marker this infowindow should be anchored to
		 * @param {jQuery}  info  Element containing the markup to display in the infowindow
		 */
		showInfoWindow: function (marker, info) {
			var self = this;
			if (!marker.infoWindow) {
				marker.infoWindow = new google.maps.InfoWindow({
					content: info.clone()[0],
					maxWidth: 350
				});
				self.infoWindows.push(marker.infoWindow);
			}

			// Close all other infowindows.
			if (!self.options.multipleInfoWindows) {
				$.each(self.infoWindows, function (index, infoWindow) {
					infoWindow.close();
				});
			}

			marker.infoWindow.open(this.googlemap, marker);

		},

		/**
		 * Parses vcard elements for geodata and creates markers on the map
		 */
		parseVCards: function (options) {
			var self = this;

			options = $.extend({
				vcards: null,
				zoomLevel: 5
			}, options || {});

			if (options.vcards) {
				options.vcards.each(function () {
					var item = $(this);
					var geocoder;
					var coords;

					function addMarker () {
						self.addMarker({
							latlng: coords,
							info: item,
							iconset: self.options.iconset,
							iconsets: self.options.iconsets
						});
					}

					// Does this vcard contain any geo data?
					if (item.find('.geo').length) {
						// If so, use that data to create a new LatLng instance
						coords = new google.maps.LatLng(item.find('.geo .latitude:first').text(), item.find('.geo .longitude:first').text());
						addMarker();
					}
					else {
						// If not, try to geocode the address data
						geocoder = new google.maps.Geocoder();
						geocoder.geocode({
							// Get first address from vcard and strip multiple whitespace
							address: item.find('.adr:first').text().replace(/\s+/g, ' ')
						}, function (result, status) {
							if (status === google.maps.GeocoderStatus.OK) {
								coords = result[0].geometry.location;
								addMarker();
								self.fitAroundMarkers();
							}
						});
					}
				});

				this.fitAroundMarkers();
			}
		}
	};

	$.fn.netrGoogleMap = function (method) {
		var methods = {
			init: function (options) {
				var el = $(this);
				el.data('googlemap', new NetRGoogleMap(el, options || {}));
			},
			getMap: function () {
				return this.data('googlemap');
			},
			parseVCards: function (options) {
				if (this.data('googlemap')) {
					this.data('googlemap').parseVCards(options);
				}
				return this;
			},
			addMarker: function (options) {
				if (this.data('googlemap')) {
					return this.data('googlemap').addMarker(options);
				}
			},
			zoomToMarker: function (options) {
				if (this.data('googlemap')) {
					return this.data('googlemap').zoomToMarker(options);
				}
			},
			fitAroundMarkers: function () {
				if (this.data('googlemap')) {
					return this.data('googlemap').fitAroundMarkers();
				}
			}
		};

		// Method calling logic
		if (methods[method]) {
			// Method exists
			return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
		}
		else if (typeof method === 'object' || !method) {
			// Initialize
			return methods.init.call(this, method);
		}
		else {
			// Method doesn't exist
			throw new Error('Method ' +  method + ' does not exist on netrGoogleMap');
		}
	};

	/**
	 * NetRelations Google Maps wrapper default options
	 */
	$.netrGoogleMap = {
		loadAPI: function () {
			var apiKey = $('html').data('google-map-key');

			if (!$.netrGoogleMap.deferred) {
				// eslint-disable-next-line new-cap
				$.netrGoogleMap.deferred = $.Deferred();

				window.asyncGoogleMapsCallback = function () {
					try {
						delete window.asyncGoogleMapsCallback;
					}
					catch (err) {

					}
					$.netrGoogleMap.deferred.resolve();
				};

				var maps_url = new netr.URI('https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&libraries=places&v=3&sensor=false&callback=window.asyncGoogleMapsCallback');

				maps_url.query.lang = $('html').attr('lang');

				$.getScript(maps_url.toString());
			}

			return $.netrGoogleMap.deferred;
		},
		defaultOptions: {
			// A bunch of vcard elements to parse for coordinates and info.
			// Uses data found in element with class="geo" if present, otherwise
			// does a geocoding request to Google using the vcards address data.
			vcards: null,
			// Icon sets
			iconsets: {},
			multipleInfoWindows: false
		}
	};

}(jQuery));
