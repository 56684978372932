$(function () {
	'use strict';

	$('.office').each(function () {
		var office = $(this);
		var map = office.find('.office-map');

		if (map) {
			// Look through offices and find maps.
			// If found, place them in a spot appriopriate for
			// being floated right of the content.
			$.breakpoint((function () {
				return {
					condition: function () {
						return window.matchMedia('only screen and (max-width:700px)').matches;
					},
					enter: function () {
						map.detachWithPlaceholder().insertBefore(office.find('dl:first'));
					},
					exit: function () {
						map.attachToPlaceholder();
					}
				};
			}()));
		}
	});
});