$(function () {
	'use strict';

	$(document).on('change', '[data-autosubmit]', autosubmit);

	function autosubmit(e) {
		if (e == undefined) {
			e = $('[data-autosubmit]');
		}

		$(e.target)
			.closest('form')
			.find(':submit:not([data-noautosubmit])')
			.trigger('click');
	}
});
