/* globals Collapsable */

// eslint-disable-next-line no-unused-vars
function Collapsable (params) {
	if (!params || !params.element || !params.id) {
		throw new Error('Missing parameter(s)');
	}

	var id = params.id;
	var $document = $(document);
	var $element = $(params.element);
	var isExpanded = $element.hasClass('is-expanded');

	$element.attr('aria-expanded', isExpanded);
    
	$document.on('collapsable:action', eventHandler);

	function eventHandler (event, data) {
		if (!data || !data.action || !data.id) {
			return;
		}

		if (data.id === id) {
			switch (data.action.toLowerCase()) {
				case 'toggle': 
					toggle();
					break;
				
				case 'expand':
					expand();
					break;
				
				case 'collapse':
					collapse();
					break;
			}
		}
	}

	function toggle (expand) {
		if (isExpanded || expand === false) {
			$element
				.removeClass('is-expanded')
				.attr('aria-expanded', false);

			isExpanded = false;
		}
		else if(!isExpanded || expand === true) {
			$element
				.addClass('is-expanded')
				.attr('aria-expanded', true);

			isExpanded = true;
		}

		$document.trigger(
			'collapsable:state-change',
			[{ id: id, expanded: isExpanded }]
		);
	}

	function collapse () {
		toggle(false);
	}

	function expand () {
		toggle(true);
	}

	return {
		toggle: toggle,
		collapse: collapse,
		expand: expand
	};
}