/**
 * @license
 * This software or document includes material copied from or derived from multithumb-slider.js (https://w3c.github.io/aria-practices/examples/slider/js/multithumb-slider.js). Copyright © 2018 W3C® (MIT, ERCIM, Keio, Beihang).
 * The original code is licensed according to the W3C Software License at https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 */
var MultiThumbSlider = function (element, options)  {
	options = options || {};
	this.settings = {
		inputFieldContainerSelector: options.inputFieldContainerSelector || '.multi-thumb-slider__input-fields',
		minInputSelector: options.minInputSelector || '.multi-thumb-slider__input-field--min input',
		maxInputSelector: options.maxInputSelector || '.multi-thumb-slider__input-field--max input',
		sliderContainerClass: options.sliderContainerClass || 'multi-thumb-slider__slider',
		labelContainerClass: options.labelContainerClass || 'multi-thumb-slider__rail-labels',
		labelContainerLabelPrefix: options.labelContainerLabelPrefix || 'Possible range: ',
		minLabelClass: options.minLabelClass || 'multi-thumb-slider__rail-label multi-thumb-slider__rail-label--min',
		maxLabelClass: options.maxLabelClass || 'multi-thumb-slider__rail-label multi-thumb-slider__rail-label--max',
		railClass: options.railClass || 'multi-thumb-slider__rail',
		minThumbClass: options.minThumbClass || 'multi-thumb-slider__thumb multi-thumb-slider__thumb--min',
		maxThumbClass: options.maxThumbClass || 'multi-thumb-slider__thumb multi-thumb-slider__thumb--max',
		valueTextPrefix: options.valueTextPrefix || '',
		valueTextSuffix: options.valueTextSuffix || '',
		railBorderWidth: options.railBorderWidth || 0,
		thumbWidth: options.thumbWidth || 24,
		thumbHeight: options.thumbHeight || 24
	};
	this.container = element;
	this.minInput = element.querySelector(this.settings.minInputSelector);
	this.maxInput = element.querySelector(this.settings.maxInputSelector);

	this.railBorderWidth = this.settings.railBorderWidth;
	this.thumbWidth = this.settings.thumbWidth;
	this.thumbHeight = this.settings.thumbHeight;
};

/**
 * Create DOM elements and set up event listeners
 */
MultiThumbSlider.prototype.init = function () {

	// The slider container
	this.sliderContainer = document.createElement('div');
	this.sliderContainer.setAttribute('class', this.settings.sliderContainerClass);

	// The label container
	this.labelContainer = document.createElement('div');
	this.labelContainer.setAttribute('class', this.settings.labelContainerClass);
	this.labelContainer.setAttribute('aria-label', this.settings.labelContainerLabelPrefix + this.minInput.min + ' - ' + this.minInput.max);
	this.sliderContainer.appendChild(this.labelContainer);

	// The min and max labels. Note that these are hidden from screen readers.
	this.minLabel = document.createElement('div');
	this.minLabel.setAttribute('aria-hidden', 'true');
	this.minLabel.setAttribute('class', this.settings.minLabelClass);
	this.minLabel.appendChild(document.createTextNode(this.minInput.min));
	this.labelContainer.appendChild(this.minLabel);

	// The max label
	this.maxLabel = document.createElement('div');
	this.maxLabel.setAttribute('aria-hidden', 'true');
	this.maxLabel.setAttribute('class', this.settings.maxLabelClass);
	this.maxLabel.appendChild(document.createTextNode(this.maxInput.max));
	this.labelContainer.appendChild(this.maxLabel);

	// The rail
	this.rail = document.createElement('div');
	this.rail.setAttribute('class', this.settings.railClass);
	this.sliderContainer.appendChild(this.rail);

	this.minThumb = document.createElement('span');
	this.minThumb.input = this.minInput;
	this.minThumb.setAttribute('id', this.minInput.id + '-thumb');
	this.minThumb.setAttribute('role', 'slider');
	this.minThumb.setAttribute('tabindex', '0');
	this.minThumb.setAttribute('class', this.settings.minThumbClass);
	this.minThumb.setAttribute('aria-valuemin', this.minInput.min);
	this.minThumb.setAttribute('aria-valuemax', this.minInput.max);
	this.minThumb.setAttribute('aria-valuenow', this.minInput.value);
	this.minThumb.setAttribute('aria-valuetext', this.settings.valueTextPrefix + this.minInput.value + this.settings.valueTextSuffix);
	this.minThumb.setAttribute('aria-label', this.container.querySelector('label[for="' + this.minInput.id + '"]').textContent);
	this.rail.appendChild(this.minThumb);

	this.maxThumb = document.createElement('span');
	this.maxThumb.input = this.maxInput;
	this.maxThumb.setAttribute('id', this.maxInput.id + '-thumb');
	this.maxThumb.setAttribute('role', 'slider');
	this.maxThumb.setAttribute('tabindex', '0');
	this.maxThumb.setAttribute('class', this.settings.maxThumbClass);
	this.maxThumb.setAttribute('aria-valuemin', this.maxInput.min);
	this.maxThumb.setAttribute('aria-valuemax', this.maxInput.max);
	this.maxThumb.setAttribute('aria-valuenow', this.maxInput.value);
	this.maxThumb.setAttribute('aria-valuetext', this.settings.valueTextPrefix + this.maxInput.value + this.settings.valueTextSuffix);
	this.maxThumb.setAttribute('aria-label', this.container.querySelector('label[for="' + this.maxInput.id + '"]').textContent);
	this.rail.appendChild(this.maxThumb);

	// Store the original min and max values of the rail and the input fields
	this.railMin = parseInt(this.minInput.min, 10);
	this.railMax = parseInt(this.maxInput.max, 10);
	this.minInput.minOriginal = this.minInput.min;
	this.minInput.maxOriginal = this.minInput.max;
	this.maxInput.minOriginal = this.maxInput.min;
	this.maxInput.maxOriginal = this.maxInput.max;

	// Add event listeners to the thumb container
	this.sliderContainer.addEventListener('keydown', this.handleKeyDown.bind(this));
	this.sliderContainer.addEventListener('mousedown', this.handleMouseDown.bind(this));
	this.sliderContainer.addEventListener('focus', this.handleFocus.bind(this));
	this.sliderContainer.addEventListener('blur', this.handleBlur.bind(this));

	// Insert the created elements
	this.container.insertBefore(this.sliderContainer, this.container.querySelector(this.settings.inputFieldContainerSelector));

	// Set the thumbs to their initial positions
	this.positionThumbs();

	// Reposition the thumbs if the user changes the value of the input fields directly
	this.minInput.addEventListener('change', this.positionThumbs.bind(this));
	this.maxInput.addEventListener('change', this.positionThumbs.bind(this));

	// Reposition the thumbs when the viewport (and thus the rail) is resized
	window.addEventListener('resize', this.positionThumbs.bind(this));
	window.addEventListener('orientationchange', this.positionThumbs.bind(this));
};

MultiThumbSlider.prototype.positionThumbs = function () {
	this.moveThumbTo(this.minThumb, this.minInput.value);
	this.moveThumbTo(this.maxThumb, this.maxInput.value);
};

MultiThumbSlider.prototype.moveThumbTo = function (thumb, value) {
	var valueMax = parseInt(thumb.getAttribute('aria-valuemax'), 10);
	var valueMin = parseInt(thumb.getAttribute('aria-valuemin'), 10);
	if (value > valueMax) {
		value = Math.min(valueMax, thumb.input.maxOriginal);
	}

	if (value < valueMin) {
		value = Math.max(valueMin, thumb.input.minOriginal);
	}

	var pos = Math.round(((value - this.railMin) * (this.rail.clientWidth - 2 * (this.thumbWidth - this.railBorderWidth))) / (this.railMax - this.railMin));
	thumb.setAttribute('aria-valuenow', value);
	thumb.setAttribute('aria-valuetext', this.settings.valueTextPrefix + value + this.settings.valueTextSuffix);
	thumb.input.value = value;

	if (thumb === this.minThumb) {
		this.maxInput.min = Math.max(value, this.maxInput.minOriginal);
		this.maxThumb.setAttribute('aria-valuemin', Math.max(value, this.maxInput.minOriginal));
		thumb.style.left = (pos - this.railBorderWidth) + 'px';
	}
	else {
		this.minInput.max = Math.min(value, this.minInput.maxOriginal);
		this.minThumb.setAttribute('aria-valuemax', Math.min(value, this.minInput.maxOriginal));
		thumb.style.left = (pos + this.thumbWidth - this.railBorderWidth) + 'px';
	}
};

MultiThumbSlider.prototype.handleKeyDown = function (e) {
	var keyCodes = {
		'left': 37,
		'up': 38,
		'right': 39,
		'down': 40,
		'pageUp': 33,
		'pageDown': 34,
		'end': 35,
		'home': 36
	};

	// Consume the keyDown event if one of the keys we use was pressed
	if (Object.keys(keyCodes).some(function(key) {
		return keyCodes[key] === e.keyCode;
	})) {
		e.preventDefault();
		e.stopPropagation();
	}

	var thumb = e.target;
	var valueNow = parseInt(thumb.getAttribute('aria-valuenow'), 10);

	switch (e.keyCode) {
		case keyCodes.left:
		case keyCodes.down:
			this.moveThumbTo(thumb, valueNow - 1);
			break;

		case keyCodes.right:
		case keyCodes.up:
			this.moveThumbTo(thumb, valueNow + 1);
			break;

		case keyCodes.pageDown:
			this.moveThumbTo(thumb, valueNow - 10);
			break;

		case keyCodes.pageUp:
			this.moveThumbTo(thumb, valueNow + 10);
			break;

		case keyCodes.home:
			this.moveThumbTo(thumb, this.railMin);
			break;

		case keyCodes.end:
			this.moveThumbTo(thumb, this.railMax);
			break;
	}
};

MultiThumbSlider.prototype.handleFocus = function () {
	this.rail.classList.add('focus');
};

MultiThumbSlider.prototype.handleBlur = function () {
	this.rail.classList.remove('focus');
};

MultiThumbSlider.prototype.handleMouseDown = function (event) {
	var self = this;
	var thumb = event.target;

	var handleMouseMove = function (event) {
		event.preventDefault();
		event.stopPropagation();
		var diffX = event.clientX - Math.round(self.rail.getBoundingClientRect().x);
		self.moveThumbTo(thumb, self.railMin + parseInt(((self.railMax - self.railMin) * diffX) / self.rail.clientWidth, 10));
	};

	var handleMouseUp = function () {
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);
	};

	// Track mouse movements and move the thumb
	document.addEventListener('mousemove', handleMouseMove);

	// Stop tracking mouse movements on mouseup
	document.addEventListener('mouseup', handleMouseUp);

	event.preventDefault();
	event.stopPropagation();

	// Move focus to the clicked handle
	thumb.focus();
};