(function ($) {
	'use strict';
	
	$.fn.netrAutocomplete = function (settings) {
		var config = {
			// Url for search suggestions returned in JSON format
			suggestionsUrl: '/temp/querycompletion.json',
			// Maximum number of suggestions to display
			maxSuggestions: 3,
			// Class name for the autocomplete placeholder element
			autocompletePlaceholderClass: 'autocomplete-placeholder',
			autocompleteActiveClass: 'autocomplete-active'
		};
		if (settings) {
			$.extend(config, settings);
		}
		this.each(function () {
			var input = $(this);
			// Insert placeholder element
			var autocompletePlaceholder = $('<div>', {
				'class': config.autocompletePlaceholderClass
			}).insertAfter(input);
			// Initialise jQuery UI Autocomplete widget
			input.autocomplete({
				select: function () {
					$(this).closest('form').find('[type=submit]').click();
				},
				source: function (request, response) {
					$.ajax({
						url: config.suggestionsUrl,
						data: {
							q: request.term
						},
						dataType: 'json',
						success: function (data) {
							if (!data) {
								data = '';
							}
							var ret = $.map(data.slice(0, config.maxSuggestions), function (row) {
								return {
									label: row.suggestionHighlighted,
									value: row.suggestion
								};
							});
							response(ret);
						}
					});
				},
				delay: 200,
				minLength: 2,
				appendTo: autocompletePlaceholder,
				position: {
					my: 'left top',
					at: 'left top',
					of: autocompletePlaceholder
				},
				open: function () {
					input.addClass(config.autocompleteActiveClass);
				},
				close: function () {
					input.removeClass(config.autocompleteActiveClass);
				}
			}).data('ui-autocomplete')._renderItem = function (ul, item) {
				// Custom render method to allow unescaped HTML in results
				return $('<li></li>').data('item.autocomplete', item).append('<a><span class="suggestion">' + item.label + '</a>').appendTo(ul);
			};
		});
	};

	$('form[data-querycompletion-url]').each(function () {
		var form = $(this);
		var input = $(this).find('input[type="search"]');

		input.netrAutocomplete({
			suggestionsUrl: form.data('querycompletion-url')
		});
	});

	$('.ui-autocomplete').click(function () {
		$(this).closest('form').find('[type=submit]').click();
	});
}(jQuery));
