$(function () {
	'use strict';

	$.fn.expandableMap = function () {
		var map = $(this);

		if (map.hasClass('expandable-map')) {
			map.append($('<button>', {
				'class': 'link map-expand title-tooltip',
				'title': netr.string.translate('salesOffices.viewLargerMapTitle'),
				type: 'button',
				html: netr.string.translate('salesOffices.viewLargerMap'),
				click: function (e) {
					e.preventDefault();
					e.stopPropagation();
					openMapDialog(map);
				}
			}));

			map.click(function (e) {
				e.stopPropagation();
				openMapDialog(map);
			});
		}
		else if (map.hasClass('expandable-map-text')) {
			map.find('a').click(function (e) {
				e.preventDefault();
				e.stopPropagation();
				openMapDialog(map);
			});
		}

		function openMapDialog (map) {
			var dialog;

			if (!dialog) {
				dialog = $.netrdialog({
					extraClass: 'dialog-map'
				});

				dialog.dialogElement.on('open.netrdialog', function () {
					var mapdiv = $('<div>', {
						'class': 'map map-large'
					});

					dialog.setContent(mapdiv);

					$.netrGoogleMap.loadAPI().then(function () {
						mapdiv.netrGoogleMap({
							vcards: map.find('.vcard'),
							zoomLevel: 11
						});
					});
				});
			}

			dialog.open();
		}
	};
});
