var AppToggler = {
	init: function () {
		'use strict';

		var self = this;

		this.element = $('[data-toggle]');

		var name = this.element.data('toggle');
		var toggler = $('[data-toggler-for="' + name + '"]');
		var toggler_html = toggler.html();
		var	button = $('<button>', {
			'class': 'link',
			'type': 'button',
			'html': toggler_html,
			'click': function (e) {
				e.preventDefault();

				// $(this) cuz JS scope
				$(this).toggleClass('inactive');
				self.element.toggleClass('collapsed');

				if (!$(this).is('.inactive')) {
					self.unStickyfyToggler($(this));
				}
			}
		});

		if (this.element.data('init-collapsed')) {
			button.click();
		}

		toggler.empty().append(button);

		$.breakpoint((function () {
			return {
				condition: function () {
					return window.matchMedia('only screen and (min-width:1024px)').matches;
				},
				enter: function () {
					if (self.element.is('.collapsed')) {
						var toggler_button = self.element.find('[data-toggler-for] button');
						toggler_button.click();
					}
				}
			};
		}()));

		this.stickyfyToggler();
	},

	stickyfyToggler: function () {
		'use strict';

		var self = this;
		var body = $('body');
		var group_offset = this.element.offset().top;

		$(window).on('resize', function () {
			group_offset = self.element.offset().top;
		});

		$(window).on('load scroll', function () {
			// Is the group collapsed?
			if (self.element.is('.collapsed')) {
				var window_scroll_top = $(window).scrollTop();

				if (window_scroll_top >= group_offset) {
					body.addClass('sticky-input');
					body.css('padding-top', self.element.innerHeight());
				}
				else {
					self.unStickyfyToggler();
				}
			}
		});
	},

	unStickyfyToggler: function (target) {
		'use strict';

		var body = $('body');
		var was_sticky = body.is('.sticky-input');

		body
			.removeClass('sticky-input')
			.css('padding-top', 0);

		// Are we toggling the input group?
		if (target && was_sticky) {
			$(window).scrollTop(this.element.offset().top);
		}
	}
};

$(function () {
	'use strict';

	if ($('[data-toggle]').length) {
		AppToggler.init();
	}
});