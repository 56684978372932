$(function () {
	'use strict';

	$(document).on('click', '.tweet-image', function (e) {
		e.preventDefault();

		var link = $(this);
		var dialog = $.netrdialog({
			extraClass: 'dialog-image'
		});
		dialog.setContent('<img src="' + link.attr('href') + '" alt="" />');
		dialog.open();
	});
});
