/**
 * Compare two text inputs and set a custom validation message if they don’t match.
 */
(function ($) {
	'use strict';
	$.fn.compareFields = function () {
		return this.each(function () {
			var input = $(this);
			var compareTo = $('#' + $(this).attr('data-compare-to'));
			input.add(compareTo).on('change', function () {
				if (input.val() !== compareTo.val()) {
					input.get(0).setCustomValidity(input.attr('data-custom-validation-message'));
				}
				else {
					input.get(0).setCustomValidity('');
				}
			});
		});
	};
}(jQuery));