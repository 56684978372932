/* eslint-disable */
/* global grecaptcha */

(function () {
	'use strict';
	// Handling Recaptcha (invisible) in forms. Can only handle on instance on recaptcha at the moment.
	$(document).on('submit', '[data-recaptcha="true"]', function (event) {
		var formWithRecaptcha = $(event.target).closest('form')[0];

		if (!formWithRecaptcha.recaptchaChecked && formWithRecaptcha.checkValidity && formWithRecaptcha.checkValidity()) {
			event.preventDefault();
			grecaptcha.execute();
		}
	});

	var falsevar = false;
	if (falsevar) {
		setIframeCss();
	}
}());

function isIE() {
	return window.document.documentMode;
}

function setIframeCss(time) {
	if (time && isIE()) {
		setTimeout(function () {
			resetScroll();
		}, time);
		setInterval(function () {
			$("iframe").each(function () {
				if ($(this).parent().parent().length > 0) {
					var parentTop = $(this).parent().parent()[0].style.top.split("px")[0];
					var calculatedTop = -parentTop + (Number($("#email-form").closest(".dialog")[0].style.top.split("px")[0]) + 40);
					$(this).parent().css("top", calculatedTop + 'px').css("z-index", "2147483647");
				}
			});
		}, time);
	}
}

function resetScroll() {
	if ($('#email-form').closest('.dialog')[0]) {
		$(window).scrollTop($('#email-form').closest('.dialog')[0].style.top.split('px')[0]);
	}
}
/*
setInterval(function () {
	if (buttonIsPressed) {
		$("iframe").each(function () {
			if ($(this).parent().parent().length > 0) {
				var parentTop = $(this).parent().parent()[0].style.top.split("px")[0];
				var calculatedTop = -parentTop + (Number($("#email-form").closest(".dialog")[0].style.top.split("px")[0]) + 40);
				$(this).parent().css("top", calculatedTop + 'px').css("z-index", "2147483647");
			}
		})
	}
}, 1000);*/