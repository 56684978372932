/* globals YT, loadYoutubeAPI */

$(function () {
	'use strict';

	var player;
	var ARROW_VISIBLE_LIMIT = 15; // How many pixels of the text content that should be visible not to show arrow
	var VIDEO_CONTAINER_ID = 'video-container';

	var scrollToContent = function () {
		// Scrolls down to the content (minus 30px for some white-space)
		$('html, body').animate({
			scrollTop: $('#content-primary').offset().top - 30
		}, 750, 'easeInOutQuad');
	};

	$('.section-intro-image').each(function () {
		var container = $(this);
		var image_wrapper = container.find('.section-intro-image-image');
		var arrow;

		var showArrow = function () {
			if ($('#content-primary').length) {
				return window.matchMedia('only screen and (min-width:701px)').matches && $('#content-primary').offset().top > $(window).height() + $(window).scrollTop() - ARROW_VISIBLE_LIMIT;
			}
			else {
				return false;
			}
		};

		if (!arrow && showArrow()) {
			arrow = $('<div />');
			arrow.addClass('arrow')
				.attr('aria-hidden', 'true')
				.click(scrollToContent);

			container.append(arrow);
		}

		$(window).on('scroll', function () {
			var scroll_top = $(window).scrollTop();

			if (window.matchMedia('only screen and (min-width:701px)').matches) {
				if (scroll_top < ((container.height() * 0.285714)) * 4) {
					image_wrapper.css('top', parseInt(scroll_top * 0.25, 10) * -1);
				}
			}
			else {
				image_wrapper.css('top', 0);
			}

			if (arrow && !showArrow()) {
				arrow.addClass('hide').unbind('click');
			}
		});
	});

	// Fetch and play video
	$('.section-intro-play').each(function () {
		var group_inner;
		var close_button;

		$(this).on('click', function (e) {
			e.preventDefault();

			var link = $(this);
			var section = link.closest('.section-intro-image');
			var image = section.find('img');

			// Fade to black
			section.css('min-height', section.find('img').outerHeight()).addClass('theater-mode');

			setTimeout(function () {
				image.detachWithPlaceholder();

				$.ajax({
					url: link.attr('href'),
					success: function (response) {
						var new_html = $(response);

						if (!group_inner) {
							group_inner = $('<div>', {
								'class': 'group-inner'
							});
						}

						if (!close_button) {
							close_button = $('<button>', {
								'type': 'button',
								'class': 'link section-intro-image-close',
								'html': '<span>' + netr.string.translate('sectionIntroImage.close') + '</span>',
								'click': function (e) {
									e.preventDefault();

									if (group_inner.data('video-type') === 'youtube') {
										player.stopVideo();
										group_inner.hide();
									}
									else {
										group_inner.remove();
									}

									section.removeClass('theater-mode').css('min-height', '0');
									image.attachToPlaceholder();
								}
							});
						}

						var link = new_html.find('a[href*="//www.youtube.com"]');

						// YouTube
						if (link.length) {
							if (group_inner.find('#' + VIDEO_CONTAINER_ID).length && player) {
								group_inner.fadeIn();
								/* ========================
								Disabled YouTube autoplay due to YouTube API violation (Support #15439):
								"A page or screen must not have more than one YouTube player that automatically plays content simultaneously."
								https://developers.google.com/youtube/terms/required-minimum-functionality
								======================== */
								// player.playVideo();
							}
							else {
								group_inner.data('video-type', 'youtube');
								createPlayer(group_inner, link);
							}
						}
						// Qbrick
						else {
							group_inner.data('video-type', 'qbrick');
							group_inner.append(new_html);
						}

						group_inner.prepend(close_button);
						section.append(group_inner).css('min-height', '0');
					}
				});
			}, 500);

			// Custom event to stop or pause the video when another is being played in teasers
			$(document).on('stopHeroVideos', function() {
				// Reset video like the close button
				if (group_inner.data('video-type') === 'youtube') {
					player.stopVideo();
					group_inner.hide();
				}
				else {
					group_inner.remove();
				}

				section.removeClass('theater-mode').css('min-height', '0');
				image.attachToPlaceholder();
			});

		});
	});

	function createPlayer (context, link) {
		var videoID = new netr.URI(link.attr('href')).query.v;
		var video_container = $('<div>', {
			'id': VIDEO_CONTAINER_ID
		});

		var onPlayerReady = function () {
			/* ========================
			Disabled YouTube autoplay due to YouTube API violation (Support #15439):
			"A page or screen must not have more than one YouTube player that automatically plays content simultaneously."
			https://developers.google.com/youtube/terms/required-minimum-functionality
			======================== */
			player.playVideo();
			$(document).trigger('pauseTeaserVideos');
		};

		loadYoutubeAPI().then(function () {
			context.append(video_container);

			setTimeout(function () {
				player = new YT.Player(VIDEO_CONTAINER_ID, {
					videoId: videoID,
					events: {
						'onReady': onPlayerReady
					},
					width: context.width(),
					height: parseInt((context.width() / 16) * 9, 10),
					playerVars: {
						rel: 0
					}
				});
			}, 0);
		});
	}
});
