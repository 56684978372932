
/* global documentReadyFunctions */
$(function () {
	'use strict';

	var product_finder = $('.c-product-finder');

	if (!product_finder.length) {
		return;
	}

	/**
	 * Will post the form with XHR and the update the selected element from the response.
	 * data-ajax-submit="<target selector>"
	 */
	$('body').on('submit', '[data-ajax-submit]', function (event) {
		var form = $(this);
		var target = form.data('ajax-submit');
		var result = $('.c-product-finder-result');
		var url = new netr.URI(window.location).setQuery(netr.URI.parseQuery(form.serialize()));

		$(target).addClass('is-loading');

		// Scroll down to the result area.
		if (result.length) {
			$('html').animate({
				scrollTop: result.offset().top - 30
			}, 1000);
		}

		$.ajax({
			url: this.action,
			method: this.method,
			data: form.serialize(),
			error: function (xhr, status, error) {
				console.warn(status, ':', error);
			}
		})
			.success(function (data) {
				updateResult({target: target, content: $(data).find(target)});
			});

		history.pushState(null, window.title, url);
		event.preventDefault();
	});

	/**
	 * @param { Object } parameters - Object with the parameters.
	 * @param { String } parameters.target - Selector to the element that will be updated with the new content.
	 * @param { Element } parameters.content - The new content.
	 */
	function updateResult (parameters) {
		if (!parameters || !parameters.target || !parameters.content) {
			return;
		}

		$(parameters.target).replaceWith(parameters.content);
		var new_target = $(parameters.target);
		// Initilize the table to be responsive and the other nice table features.
		new_target.addClass('c-product-finder-result--new');
		documentReadyFunctions(new_target);
		$(window).trigger('resize');
	}

	$('[data-product-finder-panel]').not('.c-product-finder-active').find('input, select').attr('disabled', true);

	product_finder.on('change', '[data-product-finder-panel-swap]', function (event) {
		var options = $(event.target).data('product-finder-panel-swap').split(':');
		var scope = options[0];
		var target = options[1];

		$('[data-product-finder-panel*="' + scope + ':"]')
			.removeClass('c-product-finder-active')
			.find('input, select').attr('disabled', true);

		$('[data-product-finder-panel="' + scope + ':' + target + '"]')
			.addClass('c-product-finder-active');

		$('[data-product-finder-panel="' + scope + ':' + target + '"]').find('input, select').attr('disabled', false);
		$('[data-product-finder-panel="' + scope + ':' + target + '"] > [data-product-finder-panel]').not('.c-product-finder-active').find('input, select').attr('disabled', true);
	});

	product_finder.on('reset', function (event) {
		event.preventDefault();

		var inputs = $('input', product_finder).not('[data-product-finder-panel-swap], [type="button"], [type="submit"], [type="reset"]');
		var selects = $('select', product_finder);

		inputs.val('');
		selects.each(function () {
			$(this).val('').trigger('change');
		});

	});

});
