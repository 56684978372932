/* globals cision */

$(function () {
	'use strict';
	$.support.cors = true;

	if ($('#templateTicker').length) {
		cision.websolution.tickers.render();
		$('.share-teaser').removeClass('is-loading');
	}
});