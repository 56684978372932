$(function () {
	'use strict';

	// Look through search results and find maps/images.
	// If found, place them in a spot appriopriate for
	// being floated right of the content of the result.
	$('.search-result').each(function () {
		var result = $(this);
		var image = result.find('img');

		if (image) {
			$.breakpoint((function () {
				return {
					condition: function () {
						return window.matchMedia('only screen and (max-width:700px)').matches;
					},
					enter: function () {
						if (result.is('.office')) {
							image = result.find('.expandable-map');
						}

						if (result.is('.search-result-image')) {
							image.detachWithPlaceholder().insertBefore(result.find('p:first'));
						}
					},
					exit: function () {
						image.attachToPlaceholder();
					}
				};
			}()));
		}
	});

	// Add toggle button for other websites
	$('.search-results').each(function () {
		var results = $(this);
		var other_websites = results.find('.search-other-websites');
		var heading = other_websites.find('.search-other-websites-heading');

		if (other_websites.length) {
			var button = $('<button>', {
				'class': 'link search-other-websites-toggler',
				'type': 'button',
				'html': '<span>' + heading.text() + '</span>',
				'click': function () {
					other_websites.slideToggle(200);
					button.toggleClass('expanded');
				}
			});

			heading.addClass('structural');
			other_websites.hide();
			other_websites.prev().append('. ').append(button);
		}
	});
});
