window.getFragments = (function () {
	'use strict';

	/**
	 * Get the element matching the fragment part of a URL via Ajax
	 * @param {object/string} options Object with options, or a string containing a URL
	 * @param {array/string} ids Array with id's of elements to fetch OR string with an id
	 */
	return function (options, ids) {
		var deferred = new $.Deferred();

		if (typeof options === 'string') {
			options = {
				url: options
			};
		}

		try {
			var url = new URL(options.url, document.location);
		}
		catch (err) {
			deferred.reject();
		}

		// Ensure we have an array of id's with no leading hash sign
		ids = []
			.concat(ids || [url.hash])
			.map(function (x) { return x.replace('#', ''); });

		if (ids.length === 0) {
			throw new Error('getFragments is missing id\'s.');
		}

		var success_callback = options.success;

		delete options.success;

		// IE seems to sometimes escape the hash part and send it along to the server.
		// This might cause an error, so we have to take the hash part away before requesting
		url.hash = '';
		options.url = url;

		$.ajax(options)
			.done(function (data, textStatus, jqXHR) {
				data = $(data);

				var matched_elements = $();

				// Remove any text nodes.
				data = data.filter(function () {
					return this.nodeType !== 3;
				});

				if (data.length === 0) {
					warn('Found no content at ' + url);
				}

				$.each(ids, function (index, id) {
					var element;
					var fragment = '#' + id;

					if (data.length === 1) {
						if (data.is(fragment)) {
							element = data;
						}
						else {
							element = $(fragment, data);
						}
					}
					else {
						element = data.find('*').andSelf().filter(fragment);
					}

					if (element.length) {
						matched_elements = matched_elements.add(element);
					}
					else {
						warn('Couldn\'t find element ' + fragment);
					}
				});

				/**
				 * Warn using console
				 * @param  {string} warning Warning text
				 */
				function warn (warning) {
					if (console && console.warn) {
						console.warn(warning);
					}
				}

				if (success_callback) {
					success_callback(matched_elements, textStatus, jqXHR);
				}

				deferred.resolve(matched_elements, textStatus, jqXHR);
			})

			.fail(function (jqXHR, textStatus, errorThrown) {
				deferred.reject(jqXHR, textStatus, errorThrown);
			});

		return deferred;
	};
}());
