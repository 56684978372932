$(function () {
	'use strict';

	var filters = $('.filters');

	$.breakpoint((function () {
		var form;

		return {
			condition: function () {
				return window.matchMedia('only screen and (max-width:900px)').matches;
			},
			first_enter: function () {
				form = $('.on-page-search');
			},
			enter: function () {
				if (form.length) {
					form.after(filters.detachWithPlaceholder());
					filters.addClass('filters-after-form');
				}
			},
			exit: function () {
				filters.attachToPlaceholder();
				filters.removeClass('filters-after-form');
			}
		};
	}()));
});
