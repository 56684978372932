/* global YT, loadYoutubeAPI */

(function ($) {
	'use strict';

	var aspects = {
		FOUR_TO_THREE: 3 / 4,
		SIXTEEN_TO_NINE: 9 / 16
	};
	
	var players = {};

	function extractVideoID(url){
		/* eslint-disable-next-line no-useless-escape */
		var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
		var match = url.match(regExp);
		return match && match.length ? match[1] : null;
	}

	$.fn.youtubethumb = function () {
		return this.each(function (index) {
			var link = $(this);
			var linkOpensInDialog = link.is('.youtube-thumb-dialog');
			var img = link.find('img');
			var width = link.parent().width();
			var url;
			var clip;
			var aspect;
			var videoID = extractVideoID(link.attr('href'));
			var playerID = 'player-' + index;
			var firstPlay = true;
			var movie = $('<div>').addClass('video-container').append($('<div>').attr('id', playerID));
			var heroVideoContainer = link.parent('.hero-video');

			if (videoID) {
				if (!img.length) {

					link.addClass('youtube-thumb');

					// Build API URL
					url = new netr.URI('https://gdata.youtube.com/feeds/api/videos/');
					url.path = url.path + videoID;
					url.query = {
						v: 2,
						alt: 'jsonc'
					};

					// Do a JSON request to YouTube (&callback=? needed for IE9)
					$.getJSON(url.toString() + '&callback=?', function (data) {
						var json = data;

						aspect = json.data.aspectRatio === 'widescreen' ? aspects.SIXTEEN_TO_NINE : aspects.FOUR_TO_THREE;

						// Replace link contents with thumbnail
						link.attr('title', json.data.title);

						clip = $('<div>', {
							css: {
								'text-align': 'center',
								overflow: 'hidden',
								// width: width,
								height: Math.round(width * aspect)
							}
						});

						img = $('<img>', {
							width: width,
							height: Math.round(width * aspects.FOUR_TO_THREE),
							src: json.data.thumbnail.hqDefault,
							alt: 'Play "' + json.data.title + '"'

						});

						if (aspect === aspects.SIXTEEN_TO_NINE) {
							clip.css('height', Math.round(width * aspects.SIXTEEN_TO_NINE));
							img.css('margin-top', -Math.round(((width * aspects.FOUR_TO_THREE) - (width * aspects.SIXTEEN_TO_NINE)) / 2));
						}
						else {
							clip.css('height', Math.round(width * aspects.FOUR_TO_THREE));
						}

						link.empty().append(clip.append(img, '<span class="play-overlay"></span>'));
					});

				}

				var initPlayer = function () {
					var player;
					var dialog;

					// The API will call this function when the video player is ready.
					var onPlayerReady = function (event) {
						/* ========================
						Disabled YouTube autoplay due to YouTube API violation (Support #15439):
						"A page or screen must not have more than one YouTube player that automatically plays content simultaneously."
						https://developers.google.com/youtube/terms/required-minimum-functionality
						======================== */
						event.target.playVideo();

						// Determine when the user has watched half the video
						var log_progress_half = setInterval(function () {
							var progress = player.getCurrentTime() / player.getDuration();
							if (progress > 0.5) {
								try {
									window.dataLayer.push({
										eventCategory: 'Video',
										eventAction: 'Reached middle of video',
										eventLabel: player.A.videoData.title
									});
								}
								catch (e) {}

								clearInterval(log_progress_half);
							}
						}, 1000);

						// Determine when the user has watched the entire video
						var log_progress_full = setInterval(function () {
							var progress = player.getCurrentTime() / player.getDuration();
							if (progress > 0.9) {
								try {
									// Log event in Google Analytics
									window.dataLayer.push({
										eventCategory: 'Video',
										eventAction: 'Reached end of video',
										eventLabel: player.A.videoData.title
									});
								}
								catch (e) {}

								clearInterval(log_progress_full);
							}
						}, 1000);
					};

					// The API calls this function when the player's state changes.
					var onPlayerStateChange = function (event) {
						if (event.data === YT.PlayerState.PLAYING) {

							for(var key in players) {
								if(key !== playerID && players[key] && players[key].pauseVideo) {
									players[key].pauseVideo();
								}
							}

							// Trigger pause for heroes
							$(document).trigger('stopHeroVideoContainers');

							if (firstPlay) {
								try {
									// Log event in Google Analytics
									window.dataLayer.push({
										eventCategory: 'Video',
										eventAction: 'Video started',
										eventLabel: event.target.A.videoData.title
									});
								}
								catch (e) {}

								firstPlay = false;
							}
						}
					};
					
					// Custom event to stop or pause the video when another is being played in hero components
					$(document).on('pauseTeaserVideos', function() {
						for(var key in players) {
							if(players[key] && players[key].pauseVideo) {
								players[key].pauseVideo();
							}
						}
					});

					if (linkOpensInDialog && window.matchMedia('only screen and (min-width:701px)').matches) {
						dialog = $.netrdialog({
							extraClass: 'dialog-video'
						});
						dialog.setContent(movie);
						dialog.open();

						setTimeout(function () {
							dialog.dialogElement.css('top', Math.max($(document).scrollTop() + 20, $(document).scrollTop() + ($(window).height() / 2) - (dialog.dialogElement.height() / 2)));
						}, 100);
					}
					else {
						if (heroVideoContainer.length){
							heroVideoContainer.find('img').hide();
						}
						link.hide().after(movie);
					}

					loadYoutubeAPI().then(function () {
						player = new YT.Player(playerID, {
							videoId: videoID,
							playerVars: { rel: 0, autoplay: linkOpensInDialog ? 1 : 0 },
							events: {
								'onReady': onPlayerReady,
								'onStateChange': onPlayerStateChange
							}
						});

						players[playerID] = player;

					});

					if (dialog) {
						dialog.position();
					}
				};

				link.click(function (e) {
					e.preventDefault();
					e.stopPropagation();

					initPlayer();
				});

				/* ========================
				Disable YouTube overlay due to YouTube API violation (Support #15439):
				"You must not display overlays, frames, or other visual elements in front of any part of a YouTube embedded player."
				https://developers.google.com/youtube/terms/required-minimum-functionality
				======================== */
				// link.not('.youtube-thumb-dialog, .youtube-play-button').trigger('click');
			}
		});
	};

}(jQuery));