$(function () {
	'use strict';

	$('.file-list.collapsable').each(function () {
		var file_list = $(this);
		var folders = file_list.find('.folder');

		folders.each(function () {
			var folder = $(this);

			var button = $('<button>', {
				'type': 'button',
				'class': 'button link',
				'html': '<span>' + folder.text() + '</span>',
				'click': function (e) {
					e.preventDefault();

					if (folder.parent().is('.closed')) {
						folder.parent().removeClass('closed').addClass('open');
					}
					else {
						folder.parent().removeClass('open').addClass('closed');
					}
				}
			});

			folder.empty().append(button);
		});

		// file_list.find('.collapse > ul').addClass('hidden');
	});
});