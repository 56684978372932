(function ($) {
	'use strict';

	var RatingTool = function (rangeElement, options) {
		var self = this;

		this.options = $.extend({
			min: parseFloat(rangeElement.attr('min'), 10) || 0,
			max: parseFloat(rangeElement.attr('max'), 10) || 10,
			step: parseFloat(rangeElement.attr('step'), 10) || 1,
			value: parseFloat(rangeElement.val(), 10) || 5,
			valuetextFormat: '{value} stjärnor'
		}, options || {});

		this.rangeElement = rangeElement.hide();

		this.activeRating = this.options.value;

		this.container = $('<span>', {
			'class': 'rating-tool',
			'tabindex': 0,
			'role': 'range',
			'aria-labelledby': rangeElement.getLabel().generateRandomId(false).attr('id'),
			'aria-valuemax': this.options.max,
			'aria-valuemin': this.options.min,
			'aria-valuenow': this.activeRating,
			'aria-valuetext': netr.string.supplant(this.options.valuetextFormat, {value: this.activeRating}),
			mouseover: function (e) {
				var target = $(e.target);

				if (target.is('.step')) {
					self.preview(parseFloat(target.attr('data-step-value'), 10));
				}
			},
			mouseleave: function () {
				self.set(self.activeRating);
			},
			click: function (e) {
				var target = $(e.target);
				if (target.is('.step')) {
					self.set(parseFloat(target.attr('data-step-value'), 10));
				}
			},
			keydown: function (e) {
				switch (e.keyCode) {
					case 38: // Up
					case 39: // Right
					case 107: // Plus
						e.preventDefault();
						self.increase(self.options.step);
						break;
					case 37: // Left
					case 40: // Down
					case 109: // Minus
						e.preventDefault();
						self.decrease(self.options.step);
						break;
					case 33: // Page Up
						e.preventDefault();
						self.set(self.options.max);
						break;
					case 34: // Page Down
						e.preventDefault();
						self.set(self.options.min);
						break;
				}
			}
		});

		this.meter = $('<span class="meter"></span>').appendTo(this.container);

		// Update meter when size of stars changes
		$.breakpoint((function () {
			return {
				condition: function () {
					try {
						return window.matchMedia('only screen and (max-width:900px) and (min-width: 700px)').matches;
					}
					catch (err) {
						return false;
					}
				},
				enter: function () {
					self.preview(self.activeRating);
				},
				exit: function () {
					self.preview(self.activeRating);
				}
			};
		}()));

		for (var val = this.options.min, max = this.options.max; val <= max; val += this.options.step) {
			this.container.append($('<span>', {
				'class': 'step',
				'data-step-value': val,
				'html': $('<span>', {
					'class': 'structural',
					'html': 'Give rating ' + val
				})
			}));
		}

		// Temporarily append unvisible container to calculate width
		this.container.css('visibility', 'hidden').appendTo('body');
		this.set(this.activeRating);
		// Make container visible again and insert it
		this.container.css('visibility', 'visible').insertAfter(this.rangeElement);

	};
	RatingTool.prototype = {
		increase: function (value) {
			var newRating = this.activeRating + value;
			if (newRating <= this.options.max) {
				this.set(newRating);
			}
		},
		decrease: function (value) {
			var newRating = this.activeRating - value;
			if (newRating >= this.options.min) {
				this.set(newRating);
			}
		},
		preview: function (value) {
			this.meter.css({
				width: this.container.width() * (value / this.options.max)
			});
		},
		set: function (value) {
			this.preview(value);
			this.rangeElement.val(value);
			this.container.attr('aria-valuenow', value);
			this.container.attr('aria-valuetext', netr.string.supplant(this.options.valuetextFormat, {value: this.activeRating}));
			this.activeRating = value;
		}
	};

	$.fn.netrRatingTool = function (options) {
		return this.each(function () {
			new RatingTool($(this), options);
		});
	};
}(jQuery));