(function ($) {
	'use strict';

	$.fn.elementToggler = function (context) {
		return this.each(function () {
			var toggler = $(this);
			var id = toggler.data('toggles');
			var target = $('#' + id, context);

			if(target.length) {
				toggler.attr({
					'aria-controls': id,
					'aria-expanded': toggler.prop('checked')
				});
			}

			target.addClass('fade-in');
			target.attr('aria-live', 'polite');

			// Checkboxes & radio buttons
			if (toggler.is('[type="checkbox"], [type="radio"]')) {
				if (toggler.prop('checked')) {
					target.show();
				}
				else {
					target.hide();
				}

				toggler.on('change', function (e) {
					e.preventDefault();
					if(target.length){
						toggler.attr('aria-expanded', toggler.prop('checked'));
					}

					// Make radio input change close targeted section of inputs charing the 'name' attribute
					if (toggler.is('[type="radio"]')) {
						var radioNameGroup = $('input[type="radio"][name="' + toggler.prop('name') + '"]').not(toggler);
						if(radioNameGroup.length) {
							radioNameGroup.each(function() {
								var closeTarget =  $('#' + $(this).data('toggles'));
								if(closeTarget.length) {
									closeTarget.hide();
									$(this).attr('aria-expanded', $(this).prop('checked'));
								}
							});
						}
					}

					if (toggler.attr('aria-expanded') !== 'true') {
						target.hide();
					}
					else {
						target.show();
					}
				});
			}
			// Other togglers
			else {
				target.attr('tabindex', '-1');
				if (toggler.attr('aria-expanded') !== 'true') {
					target.hide();
				}
				toggler.on('click', function (e) {
					e.preventDefault();
					toggler.attr('aria-expanded', (toggler.attr('aria-expanded') !== 'true'));
					if (toggler.attr('aria-expanded') !== 'true') {
						target.hide();
					}
					else {
						target.show();
						target.focus();
					}
				});
			}
		});
	};
}(jQuery));
