/* globals Modernizr */
$(function () {
	'use strict';

	$('.world-map ul a').on('mouseenter', function () {
		$('.continent[data-continent="' + $(this).data('continent') + '"]').attr('class', 'continent hover');
	}).on('mouseleave', function () {
		$('.continent[data-continent="' + $(this).data('continent') + '"]').attr('class', 'continent');
	});

	if (!Modernizr.svg) {
		$('.world-map').append('<img src="/gui/i/world-map.png" />');
	}
});