/* globals documentReadyFunctions,Column */

(function ($) {
	'use strict';

	$.fn.tablefunctions = function (options) {
		// Merge defaults with any specific options.
		options = $.extend({}, $.fn.tablefunctions.defaults, options);

		return $(this).each(function () {
			var table = $(this);
			var thead = table.children('thead').first();

			// Wrap table in <div> if inside an .article-inner
			if (!table.is('.table-narrow')) {
				if (table.parents('.article-inner').length) {
					var wrapper = $('<div>', {
						'class': 'article-table'
					});
					table.before(wrapper);
					wrapper.append(table);
				}
			}

			// Ensure we have a <thead>.
			if (thead.length === 0) {
				thead = $('<thead>');

				table.find('tr').each(function (index, row) {
					if ($(row).children(':not(th)').length === 0) {
						thead.append(row);
					}
					else {
						// Exit loop.
						return false;
					}
				});

				table.prepend(thead);
			}

			// We can only do this is if we have a header row.
			if (thead.children().length > 0) {
				// if (!table.is('[data-paging="false"]')) {
				// 	table.tablepaging();
				// }

				if (!table.is('[data-columnpicker="false"]')) {
					table.columnpicker();
				}

				thead.stickytableheaders();
			}

			// if (!table.is('[data-tablescroll="false"]')) {
			// 	table.tablescroll();
			// }
		});
	};

	// Default options.
	$.fn.tablefunctions.defaults = {
	};

	// Make tables horizontally scrollable/swipeable
	// on smaller screens.
	$.fn.tablescroll = function (options) {
		// Merge defaults with any specific options.
		options = $.extend({}, $.fn.tablescroll.defaults, options);

		var self = this;

		self.each(function () {
			var table = $(this);

			if (((window.matchMedia && window.matchMedia(options.mediaquery).matches)) || $('.dialog').length || table.is('[data-prevent-table-dialog="true"]')) {

				if ((table.outerWidth() > table.parent().outerWidth()) && (!table.is('[data-tablescroll="false"]'))) {
					if (!table.closest('.scrollable-table').length) {
						table
							.wrap('<div class="scrollable-table"><div class="inner"></div></div>')
							.addClass('scrollable');

						table.parent().on('scroll', function () {
							var self = $(this);
							var shadow_div = self.parent();

							if (self.scrollLeft() + 1 >= self.find('table').outerWidth() - self.outerWidth()) {
								shadow_div.addClass('no-shadow-right');
							}
							else {
								shadow_div.removeClass('no-shadow-right');
							}

							if (self.scrollLeft() > 0) {
								shadow_div.addClass('shadow-left');
							}
							else {
								shadow_div.removeClass('shadow-left');
							}
						});
					}
				}
				else if (table.hasClass('scrollable')) {
					table.removeClass('scrollable').unwrap().unwrap();
				}
			}

			else {

				if (table.outerWidth() > table.parent().outerWidth() || table.is('.table-dialog')) {
					if (table.is(':visible')) {
						var notes_selector = '.table-notes, .footnotes, .footnote';
						var notes = (table.parent().is('.article-table')) ? table.parent().next(notes_selector) : table.next(notes_selector);

						var heading = (table.parent().is('.article-table')) ? table.parent().prev() : table.prev();
						var has_heading = heading.is('h1, h2, h3, h4, h5, h6');

						var text = (function () {
							var caption = table.find('caption');
							if (caption.length) {
								if (caption.children('p').length) {
									return caption.children('p').html();
								}
								else {
									return caption.html();
								}
							}
							else {
								return netr.string.translate('tables.showTable');
							}
						}());

						var guiBase = $('html').attr('data-ssg-gui-base') || $('html').attr('data-gui-base');
						var paragraph = $('<p>');
						var table_toggler = $('<button>', {
							'type': 'button',
							'class': 'button button-style-3 hidden-on-print',
							'html': '<span class="icon icon-table"><svg class="i--table"><use xlink:href="' + guiBase + 'i/icons.svg#table" /></svg></span><span>' + text + '</span>',
							'click': function (e) {
								e.stopPropagation();
								var dialog = $.netrdialog({
									extraClass: 'dialog-table text'
								});

								table.removeClass('hidden-on-screen').detachWithPlaceholder();

								dialog.setContent(table);

								if (notes.length) {
									dialog.contentElement.append(
										notes.removeClass('hidden-on-screen').detachWithPlaceholder()
									);
								}

								if (has_heading) {
									dialog.contentElement.prepend($('<h2>', {
										'html': heading.text(),
										'class': 'article-heading-h2'
									}));
								}

								dialog.dialogElement.on('close.netrdialog', function () {
									table.addClass('hidden-on-screen').attachToPlaceholder();

									notes.addClass('hidden-on-screen').attachToPlaceholder();
								});

								dialog.open();
								dialog.position();
								documentReadyFunctions(dialog.contentElement);
							}
						});

						$('.sticky-header').detach();

						if (!table.data('no-button') === true) {
							table.addClass('hidden-on-screen').before(paragraph.append(table_toggler));

							if (notes.length) {
								notes.addClass('hidden-on-screen');
							}
						}
					}
				}
			}
		});

		return this;
	};

	$.fn.tablescroll.defaults = {
		mediaquery: 'screen and (max-width:900px)'
	};

	// Add paging to tall tables on smaller screens.
	$.fn.tablepaging = function () {
		return $(this).each(function () {
			var table = $(this);

			if (table.find('tr').length > 13) {
				var paging;
				var paging_button_up;
				var paging_button_down;
				var paging_summary;
				var tbody;
				var all_rows;
				var number_of_visible_rows = 11;
				var first_visible_row = 0;
				var last_visible_row = number_of_visible_rows;

				var updateVisibleRows = function () {
					all_rows.hide().slice(first_visible_row, last_visible_row).show();
					updatePagingSummaryText();
					updateButtons();
				};

				var updateButtons = function () {
					if (first_visible_row === 0) {
						paging_button_up.attr('aria-disabled', true);
						paging_button_up.attr('disabled', true);
					}
					else {
						paging_button_up.attr('aria-disabled', false);
						paging_button_up.removeAttr('disabled');
					}

					if (last_visible_row >= all_rows.length) {
						paging_button_down.attr('aria-disabled', true);
						paging_button_down.attr('disabled', true);
					}
					else {
						paging_button_down.attr('aria-disabled', false);
						paging_button_down.removeAttr('disabled');
					}
				};

				var updatePagingSummaryText = function () {
					var first_row = first_visible_row + 1;
					var last_row  = Math.min(last_visible_row, all_rows.length);

					if (first_row === last_row) {
						paging_summary.text(netr.string.supplant('Viewing row {first_row} of {total_rows}', {
							first_row: first_row,
							total_rows: table.find('tbody tr').length
						}));
					}
					else {
						paging_summary.text(netr.string.supplant('Viewing rows {first_row}-{last_row} of {total_rows}', {
							first_row: first_row,
							last_row: last_row,
							total_rows: table.find('tbody tr').length
						}));
					}
				};

				$.breakpoint((function () {
					return {
						condition: function condition () {
							return window.matchMedia('screen and (max-width:700px)').matches && !netr.forceDesktopLayout;
						},
						first_enter: function () {
							tbody = table.find('tbody');
							all_rows = tbody.children();

							paging = $('<div class="table-paging ↓">');

							paging_button_up = $('<button>', {
								'class': 'up',
								html: '<span>Up</span>',
								click: function () {
									first_visible_row = first_visible_row - number_of_visible_rows;
									last_visible_row = last_visible_row - number_of_visible_rows;
									updateVisibleRows();
								}
							});
							paging_button_down = $('<button>', {
								'class': 'down',
								html: '<span>Down</span>',
								click: function () {
									first_visible_row = first_visible_row + number_of_visible_rows;
									last_visible_row = last_visible_row + number_of_visible_rows;
									updateVisibleRows();
								}
							});

							paging_summary = $('<p class="paging-summary">');

							// Add some "padding"-rows to the last "page" of the paging.
							// This is to keep the paging buttons on the same place even
							// when there's fewer rows on the last page.
							var colspan = 0;

							table.find('thead tr:first > th').each(function () {
								var colspan_attribute = $(this).attr('colspan');

								if (colspan_attribute) {
									colspan = colspan + parseInt(colspan_attribute, 10);
								}
								else {
									colspan = colspan + 1;
								}
							});

							var extra_padding_rows = number_of_visible_rows - (all_rows.length % number_of_visible_rows);

							for (var i = 0; i < extra_padding_rows; i++) {
								tbody.append('<tr><td colspan="' + colspan + '">&nbsp;</td></tr>');
							}

							all_rows = tbody.children();

							paging.append(paging_summary, $('<div class="buttons">').append(paging_button_up, paging_button_down));
						},
						enter: function enter () {
							var closest_scroll_table = table.closest('.scrollable-table');

							updateVisibleRows();

							paging.insertAfter(closest_scroll_table.length ? closest_scroll_table : table);
						},
						exit: function () {
							paging.detach();
							all_rows.show();
						}
					};
				}()));
			}
		});
	};

	// Add the ability to pick which columns should
	// be visible in a table.
	$.fn.columnpicker = function () {
		return $(this).each(function () {
			var table = $(this);
			var thead = table.children('thead');
			var all_cells = table.find('td,th');
			var column_picker_list;
			var column_popup_summary;
			var column_list;
			var column_picker_button;
			var column_picker_button_text;
			var columns;
			var column_counter = 1;

			function updateTexts () {
				var texts = {
					active: $.grep(columns, function (column) {
						return column.visible;
					}).length,
					total: columns.length
				};

				column_picker_button_text.text(netr.string.supplant('Columns: {active} of {total}', texts));
				column_popup_summary.text(netr.string.supplant('{active} of {total} columns selected.', texts));
			}

			$.breakpoint((function () {
				return {
					condition: function condition () {
						return window.matchMedia('screen and (max-width:700px)').matches && !netr.forceDesktopLayout;
					},
					first_enter: function () {
						columns = [];

						thead.find('tr:first-child > th').each(function () {
							var th = $(this);
							var colspan = parseInt(th.attr('colspan'), 10) || 1;

							var column = new Column();
							columns.push(column);

							var max = column_counter + colspan;

							for (column_counter; column_counter < max; column_counter++) {
								column.cells = column.cells.add(all_cells.nthCol(column_counter));
							}
						});

						var column_dialog = $.netrdialog({
							extraClass: 'columpicker-dialog'
						});

						column_dialog.close();

						column_dialog.dialogElement.on('load.netrdialog', function () {
							documentReadyFunctions(column_dialog.contentElement);
						});

						column_picker_list = $('<div class="column-picker-list article" tabindex="0">');

						column_picker_list.append('<h1>Choose columns</h1>');
						column_popup_summary = $('<p>').appendTo(column_picker_list);
						column_list = $('<ul>').appendTo(column_picker_list);

						$.each(columns, function (index, column) {
							var input = $('<input>', {
								type: 'checkbox',
								checked: true,
								change: function () {
									column.toggle();
									updateTexts();
									if (!table.is('[data-tablescroll="false"]')) {
										table.tablescroll();
									}
								}
							}).generateRandomId();

							var label = $('<label>', {
								'for': input.attr('id')
							}).append(
								$('<b>', {
									html: column.getMainHeaderText()
								}),
								$('<span>', {
									html: ' ' + column.getSubHeaderText()
								})
							);
							column_list.append($('<li class="column-picker-checkbox checkbox">').append(input, label));
						});

						column_picker_list.append($('<button>', {
							'class': 'popup-close-button',
							'html': '<span>OK</span>',
							click: function () {
								column_dialog.close();
							}
						}));

						column_picker_button = $('<button>', {
							'class': 'column-picker-button',
							click: function (e) {
								e.stopPropagation();
								column_dialog.open();
							}
						});

						column_picker_button_text = $('<span>').appendTo(column_picker_button);

						column_dialog.setContent(column_picker_list);
					},
					enter: function enter () {
						var closest_scroll_table = table.closest('.scrollable-table');

						updateTexts();

						column_picker_button.insertBefore(closest_scroll_table.length ? closest_scroll_table : table);
					},
					exit: function () {
						column_picker_button.detach();

						$.each(columns, function (index, column) {
							column.show();
						});
					}
				};
			}()));
		});
	};

	// Makes a table header row stick to the top of the
	// viewport when a table is taller than the viewport.
	$.fn.stickytableheaders = function () {
		return this.each(function () {
			// Original element
			var thead = $(this);
			var cells = thead.find('th, td');
			var second_heading_row = (thead.next().find('tr:first th').length > 1) ? thead.next().find('tr:first') : undefined;
			var cells_2 = (second_heading_row) ? second_heading_row.find('th, td') : undefined;
			var div;
			var ul;
			var timeout;

			function handle () {
				var scrollTop = $(document).scrollTop();
				var table = thead.closest('table');
				var tableheight = table.height();
				// If scroll is further down than the offset top
				// of the original element and if the table is higher than the viewport
				if ((table.hasClass('table--sticky') && (scrollTop > thead.offset().top && scrollTop < thead.offset().top + tableheight - thead.height()) ) || (scrollTop > thead.offset().top && scrollTop < thead.offset().top + tableheight - thead.height() && tableheight > $(window).height())) {
					// Insert copy
					thead.offsetParent().append(div);

					// Remove previous copies
					if (div.prev().is('.sticky-header')) {
						div.prev().remove();
					}

					// Make the copy stick to the top of the viewport
					div.css({
						left: thead.offset().left,
						width: table.width()
					});

					if (table.is('.tight') || table.is('.tight-2')) {
						div.addClass('sticky-header-tight');
					}
				} else {
					// Detach copy from DOM, but don't delete it
					div.detach();
				}
			}

			function resizeHandler () {
				handle();

				// Clear current timeout
				if (typeof timeout !== 'undefined') {
					clearTimeout(timeout);
				}

				// Create cells after 200 ms instead of every time resize event is triggered
				timeout = setTimeout(function () {
					createCells();
				}, 200);
			}

			function createCells () {
				// Empty list
				ul.empty();

				// Loop over cells in thead
				cells.each(function (index) {
					// Create li and set content, style and dimensions based on corresponding original cell
					ul.append(createLi($(this), index, cells));
				});

				if (second_heading_row) {
					cells_2.each(function (index) {
						ul.append(createLi($(this), index, cells_2));
					});
				}
			}

			function createLi (element, index, cells) {				
				var li = $('<li class="th" />')
					.attr('style', element.attr('style'))
					.html(element.html())
					.width(cells.eq(index).outerWidth() - 1)
					.height(cells.eq(index).outerHeight());

				if (element.attr('align') !== '') {
					li.css('text-align', element.attr('align'));
				}

				if (index === 0) {
					li.css('clear', 'left');
				}

				return li;
			}

			$.breakpoint((function () {
				// Return the breakpoint object
				return {
					condition: function () {
						return window.matchMedia('only screen and (min-width:701px)').matches || netr.forceDesktopLayout;
					},
					first_enter: function () {
						div = $('<div class="sticky-header" aria-hidden="true">');
						ul = $('<ul>');

						createCells();

						div.append(ul);

						// Make sure header sticks on page load
						$(handle);
					},
					enter: function () {
						// Observe document scroll event
						$(window).on('scroll', handle);
						// Observe window resize event
						$(window).on('resize', resizeHandler);

					},
					exit: function () {
						// Observe document scroll event
						$(window).off('scroll', handle);
					}
				};
			}()));
		});
	};

	var Column = function () {
		this.cells = $();
		this.visible = true;
	};
	Column.prototype = {
		getMainHeaderText: function () {
			var cell = this.cells.first();
			var html = cell.html();

			// Replace linebreaks with a space.
			html = html.replace(/<br *\/*>/, ' ');

			var text = $('<span>', { html: html }).text();

			if (!text) {
				// Perhaps there are images with alt-texts?
				text = cell.find('img').attr('alt');
			}

			return text;
		},
		getSubHeaderText: function () {
			return $.map(this.cells.filter('thead th').slice(1), function (cell) {
				cell = $(cell);
				var text = cell.text();

				if (!text) {
					// Perhaps there are images with alt-texts?
					text = cell.find('img').attr('alt');
				}

				return text;
			}).join(', ');
		},
		show: function () {
			this.cells.show();
			this.visible = true;
		},
		hide: function () {
			this.cells.hide();
			this.visible = false;
		},
		toggle: function () {
			if (this.visible) {
				this.hide();
			} else {
				this.show();
			}
		}
	};

}(jQuery));
