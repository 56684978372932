$(function () {
	'use strict';

	$('table.corrosion-compare tbody td:not(:first-child)').each(function () {
		var td = $(this);

		switch (parseInt(td.text().match(/\d+/), 10)) {
			case 0:
				td.addClass('corrosion corrosion-0');
				break;
			case 1:
				td.addClass('corrosion corrosion-1');
				break;
			case 2:
				td.addClass('corrosion corrosion-2');
				break;
			default:
				break;
		}
	});
});