$(function () {
	'use strict';

	// Wrap last word and icon in span to prevent arrow from ever occupying a
	// new row on its own
	$('.illustration-list__link-text').each(function () {
		var $this = $(this);
		var words = $this.text().split(' ');
		var newHTML = '';

		for (var i = 0; i < words.length; i++) {
			if ((i + 1) !== words.length && words.length !== 1) {
				newHTML += words[i] + ' ';
			}
			else {
				newHTML += '<span class="illustration-list__text-icon-wrapper">';
				newHTML += words[i];
				newHTML += '<span class="illustration-list__arrow"></span></span>';
			}
		}

		$this.html(newHTML);
	});
});
