var EnableInputConstraints = function (element) {
	this.element = element;
	this.init();
};

EnableInputConstraints.prototype.init = function () {
	var element = this.element;
	var attributes = [
		'min',
		'max',
		'required',
		'step'
	];
	attributes.forEach(function (attribute) {
		var dataAttribute = 'data-' + attribute;
		if (element.hasAttribute(dataAttribute)) {
			element.setAttribute(attribute, attribute === 'required' ? '' : element.getAttribute(dataAttribute));
		}
	});
};
