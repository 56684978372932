/* globals icon */
$(function () {
	'use strict';

	$.fn.fileDragAndDrop = function () {

		// Declare variables
		var fileContainer = $(this);
		var input = fileContainer.find('input[type=file]');
		var form = fileContainer.closest('form');
		var formToken = form.find('input[name*="__RequestVerificationToken"]').val();

		// Create button for selecting file
		var button = $('<button>', {
			'type': 'button',
			'class': 'file-upload__button button button button-small button-style-2 button-color-2',
			'html': '<span class="icon icon-paperclip">' + icon('paperclip', '2', 'white', null, 'm') + '</span><span>' + netr.string.translate('fileUpload.buttonText') + '</span>',
			'click': function () {
				input.click();
			}
		});

		// Create drop area
		var dropArea = $('<div>', {
			'class': 'file-upload__drop-area',
			'html': [ '<span class="file-upload__drag-text">' + netr.string.translate('fileUpload.dragText') + '</span><ul class="file-upload__files"></ul>' ]
		});

		// Check if the browser supports drag and drop
		var check_supportsDragAndDrop = function () {
			var div = document.createElement('div');
			return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
		};

		if (check_supportsDragAndDrop) {
			// Hide default button
			fileContainer.find('label').addClass('structural');
			input.addClass('structural');
			// Add drop area
			fileContainer.append(dropArea);
			fileContainer.addClass('file-upload--drop-enabled');
			var fileList = dropArea.find('.file-upload__files');
			// Add new button
			fileContainer.append(button);
			// Drag and drop events
			fileContainer.on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
				e.preventDefault();
				e.stopPropagation();
				$('.file-upload__drag-text').text(netr.string.translate('fileUpload.dropText'));
			}).on('dragover dragenter', function () {
				dropArea.addClass('file-upload__drop-area--dragging');
			}).on('dragleave dragend drop', function () {
				dropArea.removeClass('file-upload__drop-area--dragging');
				$('.file-upload__drag-text').text(netr.string.translate('fileUpload.dragText'));
			}).on('drop', function (e) {
				var files = e.originalEvent.dataTransfer.files;
				uploadFiles(files);
			});
			input.on('change', function (e) {
				var files = e.target.files;
				uploadFiles(files);
				// Empty input field (wrap/unwrap is a IE solution)
				input.wrap('<form>');
				input.closest('form').get(0).reset();
				input.unwrap();
			});
		}

		function uploadFiles (files) {
			for (var i = 0; i < files.length; i++) {
				var formData = new FormData();
				formData.append('file', files[i]);
				formData.append('token', formToken);
				$.ajax({
					type: 'POST',
					async: false,
					processData: false,
					contentType: false,
					url: fileContainer.data('file-scan-url'),
					data: formData,
					beforeSend: function () {
						fileContainer.addClass('is-loading');
						input.attr('disabled', 'disabled');
					},
					success: function (response) {
						if (response[0].FileErrorMessages.length < 1) {
							displayFiles(response[0]);
						}
						else {
							var errorHeading = response[0].FileName ? response[0].FileName : netr.string.translate('fileUpload.errorMessage');
							var errorMsg = $('<div>', {
								'class': 'system-message error-message',
								'html': '<h3>' + errorHeading + '</h3><p>' + response[0].FileErrorMessages + '</p>'
							});
							fileContainer.prepend(errorMsg);
							errorMsg.attr('tabindex', '-1');
							errorMsg.focus();
						}
					},
					error: function (jqXHR) {
						alert(netr.string.translate('fileUpload.errorMessage') + ' (' + jqXHR.status + ' ' + jqXHR.statusText + ')');
					},
					complete: function () {
						fileContainer.removeClass('is-loading');
						input.removeAttr('disabled');
					},
				});
			}
		}

		function displayFiles (file) {
			// Create icon for removing files
			var removeIcon = $('<span>', {
				'html': icon('remove-close', 'white', null, null, 'xs') + '<span class="structural">' + netr.string.translate('fileUpload.removeFile') + '</span>',
				'click': function (e) {
					e.stopPropagation();
					// Remove hidden input and from dropArea
					fileContainer.find('#' + file.FileID).remove();
					$(this).closest('li').remove();
				}
			});
			// Create list items
			var fileItem = $('<li>', {
				'html': file.FileName
			});
			var fileInput = $('<input>', {
				'value': file.FileID,
				'name': 'uploaded-file',
				'type': 'hidden'
			});
			// Append icon and list item to ul
			fileItem.append(removeIcon);
			fileList.append(fileItem);
			fileContainer.append(fileInput);
		}

	};

});
