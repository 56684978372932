// Tell window that smt analytics are in use
window.smtAnalytics = true;

$(function () {
	'use strict';

	// -- Log language ----------------------------------------------------------------------------

	var lang = $('html').attr('lang');

	// -- 404 page --------------------------------------------------------------------------------

	if ($('body').is('#four-oh-four')) {
		if (window.dataLayer) {
			var url_404;

			// Is there a specific alternative url to log?
			var pageview_url_404 = $('html').attr('data-pageview-url');

			if (pageview_url_404 !== '') {
				url_404 = new netr.URI(pageview_url_404);
			}
			else {
				url_404 = new netr.URI(document.location.toString());
			}

			// Remove everything before path
			url_404.scheme = '';
			url_404.domain = '';
			url_404.port = '';

			var url_to_gtm = new netr.URI('/' + lang + '/404');
			url_to_gtm.query = {
				page: document.location.pathname + document.location.search,
				from: document.referrer
			};
			url_404 = url_to_gtm.toString();

			window.dataLayer.push({
				event: '404',
				eventData: url_404
			});
		}
	}

	// -- Search results --------------------------------------------------------------------------

	if ($('.search-results').length) {
		if (window.dataLayer) {
			// Are there any hits?
			if ($('.search-result').length === 0) {
				var url;

				// Is there a specific alternative url to log?
				var pageview_url = $('html').attr('data-pageview-url');

				if (typeof pageview_url !== 'undefined') {
					url = new netr.URI(pageview_url);
				}
				else {
					url = new netr.URI(document.location.toString());
				}

				// Remove everything before path
				url.scheme = '';
				url.domain = '';
				url.port = '';

				// Log search with no hits in a special category
				url.query.spec_cat = 'No hits';

				// Track page view
				window.dataLayer.push({
					event: 'zerohits',
					eventData: url.toString()
				});
			}
		}
	}

	// -- Bar Converter logging  -------------------------------------------------------------

	if ($('.bc-form').length) {
		$(window).on('load', function () {
			if (window.dataLayer) {
				var results = $('.bc-results');
				var no_hits = $('.no-hits');
				var error_message = $('.system-message.error-message');

				// Variables used in all cases
				var category = 'Bar Converter';
				var action;
				var label;
				var dimension2;
				var dimension3;
				var metric1;
				var metric2;

				// Variables used if successful calculation or no savings found
				if ((results.length) || (no_hits.length)) {
					dimension2 = $('.steel-selector :checked').val();
					dimension3 = ($('.unit-switch :checked').val() === 'mm') ? 'SEK' : 'USD';
					metric1 = parseInt($('#BodyContent_cost').val(), 10);
					metric2 = parseInt($('#BodyContent_ppps').val(), 10);
					label = new Date().toISOString();
				}

				// Successful calculation
				if (results.length) {
					action = 'Calculated savings';
					var metric3 = parseInt(results.find('.bc-results-result p strong').text().replace(/\D/g, ''), 10);

					window.dataLayer.push({
						event: 'barConverterEvent',
						eventCategory: category,
						eventAction: action,
						eventLabel: label,
						dimension2: dimension2,
						dimension3: dimension3,
						metric1: metric1,
						metric2: metric2,
						metric3: metric3
					});
				}
				// No savings found
				else if (no_hits.length) {
					action = 'No obvious savings';

					window.dataLayer.push({
						event: 'barConverterEvent',
						eventCategory: category,
						eventAction: action,
						eventLabel: label,
						dimension2: dimension2,
						dimension3: dimension3,
						metric1: metric1,
						metric2: metric2
					});
				}
				// Error message
				else if (error_message.length) {
					action = 'Error message';

					var error = $('.system-message.error-message');
					var errors = '';

					error.find('li').each(function (i) {
						if (i > 0) {
							errors += ', ';
						}

						errors += $(this).text();
					});

					label = errors;

					window.dataLayer.push({
						event: 'barConverterEvent',
						eventCategory: category,
						eventAction: action,
						eventLabel: label
					});
				}
			}
		});
	}

	// -- Wire selection guide logging  ------------------------------------------------------
	// -- Also see wire-selection-guide.js for error logging

	if ($('.wsg-form').length) {
		$(window).on('load', function () {
			if (window.dataLayer) {
				if ($('.wsg-results').length) {
					var category = 'Wire selection guide';
					var action = 'Test run';
					var label = $('.wsg-results-table tbody tr:not(.sub-th) td.col-grade').first().text().trim();

					window.dataLayer.push({
						event: 'wireSelectionGuideEvent',
						eventCategory: category,
						eventAction: action,
						eventLabel: label
					});
				}
			}
		});
	}

	// -- Log material datasheet search events  ----------------------------------------------

	if (($('.material-datasheet-search').length) || ($('.datasheet-info-download').length)) {
		if (window.dataLayer) {
			$('a').on('click', function (e) {
				var url = new netr.URI($(e.target).attr('href'));

				if (url.query.show === 'pdf') {
					e.preventDefault();

					// Remove everything before path
					url.scheme = '';
					url.domain = '';
					url.port = '';

					window.dataLayer.push({
						event: 'pageview',
						eventData: url.toString()
					});

					setTimeout(function () {
						document.location = $(e.target).attr('href');
					}, 100);
				}
				else if (url.query.show === 'email-pdf') {
					var target = $(e.target);

					target.bind('load.netrdialog', function () {
						if (target.netrdialog('getdialog').contentElement.find('.confirmation-message').length) {
							// Remove everything before path
							url.scheme = '';
							url.domain = '';
							url.port = '';

							// User has successfully submitted the form
							var category = 'Material datasheets';
							var action = 'Send PDF as email';
							var label = url.toString();

							window.dataLayer.push({
								event: 'materialDatasheetsEvent',
								eventCategory: category,
								eventAction: action,
								eventLabel: label
							});
						}
					});
				}
			});
		}
	}
});
