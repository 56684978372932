$(function () {
	'use strict';

	var blockquote_startpage = $('.extra-header-startpage');

	if (blockquote_startpage.length) {
		var blockquote = $('.extra-header-blockquote');
		var content_wrapper = $('#main .group-inner');
		var blockquote_wrapper;

		$.breakpoint((function () {
			return {
				condition: function () {
					return window.matchMedia('only screen and (max-width:900px)').matches;
				},
				first_enter: function () {
					blockquote_wrapper = $('<div>', {
						'id': 'blockquote-wrapper',
						'class': 'blockquote-wrapper',
						'aria-expanded': 'false'
					});
				},
				enter: function () {
					blockquote.detachWithPlaceholder();

					blockquote_wrapper.append(blockquote);
					content_wrapper.append(blockquote_wrapper);
				},
				exit: function () {
					blockquote.attachToPlaceholder();

					blockquote_wrapper.detach();
				}
			};
		}()));
	}
});