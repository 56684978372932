$(function () {
	'use strict';

	$('.table__check-all').on('click', function() {
		$(this)
			.closest('table')
			.find('tbody .table__checkbox')
			.prop('checked', this.checked);
	});

	$('.table__check-all').closest('table').find('tbody .table__checkbox').on('click', function() {
		// add selected class on check all
		$(this).closest('table')
			.find('.table__check-all')
			.prop('checked',
				$(this)
					.closest('table')
					.find('tbody .table__checkbox:checked').length ==
          $(this).closest('table').find('tbody .table__checkbox').length
			);
	});

	$('.table__check-all').closest('table').find('[data-check-change]').on('change', function() {
		var checkboxInput = $(this).parent().siblings('.checkbox').find('.table__checkbox');
		if(!checkboxInput.is(':checked')) {
			checkboxInput.prop('checked', true);
		}
	});

	$('.table--collapsible-rows').find('.table__button').each(function() {
		var tableId = $(this).attr('data-table-button');
		$(this).attr('aria-controls', tableId);
		$(this).attr('aria-expanded', false);
	});

	$('.table__button').on('click', function(event) {
		event.preventDefault();
		var tableId = $(this).attr('data-table-button');
		$(this).toggleClass('expanded');
		$('#'+tableId).toggle();
		$(this).attr('aria-expanded', $(this).hasClass('expanded'));
		$(this).closest('tr').toggleClass('table__collapsible-button-row');
	});
  
});