$(function () {
	'use strict';

	$.breakpoint((function () {
		var disclaimer = $('.datasheet-info .disclaimer');

		return {
			condition: function () {
				return window.matchMedia('only screen and (max-width:700px)').matches;
			},
			enter: function () {
				disclaimer.detachWithPlaceholder().insertAfter('.article');
			},
			exit: function () {
				disclaimer.attachToPlaceholder();
			}
		};
	}()));
});