(function ($) {
	'use strict';
	$.fn.toggleDisabledFields = function () {
		return this.each(function () {
			var radio = $(this);
			if (radio.is(':checked')) {
				$('#' + radio.attr('data-input-parent')).find('input, select, textarea').removeAttr('disabled');
			}
			else {
				$('#' + radio.attr('data-input-parent')).find('input, select, textarea').attr('disabled', 'disabled');
			}
		});
	};
}(jQuery));
