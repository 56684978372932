/* eslint-disable */
// Google Analytics tracking for all business areas
$(function () {
	'use strict';
	
	var body = $('body');

	// Save topic for sent email event.
	var most_recent_topic = '';
	
	// Prevent duplicated events by checking against this var before doing stuff
	var go_ahead = true;
	
	// Object containing analytics events tracking
	var analyticsTracking = {
		emailOpenEvent: function(label, action) {
			window.dataLayer.push({
				event: 'emailOpenEvent',
				eventCategory: 'Contact us',
				eventLabel: label ? label : 'Open e-mail dialouge button',
				eventAction: action ? action : 'Open e-mail dialogue'
			});
		},
		emailSendEvent: function(label, action) {
			window.dataLayer.push({
				event: 'emailSendEvent',
				eventCategory: 'Contact us',
				formLocation: window.location.pathname ? window.location.pathname : '',
				eventLabel: label ? label : 'Sent',
				eventAction: action ? action : 'Sent email'
			});
		},
		callUsOpenEvent: function(label) {
			window.dataLayer.push({
				event: 'callUsOpenEvent',
				eventCategory: 'Contact us',
				eventLabel: label ? label : 'Call us',
				eventAction: 'Open call us dialogue'
			});
		},
		printEvent: function() {
			window.dataLayer.push({
				event: 'printEvent',
				eventCategory: 'Print',
				eventAction: 'Open print dialogue',
				eventLabel: 'Not set'
			});
		},
		carouselEvent: function(label) {
			window.dataLayer.push({
				event: 'carouselEvent',
				eventCategory: 'Carousel',
				eventAction: 'Click on button',
				eventLabel: label,
			});
		},
		materialDatasheetsviewEvent: function() {
			window.dataLayer.push({
				event: 'materialDatasheetsviewEvent',
				eventCategory: 'Material datasheets',
				eventAction: 'Send PDF as email',
				eventLabel: window.location.href
			});
		},
		contactTabEvent: function() {
			window.dataLayer.push({
				event: 'contactTabEvent',
				eventCategory: 'Contact us',
				eventAction: 'View Contact information',
				eventLabel: window.location.href
			});
		},
		virtualPageview: function(eventData) {
			window.dataLayer.push({
				event: 'virtualPageview',
				eventData: eventData
			});
		}
	}

	$.fn.trackEmailOpenEvent = function (dialog) {
		if (window.dataLayer) {
			if (go_ahead) {
				var element = $(this);
				var label = element.text().trim();
				var action = '';

				go_ahead = false;

				if (!element.is('.open-email-dialog')) {
					action = 'Open "Send us a message" dialogue';
				}

				dialog.dialogElement.find('#topic').change(function () {
					most_recent_topic = $(this).val();
				}).change();

				analyticsTracking.emailOpenEvent(label, action)

				setTimeout(function () {
					go_ahead = true;
				}, 1000);
			}
		}
	}

	$.fn.trackEmailSendEvent = function () {
		if (window.dataLayer) {
			var el = $(this);
			var label;

			// Check if we've loaded the form in a dialog or not
			if (el.netrdialog('getdialog')) {
				// Check if the form was submitted successfully and we've loaded the confirmation message
				if (el.netrdialog('getdialog').contentElement.find('.confirmation-message').length) {
					if (go_ahead) {
						// No duplicates, please
						go_ahead = false;
						
						// User has successfully submitted the form
						var action = 'Sent e-mail dialogue';
						label = (most_recent_topic.length > 0) ? most_recent_topic : 'Sent';
						
						if (!el.is('.open-email-dialog')) {
							action = 'Send "Send us a message" dialogue';
						}
						
						analyticsTracking.emailSendEvent(label, action)

						setTimeout(function () {
							go_ahead = true;
						}, 1000);
					}
				}
			}
			else {
				if (go_ahead) {
					// No duplicates, please
					go_ahead = false;

					// User has successfully submitted the form
					label = (most_recent_topic.length > 0) ? most_recent_topic : 'Sent';
					
					analyticsTracking.emailSendEvent(label)

					setTimeout(function () {
						go_ahead = true;
					}, 1000);
				}
			}
		}
	}

	$.fn.trackCallUsOpenEvent = function (dialog) {
		if (window.dataLayer) {
			if (go_ahead) {
				var label = window.smtAnalytics = true ? dialog.contentElement.find('select:first').val() : '';

				go_ahead = false;

				analyticsTracking.callUsOpenEvent(label)

				setTimeout(function () {
					go_ahead = true;
				}, 1000);
			}
		}
	}
	
	// For forms on a static page that we would like to track
	// Send tracking when form is submitted successfully
	if (window.location.hash === '#email-form') {
		var systemMessageIsSuccess = $('.confirmation-message');

		if (systemMessageIsSuccess) {
			analyticsTracking.emailSendEvent();
		}
	}

	// Using EPiServer forms event for formSubmitted to see if the form was successfully submitted
	// https://world.episerver.com/documentation/developer-guides/forms/handling-events-for-forms/
	if (typeof $$epiforms !== 'undefined') {
		$$epiforms(document).ready(function () {
			$$epiforms('.episerver-form').on('formsSubmitted', function () {
				analyticsTracking.emailSendEvent();
			});
		});
	}

	// -- Log print events ------------------------------------------------------------------------

	body.on('click', '.page-action-print button', function () {
		if (window.dataLayer) {
			analyticsTracking.printEvent();
		}
	});

	// -- Log carousel clicks  -------------------------------------------------------------

	body.on('click', '.slide-content a', function (e) {
		if (window.dataLayer) {
			e.preventDefault();
			var link = $(this);
			var label = $(this).closest('li').find('h2').text();

			analyticsTracking.carouselEvent(label);

			// Delay to allow tracking request to finish
			setTimeout(function () {
				document.location = link.attr('href');
			}, 100);
		}
	});

	// -- Log "Sent PDF via mail"  --------------------------------------------------------------------------*/
	
	body.on('click', '[data-ga="sendpdfviamail"]', function () {
		analyticsTracking.materialDatasheetsviewEvent();
	});

	// -- Log Clicks on the Contact tab menu  --------------------------------------------------------------------------*/
	
	body.on('click', '[data-ga="contacttab"]', function () {
		analyticsTracking.contactTabEvent();
	});

	// -- Log file clicks as page views -----------------------------------------------------------

	body.on('click', 'a', function (e) {
		if (window.dataLayer) {
			var link = $(e.target);

			if (!link.is('a')) {
				link = link.parents('a');
			}

			var original_href = link.attr('href') || '';
			var linkTarget = link.attr('target');
			var suffixes = /\.(7z|aac|arc|arj|asf|avi|bin|csv|docx?|exe|flv|gif|gz|gzip|hqx|jar|jpe?g|js|mp(2|3|4|e?g)|mov(ie)?|msi|msp|pdf|png|pptx?|qtm?|ra(m|r)?|tar|tgz|txt|wav|wma|wmv|wpd|xlsx?|xml|z|zip)$/;

			if (!link.isExternal() && original_href.match(suffixes)) {
				e.preventDefault();

				var modified_url = new netr.URI(original_href);

				// Remove everything before path
				modified_url.scheme = '';
				modified_url.domain = '';
				modified_url.port = '';

				// Prepend language to url. This is done so that the pageview will
				// show up in the correct profile inside Google Analytics.
				modified_url.path = '/' + $('html').attr('lang') + modified_url.path;

				analyticsTracking.virtualPageview(modified_url.toString());
				
				// Tiny delay to allow request to be sent
				// Then opening the link in a new tab if target is set to _blank
				// And if not target _blank; set the document location to the file href
				setTimeout(function () {
					if(linkTarget === '_blank') {
						window.open(original_href);
					} else {
						document.location = original_href;
					}
				}, 100);
			}
		}
	});
});