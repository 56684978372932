
// eslint-disable-next-line no-unused-vars
function goToPreviousStep() {
	$('.next-step').val($('.next-step').val() - 2);
}

// eslint-disable-next-line no-unused-vars
function addAutoSubmit() {
	$('[type=submit]').removeAttr('data-noautosubmit');
}

// eslint-disable-next-line no-unused-vars
function removeAutoSubmit() {
	$('[type=submit]').attr('data-noautosubmit', true);
}

// eslint-disable-next-line no-unused-vars
function removeDisabledFromSubmit() {
	$('[type=submit], [data-submit]').removeAttr('disabled');
}

// eslint-disable-next-line no-unused-vars
function addDisabledToSubmit() {
	$('[type=submit], [data-submit]').attr('disabled', 'disabled');
}

// eslint-disable-next-line no-unused-vars
function alterStepCssForIE() {		
	$('#pardot-contact-form .progress ol li').css('align-items', 'stretch');
}

// eslint-disable-next-line no-unused-vars
function alwaysGoToStep1(select) {
	var gotoStep1 = false;

	var chosenTopicHasNoProducts =
		$(select).val() == 'Sourcing' ||
		$(select).val() == 'PressMedia' ||
		$(select).val() == 'InvestorRelations' ||
		$(select).val() == 'PreventPostback' ||
		$(select).val() == '' ||
		$(select).val() == 'Careers';

	if (!chosenTopicHasNoProducts) {
		gotoStep1 = $('#Division').val() != '' && $('#Division').val() != undefined;
	}

	if (gotoStep1) {
		$('.next-step').val(1);
	}
}

// eslint-disable-next-line no-unused-vars
function resetScroll() {
	if ($('#email-form').closest('.dialog')[0]) {
		$(window).scrollTop($('#email-form').closest('.dialog')[0].style.top.split('px')[0]);
	}
}

// eslint-disable-next-line no-unused-vars
function removeDivisionsIfApplicable(select) {
	var chosenTopicHasNoProducts = $(select).val() == 'Sourcing' ||
		$(select).val() == 'PressMedia' ||
		$(select).val() == 'InvestorRelations' ||
		$(select).val() == 'Careers';

	if (chosenTopicHasNoProducts) {
		$('#division-div').remove();
		removeAutoSubmit();
		removeDisabledFromSubmit();
	}
}

// eslint-disable-next-line no-unused-vars
function isFormValid() {
	var valid = true;
	$('#pardot-contact-form input, #pardot-contact-form select').each(function () {
		if (this.checkValidity() == false || this.value == 'PreventPostback') {
			valid = false;
		}
	});

	if (valid) {
		removeDisabledFromSubmit();
	}
	else {
		$('#pardot-contact-form [type=submit], #pardot-contact-form [data-submit]').attr('disabled');
	}
}

// eslint-disable-next-line no-unused-vars
function transferMessageAndOptinDataBetweenForms() {
	$('.backwards-message').val($('.original-message').val());

	if ($('.original-optin')[0].checked) {
		$('.backwards-optin').attr('checked', 'checked');
		$('.backwards-optin')[0].checked = true;
	}
}

function setEventListeners() {
	$('#TopicSelector').change(function () {
		removeDisabledFromSubmit();
		addAutoSubmit();
		removeDivisionsIfApplicable(this);
		alwaysGoToStep1(this);

		if (this.value == '' || this.value == 'PreventPostback') {
			addDisabledToSubmit();
		}
	});
	$('#Division').change(function () {
		removeAutoSubmit();
		removeDisabledFromSubmit();
	});
	$('.form-general:not(.first)').on('input', function () {
		isFormValid();
	});
	$('#Message, #ZipCode').on('input propertychange paste', function () {
		isFormValid();
	});
	$('.second-back-button').click(function () {
		goToPreviousStep();
	});
}

$(function () {
	'use strict';

	setEventListeners();	
});