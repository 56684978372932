/* globals intlTelInput */
/*
var input = document.querySelector('[data-phone-country-selector]');
if (input) {
	intlTelInput(input, {
		// any initialisation options go here        
	});
}
*/
var doNotClosePopup = false;
var globalIntlIndex = 0;
if (doNotClosePopup) {
	doNotClosePopup = false;
}

function initPhone() {
	var inputs = document.querySelectorAll('[data-phone-country-selector]:not([data-intl-tel-input-id])');
	if (inputs) {
		var itiArray = [];
		for (var i = 0; i < inputs.length; i++) {
			var localInput = inputs[i];

			var isMobile = /Android.+Mobile|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
			var options = {
				customContainer: 'u-w-full u-h-full',
				initialCountry: 'auto',// 'se',
				formatOnDisplay: false,
				preferredCountries: ['se', 'gb'],
				customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
					return '+' + selectedCountryData.dialCode + selectedCountryPlaceholder.substring(1);
				},
				//  saved for reference
				//	separateDialCode: true,
				//	hiddenInput: "Phone",
				//	utilsScript: 'js/vendor/intl-tel-input/js/utils.js', //did not work
			};
			$(localInput).attr('data-intl-index', globalIntlIndex );
			if (isMobile) {
				options.dropdownContainer = document.getElementById('countrycode-anchor');
			}
			itiArray[globalIntlIndex] = intlTelInput(localInput, options);
			globalIntlIndex++;
			
			localInput.addEventListener('countrychange', function () {
				if ($(this).val() == '') {
					$(this).val('+' + itiArray[$(this).attr('data-intl-index')].getSelectedCountryData().dialCode);
				}
				if ($(this).val().indexOf('+') == -1) {
					$(this).val('+' + itiArray[$(this).attr('data-intl-index')].getSelectedCountryData().dialCode + $(this).val());
				}
				doNotClosePopup = true;
			});

			$(localInput).change(function () {
				if ($(this).val() == '') {
					$(this).val('+' + itiArray[$(this).attr('data-intl-index')].getSelectedCountryData().dialCode);
				}
			});
		}
	}
}

$(function () {
	initPhone();
});