var LimitMultiplier = function (element) {
	this.element = element;
	this.init();
};

LimitMultiplier.prototype.init = function () {
	var total = parseFloat(this.element.getAttribute('data-total'));
	var multiplier = document.getElementById(this.element.getAttribute('data-multiplier'));
	if (!isNaN(total) && multiplier) {
		this.element.addEventListener('input', function () {
			var max = Math.floor(total / this.value);
			multiplier.setAttribute('max', max);
		});
	}
};
