window.whenMutated = (function () {
	'use strict';

	return function (targetNode, options) {

		var defer = $.Deferred();
		var callback = function (mutationsList, observer) {
			observer.disconnect();
			defer.resolve(mutationsList);
		};

		var observer = new MutationObserver(callback);

		observer.observe(
			targetNode,
			options || { attributes: false, childList: true, subtree: false }
		);

		return defer.promise();
	};
}());