$(function () {
	'use strict';

	$('.like').each(function () {
		var $self = $(this);

		// Replace the link with a button
		var button = $('<button>', {
			'type': 'button',
			'html': $self.html(),
			'class': $self.attr('class') + ' link'
		});

		var text = button.find('.like__text');

		// Copy all data attributes
		$.each($self.prop('attributes'), function () {
			if (this.name.match(/^data-/i)) {
				button.attr(this.name, this.value);
			}
		});

		function getNumberFromText () {
			var num;
			try {
				num = text.text().match(/\d+/)[0]; // Replace non-digits with nothing and return the first match
			}
			catch (e) {}

			if (num) {
				return num;
			}
			else {
				return false;
			}
		}

		button.on('click', function () {
			$.ajax({
				url: (button.is('.like--liked')) ? button.data('unlike-url') : button.data('like-url'),
				data: 'id=' + button.data('like-id'),
				success: function () {
					var num = getNumberFromText();

					button.toggleClass('like--liked');

					if (num) {
						if (button.is('.like--liked')) {
							text.html(netr.string.translate('like.youAndOthersLike').replace('{0}', num));
						}
						else if (parseInt(num, 10) > 1) {
							text.html(netr.string.translate('like.othersLike').replace('{0}', num));
						}
						else if (parseInt(num, 10) === 1) {
							text.html(netr.string.translate('like.oneOtherLike'));
						}
					}
					else if (button.is('.like--liked')) {
						text.html(netr.string.translate('like.youLike'));
					}
					else {
						text.html(netr.string.translate('like.noLikes'));
					}

					// Set/delete localStorage item
					if (localStorage) {
						if (button.is('.like--liked')) {
							localStorage.setItem('like' + button.data('like-id'), true);
						}
						else {
							localStorage.removeItem('like' + button.data('like-id'));
						}
					}
				}
			});
		});

		$self.replaceWith(button);

		// If there's data in localstorage saying the user has liked this
		if (localStorage && localStorage.getItem('like' + button.data('like-id'))) {
			button.addClass('like--liked');

			var num = getNumberFromText();

			if (num && num <= 1) {
				text.html(netr.string.translate('like.youLike'));
			}
			else {
				text.html(netr.string.translate('like.youAndOthersLike').replace('{0}', num - 1));
			}
		}
	});
});
