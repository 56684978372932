(function () {
	'use strict';
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every#Polyfill
	if (!Array.prototype.every) {
		Array.prototype.every = function (callbackfn, thisArg) {
			var T, k;

			if (this === null) {
				throw new TypeError('this is null or not defined');
			}

			// 1. Let O be the result of calling ToObject passing the this
			//    value as the argument.
			var O = Object(this);

			// 2. Let lenValue be the result of calling the Get internal method
			//    of O with the argument "length".
			// 3. Let len be ToUint32(lenValue).
			var len = O.length >>> 0;

			// 4. If IsCallable(callbackfn) is false, throw a TypeError exception.
			if (typeof callbackfn !== 'function') {
				throw new TypeError();
			}

			// 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
			if (arguments.length > 1) {
				T = thisArg;
			}

			// 6. Let k be 0.
			k = 0;

			// 7. Repeat, while k < len
			while (k < len) {

				var kValue;

				// a. Let Pk be ToString(k).
				//   This is implicit for LHS operands of the in operator
				// b. Let kPresent be the result of calling the HasProperty internal
				//    method of O with argument Pk.
				//   This step can be combined with c
				// c. If kPresent is true, then
				if (k in O) {

					// i. Let kValue be the result of calling the Get internal method
					//    of O with argument Pk.
					kValue = O[k];

					// ii. Let testResult be the result of calling the Call internal method
					//     of callbackfn with T as the this value and argument list
					//     containing kValue, k, and O.
					var testResult = callbackfn.call(T, kValue, k, O);

					// iii. If ToBoolean(testResult) is false, return false.
					if (!testResult) {
						return false;
					}
				}
				k++;
			}
			return true;
		};
	}


	// https://tc39.github.io/ecma262/#sec-array.prototype.find
	if (!Array.prototype.find) {
		Object.defineProperty(Array.prototype, 'find', {
			value: function (predicate) {
				// 1. Let O be ? ToObject(this value).
				if (this === null) {
					throw new TypeError('"this" is null or not defined');
				}

				var o = Object(this);

				// 2. Let len be ? ToLength(? Get(O, "length")).
				var len = o.length >>> 0;

				// 3. If IsCallable(predicate) is false, throw a TypeError exception.
				if (typeof predicate !== 'function') {
					throw new TypeError('predicate must be a function');
				}

				// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
				var thisArg = arguments[1];

				// 5. Let k be 0.
				var k = 0;

				// 6. Repeat, while k < len
				while (k < len) {
					// a. Let Pk be ! ToString(k).
					// b. Let kValue be ? Get(O, Pk).
					// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
					// d. If testResult is true, return kValue.
					var kValue = o[k];
					if (predicate.call(thisArg, kValue, k, o)) {
						return kValue;
					}
					// e. Increase k by 1.
					k++;
				}

				// 7. Return undefined.
				return undefined;
			}
		});
	}

	// https://tc39.github.io/ecma262/#sec-array.prototype.includes
	if (!Array.prototype.includes) {
		Object.defineProperty(Array.prototype, 'includes', {
			value: function (searchElement, fromIndex) {

				// 1. Let O be ? ToObject(this value).
				if (this == null) {
					throw new TypeError('"this" is null or not defined');
				}

				var o = Object(this);

				// 2. Let len be ? ToLength(? Get(O, "length")).
				var len = o.length >>> 0;

				// 3. If len is 0, return false.
				if (len === 0) {
					return false;
				}

				// 4. Let n be ? ToInteger(fromIndex).
				//    (If fromIndex is undefined, this step produces the value 0.)
				var n = fromIndex | 0;

				// 5. If n ≥ 0, then
				//  a. Let k be n.
				// 6. Else n < 0,
				//  a. Let k be len + n.
				//  b. If k < 0, let k be 0.
				var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

				function sameValueZero (x, y) {
					return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
				}

				// 7. Repeat, while k < len
				while (k < len) {
					// a. Let elementK be the result of ? Get(O, ! ToString(k)).
					// b. If SameValueZero(searchElement, elementK) is true, return true.
					// c. Increase k by 1.
					if (sameValueZero(o[k], searchElement)) {
						return true;
					}
					k++;
				}

				// 8. Return false
				return false;
			}
		});
	}
}());
