/* globals icon, documentReadyFunctions */
$(function () {
	'use strict';

	var cta = $('[data-sticky-cta]').find('.button');

	// Give each CTA an ID
	cta.each(function (i) {
		$(this).attr('id', 'cta-' + i);
	});

	// Get container which is created in header.js together with the menu-toggle button
	var sticky_container = $('.sticky-container');

	if(!sticky_container){
		sticky_container = $('.sticky-container--no-sticky');
	}
	
	$.breakpoint((function () {
		return {
			condition: function () {
				return window.matchMedia('screen and (max-width:900px)').matches;
			},
			first_enter: function () {
				// Update the container
				sticky_container = $('.sticky-container');
				if(!sticky_container){
					sticky_container = $('.sticky-container--no-sticky');
				}
			}
		};
	}()));

	// Create dropdown-inner
	var dropdownInner = $('<div>', {
		id: 'sticky-dropdown',
		class: 'sticky-container__dropdown-inner',
		'tabindex': '-1'
	});

	// Create dropdown button
	var dropdownButton = $('<button>', {
		class: 'sticky-container__button sticky-container__button--toggler',
		html: '<span class="sticky-container__icon">' + icon('arrow-down', '2', '1', null, 's') + '</span><span class="sticky-container__text">' + netr.string.translate('stickyCta.buttonText') + '</span>',
		'aria-controls': 'sticky-dropdown',
		click: function () {
			// Toggle dropdown
			if (dropdownInner.is(':visible')) {
				closeDropdown();
			}
			else {
				openDropdown();
			}
		}
	});

	// Create dropdown
	var dropdown = $('<span>', {
		class: 'sticky-container__dropdown'
	});

	// Assemble dropdown, dropdown-inner and buttons
	dropdown.prepend(dropdownInner);
	dropdown.prepend(dropdownButton);
	// Close the dropdown from the start
	closeDropdown();
	// Add dropdown if it contains any CTAs
	toggleDropdownButton();

	function toggleDropdownButton () {
		var containsCTA = dropdownInner.find('.sticky-container__button').length > 0;
		if (containsCTA) {
			// Remove click events for all new CTAs
			dropdownInner.find('.sticky-container__button').off('click');
			// Add events
			documentReadyFunctions(dropdown);
			// Add dropdown and display it
			sticky_container.prepend(dropdown);
			dropdown.removeClass('sticky-container__dropdown--hidden');
			// Set max-width to dropdown based on toggle buttons width
			dropdownInner.css('max-width', dropdownButton.outerWidth());
		}
		else {
			// Close dropdown, hide it and detach it
			closeDropdown();
			dropdown.addClass('sticky-container__dropdown--hidden');
			// Detach after css transition ends (200ms)
			setTimeout(function () {
				// Detach if dropdown is still hidden
				if (dropdown.hasClass('sticky-container__dropdown--hidden')) {
					dropdown.detach();
				}
			}, 200);
		}
	}

	function closeDropdown () {
		dropdownInner.hide();
		dropdownButton.find('.sticky-container__icon').html(icon('arrow-down', '2', '1', null, 's'));
		dropdownButton.attr('aria-expanded', 'false');
	}

	function openDropdown () {
		dropdownInner.show();
		dropdownButton.find('.sticky-container__icon').html(icon('arrow-up', '2', '1', null, 's'));
		dropdownInner.focus();
		dropdownButton.attr('aria-expanded', 'true');
	}

	// Close dropdown when anything else is clicked/touched or if Escape key is pressed
	$(document).on('mouseup touchstart keydown', function (e) {
		if (!dropdown.is(e.target) && dropdown.has(e.target).length === 0 || e.key === 'Escape') {
			closeDropdown();
		}
	});

	function addCTA (cta) {
		var ctaID = dropdownInner.find('[data-cta-id="' + cta.attr('id') + '"]');
		if (ctaID.length < 1) {
			// Create newCTA based on CTA
			var ctaIcon = cta.find('.icon').length ? '<span class="sticky-container__icon">' + cta.find('.icon').html() + '</span>' : '';
			var ctaText = cta.find('span:not(.icon)').length ? '<span class="sticky-container__text">' + cta.find('span:not(.icon)').text() + '</span>' : '';
			var newCTA = $('<a>', {
				class: (cta.hasClass('open-in-dialog') ? ' open-in-dialog' : '') + (cta.hasClass('open-call-dialog') ? ' open-call-dialog' : '') + (cta.hasClass('open-email-dialog') ? ' open-email-dialog' : '') + ' sticky-container__button',
				href: cta.attr('href'),
				'data-cta-id': cta.attr('id'),
				html: ctaIcon + ctaText
			});
			// Add new CTA to dropdown
			dropdownInner.append(newCTA);
			// Display dropdown-button if hidden
			toggleDropdownButton();
		}
	}

	function removeCTA (cta) {
		// Find CTA ID and remove it
		var ctaID = dropdownInner.find('[data-cta-id="' + cta.attr('id') + '"]');
		if (ctaID.length > 0) {
			ctaID.remove();
			toggleDropdownButton();
		}
	}

	function checkScroll () {
		var wScrollTop = $(window).scrollTop();
		cta.each(function () {
			var ctaTop = $(this).offset().top;
			// Run function when CTA is scrolled by + 50px
			if (((ctaTop + 50) < wScrollTop)) {
				// Add CTA to dropdown
				addCTA($(this));
			}
			else if ((ctaTop + 50) > wScrollTop) {
				// Remove CTA from dropdown
				removeCTA($(this));
			}
		});
	}

	// Don't run checkScroll() until DOM has scrolled after load
	// otherwise the dropdown won't show due to the throttle script
	var firstLoad = true;
	setTimeout(function () {
		firstLoad = false;
		checkScroll();
	}, 100);

	$(window).on('scroll', netr.throttle(function () {
		if (!firstLoad) {
			checkScroll();
		}
	}, 100));

});
