var WireSelectionGuide = {
	init: function () {
		'use strict';

		var self = this;

		this.results_table = $('.wsg-results-table');
		this.input = $('.wsg-input-form');

		this.setupRadioDisableSwitcher();
		this.changeResultMeasurements();

		$(document)
			.on('submit', 'form', function (e) {
				e.preventDefault();
				self.validateForm($(e.target));
			})
			.on('blur', '.wsg-input-form input[type="text"]', function (e) {
				var input = $(e.target);

				input.val(input.val().replace(/[,]/g, '.'));
				self.validateField(input);
			})
			.on('change', '.wsg-input-form select', function (e) {
				var field = $(e.target).parents('.row').find('[type="text"]');
				if (field.length) {
					self.validateField(field);
				}
			});
	},

	validateField: function (field, type) {
		'use strict';

		var is_required = field.is('[required]');
		var val = field.val();
		var val_as_number = parseFloat(val);
		var container = field.parents('.row');
		var selected_option = (container.find('select').length) ? container.find('option:selected') : container.find('span');
		var active_validation_type = (container.find('select').length) ? container.find('select').val() : container.find('span').text();
		var min = selected_option.data('min');
		var max = selected_option.data('max');

		this.hideValidationError(container);

		if ((is_required) && (!val.length) && (type === 'submit')) {
			this.showValidationError(field, 0);
		}
		else if (val.length) {
			if ((val_as_number < min) || (val_as_number > max)) {
				this.showValidationError(field, 2, active_validation_type, min, max);
			}
		}
	},

	validateForm: function (form) {
		'use strict';

		var self = this;
		var inputs = form.find('input[type="text"]');

		inputs.each(function (e) {
			var field = $(this);

			if (!field.is(':disabled')) {
				self.validateField(field, 'submit');
			}

			// Post form if no errors were found
			if ((e + 1) === inputs.length) {
				if (!self.input.find('.error').length) {
					$(document).off('submit');

					// Not sure why we need this timeout, but need it we do.
					setTimeout(function () {
						$('.wsg-form [type="submit"]').click();
					}, 50);
				}
			}
		});
	},

	showValidationError: function (field, error_type, value1, value2, value3) {
		'use strict';

		var text = '';
		var label = field.parents('.row').find('label:not(.structural)').html();

		if (error_type === 0) {
			text = netr.string.translate('barConverter.requiredMessage').replace('{0}', label);
		}
		else if (error_type === 1) {
			text = 'The <strong>' + label + '</strong> input may only contain numbers';
		}
		else if (error_type === 2) {
			text = label + ' input must be a value between <strong>' + value2 + '</strong> and <strong>' + value3 + ' ' + value1 + '</strong>';
		}

		var msg = $('<div>', {
			'class': 'system-message system-message-small error-message text'
		});
		var msg_inner = $('<div>', {
			'class': 'system-message-inner'
		});
		var paragraph = $('<p>', {
			'html': text
		});

		msg.append(msg_inner.append(paragraph));

		if (!field.parents('fieldset').length) {
			field.parents('.row').addClass('error').prepend(msg);
		}
		else {
			field.parents('.row').addClass('error').parents('fieldset').before(msg);
		}

		// GA logging
		if (window.dataLayer) {
			var category = 'Wire selection guide';
			var action = 'Error message';
			var ga_label = '';

			$('.system-message.error-message').each(function (i) {
				if (i > 0) {
					ga_label += ', ';
				}

				ga_label += $(this).text();
			});

			window.dataLayer.push({
				event: 'wireSelectionGuideEvent',
				eventCategory: category,
				eventAction: action,
				eventLabel: ga_label
			});
		}
	},

	hideValidationError: function (container) {
		'use strict';

		container.find('.error-message').remove();

		if (container.parents('fieldset').prev().is('.error-message')) {
			container.parents('fieldset').prev().remove();
		}

		container.removeClass('error').parents('fieldset').find('.error').removeClass('error');
	},

	changeResultMeasurements: function () {
		'use strict';

		$(document).on('change', this.results_table.find('th select'), function (e) {
			var select = $(e.target);
			var measurement_type = select.val().toLowerCase();
			var affected_cells = $('td.' + select.parents('th').attr('class'));

			affected_cells.each(function () {
				var cell = $(this);

				cell.find('span').removeClass('hidden').hide().removeClass('flash');
				cell.find('.' + measurement_type).show().addClass('flash');

			});
		});
	},

	setupRadioDisableSwitcher: function () {
		'use strict';

		var self = this;
		var fieldset = this.input.find('fieldset');
		var onload = true;

		$(document).on('change', '.wsg-input-form fieldset :radio', function (e) {
			var container = $(e.target).parents('.row');

			fieldset.find('input[type="text"], select').prop('disabled', true);
			container.find('input[type="text"], select').prop('disabled', false);

			if (!onload) {
				fieldset.find('input[type="text"]').val('');
			}

			onload = false;

			self.hideValidationError(container);
		});

		// Trigger on load
		$('.wsg-input-form fieldset input[type="text"]').last().each(function () {
			if ($(this).val() !== '') {
				$(this).parent().find(':radio').click();
			}
			else {
				$('.wsg-input-form fieldset :radio:first').trigger('change');
			}
		});
	}
};

$(function () {
	'use strict';

	if ($('.wsg-form').length) {
		WireSelectionGuide.init();
	}
});
