(function ($) {

	'use strict';

	var PopoverTrigger = function (element) {

		// Var to keep track of which element was the last to be open
		var currentElement = [];

		// Örjan prevents focus from leaving the document altogether,
		// which would cause problems when running button.focus()
		var örjan = $('<a>', {
			'href': '#',
			'aria-hidden': 'true',
			'focus': function (e) {
				// Bad keyboard behavior for browsers that does not understand relatedTarget
				if (e.relatedTarget === null) {
					e.preventDefault();
					closePopover(currentElement.popover, currentElement.button);
				}
			}
		});

		element.each(function () {
			var trigger = $(this);
			var popover = $('#' + trigger.data('popover-trigger'));
			var open_popover = true;
			var button = $('<button>', {
				'class': 'link',
				'type': 'button',
				'html': '<span>' + trigger.html() + '</span>',
				'click': function (e) {
					e.preventDefault();
					e.stopPropagation();

					// Close other popovers
					$('.popover[aria-expanded="true"]').each(function () {
						var visible_popover = $(this);
						closePopover(visible_popover, $('[data-popover-trigger="' + visible_popover.attr('id') + '"]'));
					});

					currentElement.popover = popover;
					currentElement.button = button;

					if (open_popover) {
						open_popover = false;
						popover.detachWithPlaceholder();
						$('body').append(popover, örjan);
						button.addClass('active');

						var top = trigger.offset().top + trigger.height() + 5;

						if (popover.is('.popover-above')) {
							top = trigger.offset().top - popover.outerHeight(true) - 5;
						}
						
						popover
							.attr('aria-expanded', 'true')
							.css('top', top)
							.css('zIndex', '100')
							.css('left', trigger.offset().left + (trigger.outerWidth() / 2))
							.attr('tabindex', '-1') 
							// .focus() SMRT-258: Removed because it should not focus. 
							.on('focusout', function (e) {
								// Good keyboard behavior for browsers that understand relatedTarget
								if (e.relatedTarget !== null) {
									if (($(e.relatedTarget).length === 0) || (!$(e.relatedTarget).parents('.popover').length)) {
										e.preventDefault();

										// Close if not IE and dialog link
										if (!$('#body').is('.lt-ie9') && (!$(e.relatedTarget).is('.open-in-dialog'))) {
											closePopover(popover, button);
										}

										setTimeout(function () {
											open_popover = true;
										}, 100);
									}
								}
								else {
									setTimeout(function () {
										open_popover = true;
									}, 100);
								}
							});

						$(document).on('touchstart.popover click.popover', function (e) {
							if ((!$(e.target).is('.popover')) && ($(e.target).parents('.popover').length === 0)) {
								closePopover(popover, button);

								setTimeout(function () {
									open_popover = true;
								}, 100);
							}
						});

						$(document).on('keyup.popover', function (e) {
							if (e.keyCode === 27) {
								closePopover(popover, button);

								setTimeout(function () {
									open_popover = true;
								}, 100);
							}
						});

						var align_nudge = 28;

						if (popover.offset().left < 0) {
							popover
								.addClass('popover-left-align')
								.css('left', trigger.offset().left + (trigger.outerWidth() / 2) - align_nudge);
						}
						else if ((popover.offset().left + popover.outerWidth()) > $(window).outerWidth()) {
							popover
								.addClass('popover-right-align')
								.css('left', 'auto')
								.css('right', $(window).outerWidth() - (trigger.offset().left + (trigger.outerWidth() / 2) + align_nudge));
						}

						// If very narrow window/device, use full screen
						if (window.matchMedia('only screen and (max-width:400px)').matches) {
							popover.addClass('popover-full-width');
						}
					}
				}
			});

			trigger.html(button);
			popover.attr('aria-expanded', 'false');

			// Open on load
			if (popover.is('[data-init-expanded]')) {
				setTimeout(function () {
					button.click();
				}, 1000);
			}
		});

		function closePopover (popover, button) {
			popover
				.attr('aria-expanded', 'false')
				.off('focusout')
				.attachToPlaceholder()
				.removeClass('popover-left-align')
				.removeClass('popover-right-align')
				.removeClass('popover-full-width');

			$(document)
				.off('click.popover')
				.off('touchstart.popover')
				.off('keyup.popover');

			button.focus();
			örjan.detach();
			button.removeClass('active');
		}
	};

	$.fn.popoverTrigger = function () {
		return this.each(function () {
			new PopoverTrigger($(this));
		});
	};
} (jQuery));
