var SetInputConstraints = function (element) {
	this.constrainer = element;
	this.constrainee = document.getElementById(this.constrainer.getAttribute('data-constrains'));
	this.init();
};

SetInputConstraints.prototype.init = function () {
	if (this.constrainee) {
		var self = this;
		this.constrainer.addEventListener('change', function () {
			self.constrainee.setAttribute('min', this.options[this.selectedIndex].getAttribute('data-min'));
			self.constrainee.setAttribute('max', this.options[this.selectedIndex].getAttribute('data-max'));
			self.constrainee.setAttribute('step', this.options[this.selectedIndex].getAttribute('data-step'));
		});
	}
};
