(function ($) {
	'use strict';

	// Remove border from header if hero is hero-layout-2…
	// Coulnd't find a better way to do this unfortunately.
	if ($('.header + div > .hero-layout-2').length) {
		$('.header').addClass('header-borderless');
	}

	if ($('.hero-layout-5').length) {

		var imageContainer = $('.hero-layout-5').first().find('.hero-image');
		var imageOrg = imageContainer.html();

		$(document).on('click', '.hero-play-button', function (e) {
			e.preventDefault();
			var url = $(this).attr('href');

			var video = $('<iframe>', {
				'src': url,
				'allowfullscreen': '',
				'allow': 'autoplay'
			});

			imageContainer.children().fadeOut(300, function () {
				imageContainer.html(video);
			});

			// Pause teaser video
			$(document).trigger('pauseTeaserVideos');

			// Custom event to stop or pause the video when another is being played in teasers
			$(document).on('stopHeroVideos', function() {
				imageContainer.html(imageOrg);
			});

		});

	}

}(jQuery));
