// Only used by SMT
$(function () {
	'use strict';
    
	var radioButtonsStandard = $('input[type=radio][data-standard-unit]');
	var radioButtonsTemperature = $('input[type=radio][data-temperature-unit]');
	var selectContainerStandard = $('.select[data-standard-unit]');
	var selectContainerTemperature = $('.select[data-temperature-unit]');
	
	$(radioButtonsStandard).each(showSelectedSelectStandard);
	$(radioButtonsTemperature).each(showSelectedSelectTemperature);
	$(radioButtonsStandard).change(showSelectedSelectStandard);
	$(radioButtonsTemperature).change(showSelectedSelectTemperature);
	
	function showSelectedSelectStandard() {
		var radioValue = $(this).val();
		var radioChecked = $(this).prop('checked');

		if(!radioChecked) { return; }

		$(selectContainerStandard).each(function() {
			var selectDataStandard = $(this).attr('data-standard-unit');
			
			if( selectDataStandard === radioValue) {
				$(this).show();
				$(this).find('select').prop('disabled', false);
			}
			else {
				$(this).hide();
				$(this).find('select').prop('disabled', true);
			}
		});
	}
    
	function showSelectedSelectTemperature() {
		var radioValue = $(this).val();
		var radioChecked = $(this).prop('checked');

		if(!radioChecked) { return; }

		$(selectContainerTemperature).each(function() {
			var selectDataTemperatureUnit = $(this).attr('data-temperature-unit');
			
			if( selectDataTemperatureUnit === radioValue) {
				$(this).show();
				$(this).find('select').prop('disabled', false);
			}
			else {
				$(this).hide();
				$(this).find('select').prop('disabled', true);
			}
		});
	}
});