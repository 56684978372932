/* globals Modernizr, svg4everybody, MultiThumbSlider, SetInputConstraints, EnableInputConstraints, LimitMultiplier */

// Tell window that smt analytics are not in use (will be overwritten in analytics-smt.js)
window.smtAnalytics = false;
// STARTUP SCRIPTS
(function () {
	'use strict';

	document.createElement('header-bar');

	// Initialize on document ready.
	$(function () {
		documentReadyFunctions($('html'));
	});

	// Set width for image captions based on image width.
	$(window).load(function () {
		$('span.caption').each(function () {
			var caption = $(this);
			var img = caption.children('img');
			caption.not('.fullwidth, .fullwidth-dec').has('img').css('max-width', img.outerWidth());
		});
	});

	// Show transfer message when being redirected to other Sandvik site
	var redirects = [
		{
			name: 'Sandvik Coromant',
			test: function (link) {
				return /^(www\.)?sandvik\.coromant\.com$/.test(link.domain);
			}
		},
		{
			name: 'Sandvik Coromant',
			test: function (link) {
				return /^(www\.)?coromant\.sandvik\.com$/.test(link.domain);
			}
		},
		{
			name: 'Sandvik Materials Technology',
			test: function (link) {
				return /^(www\.)?materials\.sandvik$/.test(link.domain);
			}
		},
		{
			name: 'Kanthal',
			test: function (link) {
				return /^(www\.)?kanthal\.com$/.test(link.domain);
			}
		},
		{
			name: 'Metal Powder',
			test: function (link) {
				return /^(www\.)?metalpowder\.sandvik$/.test(link.domain);
			}
		},
		{
			name: 'CE Alloys',
			test: function (link) {
				return /^(www\.)?cealloys\.sandvik$/.test(link.domain);
			}
		},
		{
			name: 'Sandvik Manufacturing Technology',
			test: function (link) {
				return /^(www\.)?manufacturingtech\.sandvik$/.test(link.domain);
			}
		},
		{
			name: 'Sandvik Mining & Rock Technology',
			test: function (link) {
				return /^(www\.)?rocktechnology\.sandvik$/.test(link.domain);
			}
		},
		{
			name: 'Sandvik Group',
			test: function (link) {
				return /^(www\.)?home\.sandvik$/.test(link.domain);
			}
		},
		{
			name: 'Sandvik Additive',
			test: function (link) {
				return /^(www\.)?additive\.sandvik$/.test(link.domain);
			}
		},
		{
			name: 'Sandvik Rock Processing Solutions',
			test: function (link) {
				return /^(www\.)?rockprocessing\.sandvik$/.test(link.domain);
			}
		}
	];
	// Show popup when user clicks a link to another Sandvik domain
	$(document).on('click', function (e) {
		var link = $(e.target).closest('a');
		var url = new netr.URI(link.attr('href'));
		var location = new netr.URI(window.location.href);
		var transferDisabled = link.data('transfer-dialog-disabled');
		if (url.scheme.slice(0, 6) !== 'mailto' && !transferDisabled) {
			var redirect = netr.array.first(redirects, function (obj) {
				// We don't whant to show the redirect dialog if the user is already on the same domain.
				return obj.test(url) && !obj.test(location);
			});

			if (redirect) {

				// Log event in GA
				// TODO: Move to analytics.js
				if (window.dataLayer) {
					e.preventDefault();
					var category = 'Transfering to other Sandvik Site';
					var action = 'Transfering to ' + redirect.name;
					var label = url.toString();

					window.dataLayer.push({
						event: 'transferEvent',
						eventCategory: category,
						eventAction: action,
						eventLabel: label
					});

					// Delay to allow tracking request to finish
					setTimeout(function () {
						// Create transfer dialog
						var dialog = $.netrdialog();
						dialog.openTransferDialog(url, redirect.name);
					}, 100);
				}
				else {
					// Create transfer dialog, this time again as a fallback if google-tracking is blocked
					var dialog = $.netrdialog();
					dialog.openTransferDialog(url, redirect.name);
				}
			}
		}
	});

	// External SVG polyfill
	svg4everybody();
}());

function documentReadyFunctions(context) {
	'use strict';

	// Activate obfuscated email links.
	$('span.hidden-email', context).activateEmailLinks({
		salt: 'NO_HAM'
	});

	// Add rel attribute to external links.
	$('a:external', context).attr('rel', 'external');

	// Expandable sections
	$('.exp-section:not(.is-initiated)', context).collapsableSection({
		headingSelector: 'h2:first',
		contentSelector: '.exp-section-content',
		contentIdBase: 'exp-section-',
		collapsedClass: 'collapsed',
		expandedClass: 'expanded'
	});

	// Bar charts
	var myBarChart = $('.bar-chart', context);
	if (myBarChart.barChart) {
		myBarChart.barChart();
	}

	// Custom selects
	if(typeof $('select:not([multiple])', context).netrCustomSelect === 'function'){
		$('select:not([multiple])', context).netrCustomSelect();
	}
	// Open email us dialog
	$('.open-email-dialog, a[href*="/email-us-form/"]', context).each(function () {
		$(this).on('open.netrdialog', function (e, dialog) {
			$(this).trackEmailOpenEvent(dialog);
		});
	});
	// Load email us dialog (when submitted)
	$('.open-email-dialog, a[href*="/email-us-form/"]', context).each(function () {
		$(this).on('load.netrdialog', function () {
			$(this).trackEmailSendEvent();
		});
	});

	// Load and open for call us dialog
	$('.open-call-dialog, a[href*="/call-us-form/"]', context).each(function () {
		$(this).on('load.netrdialog open.netrdialog', function (e, dialog) {
			$(this).trackCallUsOpenEvent(dialog);
		});
	});

	// File upload, activate drag and drop
	$('.file-upload').each(function () {
		$(this).fileDragAndDrop();
	});

	// Tabs
	$('.tabbed-module', context).each(function () {
		var $this = $(this);
		$this.netrtabbedmodule({
			moduleSelector: '.tab-content',
			addToHistory: $this.parents('.dialog').length === 0,
			headingSelector: '.tab-heading',
			contentSelector: '.tab-content',
			activeSelector: '.tab-active',
			selectedClass: 'tab-selected'
		});
	});

	// Instagram
	if (typeof $.fn.instaList === 'function' && typeof $.fn.instaPost === 'function') {
		$('.insta-list', context).instaList();
		$('.insta-post', context).instaPost();
	}

	// Maps
	$('.map', context).each(function () {
		var map = $(this);

		$.netrGoogleMap.loadAPI().then(function () {
			map.netrGoogleMap({
				vcards: map.next().find('.vcard')
			});
		});
	});

	// Datepickers
	$('.has-datepicker', context).each(function () {
		var datepicker = $(this);
		datepicker.addClass('has-datepicker--enabled').attr('type', 'text');
		datepicker.datepicker(netr.string.translate('datepicker'));
	});

	// Tooltips
	if (!Modernizr.touch) {
		$('.title-tooltip', context).tooltip({
			position: { my: 'bottom-15', at: 'top' },
			show: 200,
			hide: 200
		});
	}

	// Rating tool
	$('.rating', context).each(function () {
		var rating = $(this);

		if (!rating.find('.rating-tool').length) {
			rating.find('input').netrRatingTool();
		}
	});

	// YouTube videos
	// We check first if we have access to the api.
	$.ajax('https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest')
		.done(function () {
			$('a[href*="//www.youtube.com"]', context).youtubethumb();
		})
		.fail(function () {
			$('html').addClass('youtube-is-blocked');
		});

	// Table functions
	$('table', context).tablefunctions();

	// Element togglers
	$('[data-toggles]', context).elementToggler(context);

	// Transfer dialog links
	$('a[data-transfer-dialog]').click(function (event) {
		event.preventDefault();
		event.stopPropagation();

		var link = $(this);
		var url = new netr.URI(link.attr('href'));
		var siteName = link.data('transfer-dialog');

		// Create transfer dialog
		var dialog = $.netrdialog();
		dialog.openTransferDialog(url, siteName);
	});

	// Transfer dialog for links to global Sandvik Group site from Brazilian Sandvik Group site
	if ($('html').attr('lang').toLowerCase() === 'pt-br') {
		var url = new netr.URI(window.location.href);

		if ((url.domain.slice(0, 11) === 'www.sandvik') || (url.domain.slice(0, 7) === 'sandvik')) {
			$('a').each(function () {
				var link = $(this);
				var url = new netr.URI(link.attr('href'));

				if (typeof url.path !== 'undefined' && (url.path.toLowerCase().indexOf('sandvik.com/en/') > -1 || url.path.substr(0, 4).toLowerCase() === '/en/')) {
					link.click(function (event) {
						event.preventDefault();
						event.stopPropagation();

						// Create transfer dialog
						var dialog = $.netrdialog();
						dialog.openTransferDialog(url, 'global Sandvik');
					});
				}
			});
		}
	}

	$(window).on('load resize orientationchange open.netrdialog', function () {
		// Check if any tables are wider than their parent.
		// If they are, wrap them in containers to allow for horizontal scrolling.
		$('table', context).each(function () {
			var $this = $(this);
			if ($this.is(':visible')) {
				$this.tablescroll();
			}
		});
	});

	var form = $('.application-search form');
	form.instantsearch({
		// Selector for input element to attach autocomplete to
		inputSelector: 'input[type=search]:first',
		// Url to instant search results
		searchResultsUrl: form.attr('data-instant-search-url'),
		// Content of close button
		// closeButtonHTML: '<span>' + netr.string.translate('applicationSearch.closeInstantSearch') + '</span>',
		hijackSubmit: true
	});

	// Popover triggers
	$('[data-popover-trigger]', context).popoverTrigger();

	// Object-fit polyfill
	$('.object-fit-polyfill, .hero-layout-3, .hero-layout-5 .hero-image, .media-grid-block__media--full-size').objectFit();
	
	// Object-fit polyfill only for hero with text block, and only adding the class when viewport is 700px or more.
	$.breakpoint((function () {
		return {
			condition: function () {
				return window.matchMedia('only screen and (min-width:700px)').matches;
			},

			first_enter: function() {
				$('.hero-layout-text-block').objectFit();
			},

			enter: function () {
				$('.hero-layout-text-block').objectFit();
			},

			exit: function () {
				$('.hero-layout-text-block').objectUnfit();
			}
		};
	}()));
	
	// Multi thumb sliders
	var multiThumbSliders = $(context).get(0).querySelectorAll('.multi-thumb-slider');
	for (var i = 0; i < multiThumbSliders.length; i++) {
		var s = new MultiThumbSlider(multiThumbSliders[i], {
			valueTextSuffix: ' mm'
		});
		s.init();
	}

	// Enable and set input constraints
	$('.enable-input-constraints', context).each(function () {
		new EnableInputConstraints(this);
	});
	$('.set-input-constraints', context).each(function () {
		new SetInputConstraints(this);
	});
	$('input[type="number"][data-total][data-multiplier]', context).each(function () {
		new LimitMultiplier(this);
	});

	// Compare input field values
	$('[data-compare-to][data-custom-validation-message]').compareFields();

	// Display a loading message when some forms are submitted and some links are clicked
	$('.display-loader-on-navigation').displayLoaderOnNavigation();

	// Display a confirm message when submitting forms via buttons that have a `data-confirm` attribute
	$('[data-confirm]').on('click', function () {
		return window.confirm(this.getAttribute('data-confirm'));
	});

	// Enable radio buttons to toggle form fields - disabled / enabled.
	$('[data-toggle-disabled]', context).each(function () {
		var toggler = $(this).find('input[type="radio"]');
		toggler.toggleDisabledFields();
		toggler.on('change', function () {
			toggler.toggleDisabledFields();
		});
	});

	// Open maps in a dialog
	$('.expandable-map, .expandable-map-text', context).each(function (){
		$(this).expandableMap();
	});

	// Validate select data
	$('[data-form-validation]').on('submit', function() {
		var dataInput = $(this).find('[data-input]');
		var dataSelect = $(this).find('[data-select]');
		if(dataSelect.find('option[value="' + dataInput.val() + '"]').length > 0) {
			if(confirm($(this).attr('data-form-validation'))) {
				return true;
			}
			else {
				return false;
			}
		}
	});

	$(context).trigger('newcontent');
}

$(document).on('newcontent', function (event, params) {
	var context = (params && params.target) ? params.target : event.target;
	// Custom selects
	if(typeof $('select:not([multiple])', context).netrCustomSelect === 'function'){
		$('select:not([multiple])', context).netrCustomSelect();
	}
	
	// Dialogs
	$('.open-in-dialog', context).each(function () {
		var button = $(this);
		var hijackAttribute = button.data('hijack');
		var hijack = hijackAttribute === undefined ? true : hijackAttribute;
		var hasVideo = button.data('dialog-extra-class') === 'dialog-video';

		button.netrdialog({
			dialogTop: button.hasClass('dialog--top'),
			dialogFullInIpad: button.hasClass('dialog--full-in-ipad'),
			closeText: netr.string.translate('dialog.close'),
			errorMessage: netr.string.translate('dialog.errorMessage'),
			hijackForms: hijack,
			onlyDesktop: button.data('dialog-only-desktop') === true,
			extraClass: button.data('dialog-extra-class') ? button.data('dialog-extra-class') : false,
			open: !!button.data('dialog-open'),
			loadAsync: hasVideo ? true : false,
			callback: function (dialog) {
				documentReadyFunctions(dialog);
				var fileUpload = $(dialog).find('.file-upload');
				if (fileUpload.length > 0) {
					fileUpload.fileDragAndDrop();
				}

				if (hasVideo) {
					var dialogInstance = button.netrdialog('getdialog');
					setTimeout(function () {
						dialogInstance.dialogElement.css(
							'top',
							Math.max($(document).scrollTop() + 20,
								$(document).scrollTop() + ($(window).height() / 2) - (dialogInstance.dialogElement.height() / 2)
							));
					}, 100);
				}
			}
		});
	});

	// Special demand to show a warning dialog if "Random length" radio-button is selected on product-page
	if($('.warning-form-dialog').length) {
		$('.warning-form-dialog', context).each(function () {
			$(this).netrdialog({
				closeText: netr.string.translate('dialog.close'),
				errorMessage: netr.string.translate('dialog.errorMessage'),
				warningSubmitDialog: true,
				extraClass: 'dialog--minimal'
			});
		});
	}
	
});
