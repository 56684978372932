/* eslint-disable */
$(function () {
	'use strict';

  document.onreadystatechange = function () {
    if (document.readyState === 'complete') {
      var button = $('.c-collapsable__trigger');
      var container = $('.c-collapsable-area');
      var containerId = 'container-area';

      button.on('click', function () {
        button.trigger('collapsable:action', [{ id: containerId, action: 'toggle' }]);
      });

      $(document).on('collapsable:state-change', function (event, data) {
        if (!data || !data.id) {
          return;
        }

        if (data.id === containerId) {
          if (data.expanded) {
            button.addClass('is-active');
            button.html(button.attr('data-text-hide'));
          }
          else {
            button.removeClass('is-active');
            button.html(button.attr('data-text-show'));
          }
          button.attr('aria-expanded', data.expanded);
        }
      });

      new Collapsable({ element: container, id: containerId });
    }
  }
  
});