/* globals documentReadyFunctions */

$(function () {
	'use strict';

	var onPageLoad = true;
	var form;
	var submit_button;

	function initFiltersForm () {
		form = $('.datasheet-search');
		form.find('[type="submit"]').addClass('structural');
		submit_button = $('.datasheet-search-submit');

		form.find('select').on('change.datasheets', function () {
			submit_button.click();
		});

		addPagingHash();
	}

	initFiltersForm();

	// Listen to search field keypresses
	var counter;
	$(document).on('keyup.datasheets', '.datasheet-search input[type="search"]', function () {
		// Clear counter for previous keypress
		clearInterval(counter);

		// Make sure we wait some time before making the request
		var elapsed_time = 0;
		counter = setInterval(function () {
			elapsed_time += 50;
		}, 50);

		var wait = setInterval(function () {
			var iterations = 0;
			if (elapsed_time >= 400) {
				clearInterval(wait);
				clearInterval(counter);

				// Trigger submit
				submit_button.click();
			}
			else if (iterations > 10) {
				clearInterval(wait);
			}
			iterations++;
		}, 50);
	});

	$(document).on('submit.datasheets', '.datasheet-search', function (e) {
		e.preventDefault();

		getNewSearchresult(form.serialize(), true);
	});

	$(window).on('popstate', function () {
		// Some browsers fire the popstate event on page load,
		// avoid doing anything in these cases.
		if (!onPageLoad) {
			var url = new netr.URI(document.location.href);

			if (typeof url.query !== 'undefined') {
				getNewSearchresult($.param(url.query), false);
			}
			else {
				getNewSearchresult(null, false);
			}
		}
	});

	function addPagingHash () {
		var paging = $('.paging');
		var tabContent = paging.closest('.tab-content');
		var id = tabContent.attr('id');

		if (!tabContent.length) {
			return;
		}

		paging.find('a').each(function () {
			this.href = new netr.URI(this.href).set('#' + id);
		});
	}

	function getNewSearchresult (data, updateState) {
		var listing = $('.datasheets-listing');
		listing.addClass('datasheets-listing-loading');

		// Make sure reloading takes at least half a second
		var elapsed_time = 0;
		var counter = setInterval(function () {
			elapsed_time += 50;
		}, 50);

		$.ajax({
			url: form.attr('action'),
			type: (form.attr('method') || 'post'),
			data: data,
			async: false,
			success: function (data) {
				if (data.length) {
					var wait = setInterval(function () {
						if (elapsed_time >= 500) {
							clearInterval(wait);
							clearInterval(counter);

							// Replace DOM parts
							var new_page = $(data);
							var focused_el = $(':focus').attr('id');

							$('.datasheet-search .row:nth-child(1)').replaceWith(new_page.find('.datasheet-search .row:nth-child(1)'));
							$('.datasheets-listing').replaceWith(new_page.find('.datasheets-listing'));
							$('.paging').replaceWith(new_page.find('.paging'));

							addPagingHash();

							// Temporarily remove change listener on selects
							form.find('select').off('change.datasheets');

							// Init scrips
							documentReadyFunctions($('.datasheet-search'));
							initFiltersForm();

							// Set new state if this request wasn't initiated on popstate
							if (updateState) {
								if (history.pushState) {
									onPageLoad = false;
									history.pushState(null, '', '?' + form.serialize());
								}
							}

							// Push event to GTM
							var gtm_url = new netr.URI(document.location.href);

							// Remove everything before path
							gtm_url.scheme = '';
							gtm_url.domain = '';
							gtm_url.port = '';

							if (window.dataLayer) {
								window.dataLayer.push({
									event: 'virtualPageview',
									eventPage: gtm_url.toString()
								});
							}

							// Put focus back on correct element
							if (focused_el !== '') {
								var selector = $('#' + focused_el);
								if (selector.attr('type') !== 'search') {
									selector.focus();
								}
							}
						}
					}, 50);
				}
			},
			error: function () {
				var error_message = $('<div>', {
					'class': 'system-message error-message text',
					'html': '<p>' + netr.string.translate('materialDatasheets.searchError') + '</p>'
				});

				$('.paging').hide();
				$('.datasheets-listing').replaceWith(error_message);
			}
		});
	}
});

// eslint-disable-next-line no-unused-vars
function resetTechnicalSpecificationForm() {
	$('#query').val('');
	$('#productnames').val('');
	$('#productgroups').val('');
	$('#submit-button').click();
}