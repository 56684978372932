/* eslint-disable new-cap */
window.loadYoutubeAPI = (function () {
	'use strict';
	
	var deferred = $.Deferred();
	var script_tag_added = false;

	window.onYouTubeIframeAPIReady = function () {
		deferred.resolve();
	};

	function init() {
		if (script_tag_added === false) {
			if (window.YT) {
				deferred.resolve();
			}
			else {
				createScript();
			}
		}

		return deferred.promise();
	}

	function createScript () {
		var script_tag = document.createElement('script');
		var first_script_tag = document.getElementsByTagName('script')[0];

		script_tag.src = 'https://www.youtube.com/iframe_api';
		first_script_tag.parentNode.insertBefore(script_tag, first_script_tag);

		script_tag_added = true;
	}

	return init;
}());
