(function ($) {
	'use strict';

	$.fn.collapsableSection = function (settings) {
		var config = {
			headingSelector: '.collapsable-section-heading',
			contentSelector: '.collapsable-section-content',
			contentIdBase: 'collapsable-section-',
			collapsedClass: 'collapsed',
			expandedClass: 'expanded'
		};

		if (settings) {
			$.extend(config, settings);
		}

		this.each(function (index) {
			var section = $(this);
			var header = section.find(config.headingSelector);
			var content = section.find(config.contentSelector);
			var button;

			if (header.length && content.length) {
				// Switch class names and toggle the button's aria-expanded attribute
				var toggleContent = function () {
					if (section.hasClass(config.collapsedClass)) {
						section.removeClass(config.collapsedClass).addClass(config.expandedClass);
						button.attr({
							'aria-expanded': 'true'
						});
						$('table', content).tablescroll();
						content.attr('aria-hidden', 'false');
					}
					else {
						section.removeClass(config.expandedClass).addClass(config.collapsedClass);
						button.attr({
							'aria-expanded': 'false'
						});
						content.attr('aria-hidden', 'true');
					}
				};
				// Make sure the content element has an id for the button to reference
				if (!content.attr('id')) {
					content.attr('id', config.contentIdBase + index);
				}
				// Set aria-hidden true/false
				if (section.hasClass(config.collapsedClass)) {
					content.attr('aria-hidden', 'true');
				}
				else {
					content.attr('aria-hidden', 'false');
				}

				// Wrap the header's content in a button that toggles the content
				button = $('<button>', {
					'type': 'button',
					'aria-expanded': function () {
						return section.hasClass(config.collapsedClass) ? 'false' : 'true';
					},
					'aria-controls': content.attr('id'),
					'html': header.html(),
					'click': toggleContent,
					'keydown': function (e) {
						// Also toggle the content when space is pressed
						if (e.keyCode === 32) {
							e.preventDefault();
							toggleContent();
						}
					}
				});
				header.html(button);
				section.addClass('is-initiated');
			}
		});
	};

	// Listen to clicks that should open an exp section
	$('html').on('click', 'a', function (e) {
		if ($(this).parents('.exp-section').length) {
			var link = $(this);
			var other_exp_section;

			if ((link.attr('href').charAt(0) === '#')) {
				e.preventDefault();
				other_exp_section = $(link.attr('href')).parents('.exp-section');
				link.parents('.exp-section').find('h2 button').click();
				setTimeout(function () {
					if (!other_exp_section.is('.expanded')) {
						other_exp_section.find('h2 button').click();
					}
				}, 350);
			}
		}
	});
}(jQuery));