/* exported icon */
function icon (id, color, hocusColor, margin, size) {
	'use strict';

	var iconString = '';
	var guiBase = '/';

	iconString += '<svg class="i  i--' + id;
	iconString += (color && typeof color !== 'undefined') ? '  i--color-' + color : '';
	iconString += (hocusColor && typeof hocusColor !== 'undefined') ? '  i--hocus-color-' + hocusColor : '';
	iconString += (margin && typeof margin !== 'undefined') ? '  i--margin-' + margin : '';
	iconString += (typeof size !== 'undefined') ? '  i--size-' + size : '  i--size-m';

	// First check if there is a data-ssg-gui-base attribute
	if ($('html').attr('data-ssg-gui-base')) {
		guiBase = $('html').attr('data-ssg-gui-base');
	}
	// If there isn't one, look for a data-gui-base attribute for back compat with ssg-1.7
	else if ($('html').attr('data-gui-base')) {
		guiBase = $('html').attr('data-gui-base');
	}
	else {
		guiBase = '/project';
	}

	iconString += '">';
	iconString += '<use xlink:href="' + guiBase + '/i/icons.svg#' + id + '" />';
	iconString += '</svg>';

	return iconString;
}
