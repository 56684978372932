$(function () {
	'use strict';

	// Print button.
	if (window.print) {
		var li = $('<li>', {
			'class': 'page-action-print'
		});
		var button = $('<button>', {
			'class': 'link',
			'type': 'button',
			'html': netr.string.translate('article.print_this_page'),
			'click': function () {
				window.print();
			}
		});

		li.append(button);
		$('.page-actions-share ul').append(li);
	}

	// Get "Send feedback" form via AJAX
	(function () {
		var wrapper = $('.send-feedback');
		var feedback_link = wrapper.find('.send-feedback-link');
		var feedback_form = $('.send-feedback-form');
		var form_loaded = ($('.send-feedback-form').length) ? true : false;

		feedback_link.on('click', function (e) {
			e.preventDefault();

			var url = new netr.URI(feedback_link.attr('href'));
			var fragment = url.fragment;

			if (!form_loaded && fragment) {
				$.ajax({
					url: netr.string.stripHash(feedback_link.attr('href')),
					method: 'get',
					success: function (data) {
						var form = ($(data).filter('#' + fragment).length > 0) ? $(data).filter('#' + fragment) : $(data).find('#' + fragment);

						wrapper.append(form);
						feedback_form = $('.send-feedback-form');
						feedback_form.hide();

						if (!$('.rating .rating-tool').length) {
							feedback_form.find('.rating input').netrRatingTool();
						}

						slideDown();
						form_loaded = true;
					}
				});
			}
			else if (wrapper.is('.expanded')) {
				slideUp();
			}
			else {
				slideDown();
			}
		});

		function slideUp () {
			feedback_form.slideUp(162);
			wrapper.removeClass('expanded');
		}

		function slideDown () {
			feedback_form.slideDown(162);
			wrapper.addClass('expanded');
			feedback_form.find('textarea').focus();
		}

		if (form_loaded) {
			feedback_link.click();
		}
	}());
});