/* global getFragments */
$(function () {
	'use strict';

	if (!window.history || !window.history.pushState) {
		return false;
	}

	/**
	 * Hijack forms and links and load by ajax.
	 *
	 * Load content by ajax and show the response content
	 * in an element on the page by referencing the id of the target
	 * element in the data-update-targets attribute on a form or link.
	 */
	$('body')
		.on('click', 'a[data-update-targets]', handleClickAndSubmit)
		.on('click', 'form[data-update-targets] [type="submit"]', handleClickAndSubmit)
		.on('submit', 'form[data-update-targets]', handleClickAndSubmit)
		.on('click', '[type="submit"][data-update-targets]', handleClickAndSubmit);

	/**
	 * Handler for both link clicks and form submits
	 * @param {Event} e Event
	 */
	function handleClickAndSubmit (e) {
		var element = $(this);
		var is_submit = e.type === 'submit' || element.is('[type="submit"]');
		var form = element.closest('form');
		var includeData = $('#' + form.data('include-data-from'));

		// Let event pass through if form has validation errors. This will allow
		// native form validation error messages to be displayed.
		if (is_submit && !form[0].checkValidity() && !element.attr('formnovalidate')) {
			return;
		}

		// Abort any earlier unfinished requests to avoid loading
		// outdated data.
		if (element.data('request-in-progress') &&
			element.data('request-in-progress').abort) {
			element.data('request-in-progress').abort();
		}

		var ids = (
			element.closest('[data-update-targets]').data('update-targets') || ''
		).split(/\s+/);

		var targets = $(ids
			.filter(function (x) { return !!x; })
			.map(function (x) { return '#' + x; })
			.join(', ')
		);

		if (targets.length) {
			targets
				// Disable any transitions to avoid collission with fadeIn()
				.attr('aria-live', 'polite');

			e.preventDefault();

			targets.each(function () {
				$(this).addClass('is-loading');
			});

			var ajax_options = {};

			if (is_submit) {
				ajax_options.url  = form.attr('action');
				ajax_options.type = form.attr('method') || 'post';
				ajax_options.data = form.serializeArray().concat(includeData.serializeArray() || []);

				if (element.is('[type="submit"][name]')) {
					// Include value from button
					ajax_options.data.push({
						name: element.attr('name'),
						value: element.val()
					});
				}
			}
			else {
				ajax_options.url = element.attr('href');
			}

			var request = getFragments(ajax_options, ids)
				.done(function (updated_elements) {
					targets.each(function () {
						var target = $(this);

						target
							.empty()
							.append(
								updated_elements.filter('#' + target.attr('id')).contents()
							)
							.removeClass('is-loading');

						target.trigger('newcontent');
					});

					if (
						element.data('update-url') === true &&
						window.history && window.history.replaceState
					) {
						try {
							var url = new URL(ajax_options.url, document.location);
						}
						catch (err) { return; }

						if (is_submit && ajax_options.type === 'get') {
							ajax_options.data.forEach(function (v, k) {
								url.searchParams.set(k, v);
							});
						}

						url.hash = '';
						window.history.replaceState('', '', url.toString());
					}

					element.removeData('request-in-progress');
				});

			element.data('request-in-progress', request);
		}
	}
});
