// const { event } = require("grunt");

/* globals Modernizr, overthrow */
$(function () {
	'use strict';

	var header = $('.header > .group-inner');

	// Mobile menu
	var logo = header.find('.header-logo-wrapper');
	var language = header.find('.language');
	var search_form = header.find('.search');
	var header_micro_bar = header.find('.header-micro-bar');
	var search_field = header.find('.search-field');
	var nav_main = header.find('.nav-main');
	var nav_main_clone;
	var nav_sub_clone;
	var nav_sub = $('.nav-sub > ul');
	var cart_account = $('.header-cart-account');
	var headerBar = $('.headerbar__content');

	var extra_header = $('.extra-header');
	var extra_header_nav = extra_header.find('.extra-header-nav ul');
	var extra_header_li;

	var sub_header_navigation = $('.c-sub-header-navigation');
	var sub_header_navigation_li;

	// var starting_position = $('.header').outerHeight(true);
	var html = $('html');
	var menu_wrapper;
	var menu_toggler;

	// sticky buttons container
	var sticky_container;

	// If microsite header, change nav_main
	if ($('.header-micro').length) {
		nav_main = header.find('.header-micro-nav');
	}

	// If cart row is present, change position of menu_toggler
	if (cart_account.length || headerBar.length) {
		var bar = cart_account.length ? cart_account : headerBar;
		var original_offset;
		$(window).on('scroll', function () {
			if (menu_toggler && menu_toggler.length && menu_toggler.is(':visible')) {
				var win_top = $(window).scrollTop();
				var barHeight = bar.outerHeight();

				if (!original_offset) {
					original_offset = sticky_container.offset().top - win_top;
				}

				if (win_top === 0) {
					sticky_container.removeAttr('style');
					console.log('en fin ifsats');
				}
				else if (win_top < barHeight) {
					sticky_container.css('top', original_offset - win_top);
					console.log('en ifsats');
				}
				else if (win_top > barHeight) {
					sticky_container.css('top', original_offset - barHeight);
					console.log('en annan ifsats');
				}
			}
		});
	}

	$.breakpoint((function () {
		return {
			condition: function () {
				return window.matchMedia('only screen and (max-width:900px)').matches;
			},

			first_enter: function () {

				var headerbar_push_class = '';

				if (header.closest('.header').hasClass('header-with-headerbar')) {
					headerbar_push_class = 'menu-wrapper--headerbar-push';
				}

				if (header.closest('.header').hasClass('header-with-headerbar-extra-height')) {
					headerbar_push_class = 'menu-wrapper--headerbar-large-push';
				}

				var page_theme = ($('html').hasClass('page--dark')) ? 'dark' : 'light';
				var section_class = (header.closest('[class|="section"]').hasClass('section--dark')) ? 'section--dark' : 'section--' + page_theme;

				menu_wrapper = $('<div>', {
					id: 'menu-wrapper',
					class: 'menu-wrapper ' + section_class + ' ' + headerbar_push_class,
					'aria-expanded': 'false'
				});

				if (cart_account.length || $('#headerbar').length) {
					menu_wrapper.addClass('menu-wrapper-cart');
				}

				menu_toggler = $('<button>', {
					type: 'button',
					class: 'menu-toggler button button-small',// button-style-2 button-color-2',
					html: '<span>' + netr.string.translate('header.menuToggleText') + '</span>',
					'aria-controls': menu_wrapper.attr('id'),
					'aria-pressed': 'false',
					click: function (e) {
						e.preventDefault();
						if (menu_wrapper.is('[aria-expanded="true"]')) {
							menu_toggler.attr('aria-pressed', 'false');
							menu_wrapper.attr('aria-expanded', 'false');
							$('body').removeClass('menu-active');
						}
						else {
							menu_wrapper.attr('aria-expanded', 'true').attr('tabindex', '-1').focus();
							menu_toggler.attr('aria-pressed', 'true');
							$('body').addClass('menu-active');
							if (!Modernizr.touch || overthrow.support === 'none') {
								$(window).scrollTop(0);
							}
						}
					}
				});
				
				sticky_container = $('<div>', {
					class: 'sticky-container--no-sticky', 
					//class: 'sticky-container',
					html: menu_toggler
				});

				// Make sub levels expandable
				menu_wrapper.on('click', '[data-has-children]', function (e) {
					e.stopPropagation();

					var li = $(this);

					// Do we need to fetch sub levels for this item?
					if (li.children('ul').length === 0) {
						// This one is not in use.. looks goofy with the default loading animation from is-loading.css. TODO: Fix a animation for the navigation. /MF
						// li.addClass('is-loading');

						// Build url and query
						var url = new netr.URI(li.closest('.load-children').attr('data-fetch-children-url'));
						url.query.navigationfor = li.attr('data-page-id');

						// Fetch sub levels.
						$.get(url.toString(), function (data) {
							if (data.length) {
								li.append(data);
							}
							else {
								li.removeAttr('data-has-children');
								li.removeAttr('data-expanded');
							}

							li.removeClass('is-loading');
						});
					}

					if (li.is('[data-expanded]')) {
						li.removeAttr('data-expanded');
					}
					else {
						li.attr('data-expanded', '');
					}
				});
			},

			enter: function () {
				nav_main_clone = nav_main.clone();
				nav_main.hide();
				language.detachWithPlaceholder();

				// Change class if microsite header
				if (nav_main_clone.is('.header-micro-nav')) {
					nav_main_clone
						.removeClass('header-micro-nav')
						.addClass('nav-main');
				}

				// Extra header
				if (extra_header.length) {
					extra_header_li = addMenuItem(extra_header_nav, nav_main_clone.find('> ul'));
				}

				if (sub_header_navigation.length) {
					sub_header_navigation_li = addMenuItem(
						sub_header_navigation,
						nav_main_clone.find('> ul'),
						{
							navigationList: '.c-sub-header-navigation__items ul',
							topLink: '.c-sub-header-navigation__header > a'
						}
					);
				}

				menu_wrapper.append(language, nav_main_clone);

				header_micro_bar.find('.sticky-container--no-sticky, .sticky-container').remove();

				if (search_form.length) {
					search_form.before(sticky_container);
				}
				else if (logo.length) {
					logo.after(sticky_container);
				}
				else {
					header.append(sticky_container);
				}

				var expanded_item = menu_wrapper.find('li[data-selected]:first');
				nav_sub_clone = nav_sub.clone();

				nav_sub.hide();

				// Check if we should we append the sub menu to a
				// particular <li> in nav-main or not
				if (expanded_item.length && !extra_header.length && !sub_header_navigation.length) {
					var link_1_id = expanded_item.data('page-id');
					var link_2 = nav_sub_clone.find('li:first');

					if ((typeof link_1_id !== 'undefined') && (link_1_id === link_2.data('page-id'))) {
						nav_sub_clone = nav_sub_clone.find('li:first');
						expanded_item.replaceWith(nav_sub_clone);
					}
					else {
						expanded_item.removeAttr('data-selected').attr('data-expanded', '');
						nav_sub_clone.appendTo(expanded_item);
					}
				}
				else if ((expanded_item.length) && (extra_header.length)) {
					extra_header_nav.find('[data-selected]').replaceWith(nav_sub_clone);
				}
				else {
					// Only one item should be selected, so we remove all selected items in the navigation
					// before adding the sub-navigation.
					unSelectMenuItems(nav_main_clone);
					nav_sub_clone.appendTo(nav_main_clone);
				}

				menu_wrapper.attr('aria-expanded', 'false').appendTo('body');
			},

			exit: function () {
				nav_main.show();
				nav_sub.show();

				console.log(sticky_container);
				nav_main_clone.remove();
				nav_sub_clone.remove();

				if (extra_header_li) {
					extra_header_li.hide();
				}

				if (sub_header_navigation_li) {
					// sub_header_navigation_li.hide();
				}

				language.attachToPlaceholder();

				sticky_container.detach();
				menu_wrapper.detach();

				$(window).off('scroll.stickymenu');
				html.css('padding-top', '0').removeClass('sticky-menu').removeClass('sticky-menu-animation-end').removeClass('sticky-menu-hide');
			}
		};
	}()));

	// Show search button if there's text in the search field
	search_field.on('keyup', function () {
		if (search_field.val().length > 0) {
			search_form.addClass('has-text');
		}
		else {
			search_form.removeClass('has-text');
		}
	});

	// Remove placeholder in search field for very small screens
	if (window.matchMedia('only screen and (max-width:380px)').matches) {
		if (search_field.length) {
			search_field.removeAttr('placeholder');
		}
	}

	/**
	 * Add menu items from another navigation to mobile navigation.
	 * @param navigation {jQueryElement} Element/navigation that contains the menu items to be added.
	 * @param addTo {jQueryElement} The element to add menu items to.
	 * @param optionsOverides {Object} Options
	 * @param optionsOverides.navigationList {String} Selector for the list of links.
	 * @param optionsOverides.topLink {String} Selector for the link to be used at root level.
	 */
	function addMenuItem(navigation, addTo, optionsOverides) {
		var defaultOptions = {
			navigationList: '.extra-header-nav ul',
			topLink: 'h1 a'
		};
		var options = $.extend({}, defaultOptions, optionsOverides);

		var newMenuItem = $('<li>', {
			'data-expanded': '',
			'data-has-children': ''
		});

		var navigationClone = navigation.clone();
		var navigationList = navigationClone.find(options.navigationList);
		var top_link = navigationClone.find(options.topLink);

		newMenuItem.append(top_link, navigationList);
		addTo.append(newMenuItem);
	}

	/**
	 * Unselect all menu items.
	 * @param navigation {jQueryElement} navigation to remove unselect items in.
	 */
	function unSelectMenuItems(navigation) {
		navigation
			.find('[data-selected]')
			.removeAttr('data-selected')
			.removeClass('selected');
	}
	
	$('.header-fmsandviken li').click(function (e) {
		if (e.target !== e.currentTarget) return;
		$(this).children('a')[0].click();
	});
});