$(function () {
	'use strict';

	var breadcrumbs = $('.breadcrumbs[role="navigation"]');

	$.breakpoint((function () {
		return {
			condition: function () {
				return window.matchMedia('only screen and (max-width:500px)').matches;
			},
			enter: function () {
				breadcrumbs.detachWithPlaceholder();
				$('.footer').before(breadcrumbs);
				breadcrumbs.show();
			},
			exit: function () {
				breadcrumbs.attachToPlaceholder();
			}
		};
	}()));
});