/* global icon, documentReadyFunctions */
(function ($) {
	'use strict';

	var focusableElementSelector = '[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex]:not([tabindex="-1"]), [contenteditable]';

	function Exp_teasers  (settings) {
		// Default settings to a empty object
		settings = settings || {};

		// Default settings
		var defaults = {
			element: null, // The main element
			exp_object: '.teaser', // Objects in the expandable wrapper
			exp_object_active_state: 'teaser--is-active', // Objects active state
			exp_object_inactive_state: 'teaser--inactive', // Objects inactive state
			desktop_cols: 3, // Wrap this many cols in desktop view
			mobile_cols: 1 // Wrap this many cols in mobile view
		};

		// Merge settings
		this.settings = $.extend(defaults, settings);

		// Set the main element
		this.element = this.settings.element;

		// If no main element is given, die!
		if (!this.element) {
			console.warn('No main element is given.');
			return;
		}

		// Initialize the component
		this._init();
	}

	Exp_teasers.prototype = {

		_init: function () {
			var self = this;

			// Get all columns
			this.cols = this.element.find(this.settings.exp_object);

			// Create the quickview element
			this.quickView = $('<div>', {
				'class': 'exp-teasers__quick-view',
				'tabindex': '-1',
				'id': 'exp-quickview'
			});

			// Register the breakpoint behavor
			this._registerBreakpoints();

			// Get all teaser items
			this.teaserLink = this.element.find(this.settings.exp_object + ' a');
			this.teaserLink.attr('role', 'button');
			this.teaserLink.attr('aria-expanded', 'false');
			this.teaserLink.attr('aria-controls', 'exp-quickview');

			this.teaserLink.on('keypress', function (e) {
				// When press space
				if (e.which === 32) {
					e.preventDefault();
					self.open($(this).closest(self.settings.exp_object));
				}
			});

			// Handle event when clicking a teaser item
			this.teaserLink.on('click', function (e) {
				e.preventDefault();
				self.open($(this).closest(self.settings.exp_object));
			});
		},

		/**
		 * Add wrapper divs
		 */
		_addWrappers: function (numberOfCols) {
			for (var i = 0; i < this.cols.length; i += numberOfCols) {
				this.cols.slice(i, i + numberOfCols).wrapAll('<div class="exp-teasers__wrapper"></div>');
			}
		},

		/**
		 * Remove wrapper divs
		 */
		_removeWrappers: function () {
			if ($('.exp-teasers__wrapper').length !== 0) {
				this.cols.unwrap();
			}
		},

		/**
		 * Move the quickview to the nearest wrapper div
		 */
		_rearrangeQuickView: function () {
			if (this.quickView.is(':visible')) {
				var wrapper = this.element.find('.' + this.settings.exp_object_active_state).closest('.exp-teasers__wrapper');
				wrapper.append(this.quickView);
			}
		},

		/**
		 * Register the breakpoint behavor
		 * @return {[type]} [description]
		 */
		_registerBreakpoints: function () {
			var self = this;

			$.breakpoint((function () {
				return {
					condition: function () {
						return window.matchMedia && window.matchMedia('only screen and (min-width: 700px)').matches;
					},
					enter: function () {
						self._removeWrappers();
						self._addWrappers(self.settings.desktop_cols);
					},
					exit: function () {
					}
				};
			}()));

			$.breakpoint((function () {
				return {
					condition: function () {
						return window.matchMedia && window.matchMedia('only screen and (max-width: 699px)').matches;
					},
					enter: function () {
						self._removeWrappers();
						self._addWrappers(self.settings.mobile_cols);
						self._rearrangeQuickView();
					},
					exit: function () {

					}
				};
			}()));
		},

		/**
		 * Hide the quick view
		 * @param  {Function} callback
		 */
		_hideQuickView: function (callback) {
			var self = this;
			var activeItem = this.element.find('.' + this.settings.exp_object_active_state + ' a');
			this.element.find('.' + this.settings.exp_object_inactive_state).removeClass(this.settings.exp_object_inactive_state);
			activeItem.attr('aria-expanded', 'false');
			activeItem.focus();
			var quickView = this.element.find('.exp-teasers__quick-view');
			if (quickView.length) {
				quickView.hide();
				self.quickView.remove();
				self._callCallback(callback);
			}
			else {
				self._callCallback(callback);
			}
		},

		/**
		 * Show the quick view
		 * @param  {node} trigger
		 */
		_showQuickView: function (trigger) {
			var self = this;
			var colWrapper = trigger.closest('.exp-teasers__wrapper');
			var triggerLink = trigger.find('a');
			this.element.find('.' + this.settings.exp_object_active_state).removeClass(this.settings.exp_object_active_state);
			trigger.addClass(this.settings.exp_object_active_state);
			this.element.find(this.settings.exp_object + ':not(.' + this.settings.exp_object_active_state + ')').addClass(this.settings.exp_object_inactive_state);

			$.ajax({
				url: triggerLink.attr('href'),
				type: 'GET'
			})
				.success(function (data) {
					data = $(data).find('.exp-teasers__quick-view-inner').children();

					if (!data.length) {
						data = '<p>' + netr.string.translate('dialog.errorMessage') + '</p>';
					}
					var content = $('<div>', {
						class: 'exp-teasers__quick-view-inner'
					});
					content.html(data);
					colWrapper.append(self.quickView);
					self.quickView.html(content);
					var quickviewInner = self.quickView.find('.exp-teasers__quick-view-inner');
					quickviewInner.append('<button type="button" class="exp-teasers__quick-view-close"><span class="structural">' + netr.string.translate('dialog.close') + '</span>' + icon('remove-close-circle', '2', null, null, 'l') + '</button>');
					self.quickView.show();
					// Add the expanded-class after the quickview is shown
					setTimeout(function () {
						quickviewInner.addClass('exp-teaser__quick-view-inner--expanded');
					}, 0);
					self.quickView.focus();
					triggerLink.attr('aria-expanded', 'true');
					self._keypressHandler();
					self.quickView.find('.exp-teasers__quick-view-close').on('click', function () {
						self._hideQuickView();
						$('.' + self.settings.exp_object_active_state + ' a').focus();
						$('.' + self.settings.exp_object_active_state).removeClass(self.settings.exp_object_active_state);
					});
					documentReadyFunctions(data);
				})
				.error(function () {
					colWrapper.append(self.quickView);
					self.quickView.html('<p>' + netr.string.translate('dialog.errorMessage') + '</p>').slideDown();
				});
		},

		_getNextTeaser: function () {
			var nextElement = this.element.find('.' + this.settings.exp_object_active_state).next();

			if (nextElement.hasClass(this.settings.exp_object.substring(1, this.settings.exp_object.length))) {
				return nextElement;
			}

			var nextWrap = this.element.find('.' + this.settings.exp_object_active_state).next();

			if (nextWrap.hasClass('exp-teasers__wrapper')) {
				return nextWrap.find(this.settings.exp_object).first();
			}

			return false;
		},

		/**
		 * Get the first focusable element
		 * @return {nodelist}
		 */
		_getFirstFocusableElement: function () {
			return this.quickView.find(focusableElementSelector).first();
		},

		/**
		 * Get all focusable elements
		 * @return {nodelist}
		 */
		_getFocusableElements: function () {
			return this.quickView.find(focusableElementSelector);
		},

		_keypressHandler: function () {
			var self = this;

			$(document).on('keydown', function (e) {
				if (self.isOpen()) {
					if (e.which === 9) {
						self._controlTabFocus(e);
					}
				}
			});
		},

		_controlTabFocus: function (event) {
			var self = this;
			var focusableElements = this._getFocusableElements();
			var nextElement = this._getNextTeaser();

			if (!this.element[0].contains(document.activeElement)) {
				return true;
			}

			// Going backwards
			if (event.shiftKey && (document.activeElement === focusableElements[0] || document.activeElement === this.quickView[0] || focusableElements.length < 1)) {
				$('.' + this.settings.exp_object_active_state + ' a').focus();
				self._hideQuickView();
				$('.' + this.settings.exp_object_active_state).removeClass(this.settings.exp_object_active_state);
				event.preventDefault();
			}
			else if (!event.shiftKey && (document.activeElement === focusableElements[focusableElements.length - 1] || focusableElements.length < 1)) {
				self._hideQuickView();
				$('.' + this.settings.exp_object_active_state).removeClass(this.settings.exp_object_active_state);
				if (nextElement) {
					nextElement.find(this.settings.exp_object + ' a').focus();
					event.preventDefault();
				}
			}
		},

		/**
		 * Call callback if the callback is a function
		 * @param  {Function} callback
		 */
		_callCallback: function (callback) {
			if (typeof callback === 'function') {
				callback();
			}
		},

		isOpen: function () {
			return this.element.find('.exp-teasers__quick-view').length > 0;
		},

		open: function (item) {
			var self = this;
			// Hide the quickview if the same teaser is active
			if (item.hasClass(this.settings.exp_object_active_state)) {
				var activeElement = item;

				// Remove active state class
				activeElement.removeClass(self.settings.exp_object_active_state);

				// Hide the quickview
				self._hideQuickView();
			}
			// Open the quickview
			else {
				var trigger = item;
				// Hide all open quickviews
				self._hideQuickView(function () {
					// Show the selected quickview
					self._showQuickView(trigger);
				});
			}
		}
	};

	/**
	 * Extend jQuery prototype
	 * @return {Object} Exp_teasers  object
	 */
	$.fn.expTeaser = function () {
		$(this).each(function () {
			new Exp_teasers ({
				element: $(this)
			});
		});
	};

	// Init expTeaser
	$('.exp-teasers').expTeaser();

}(jQuery));
