$(function () {
	'use strict';

	$('[data-carbon]').each(function() {
		var ticker = $(this);
		var carbonNumber = ticker.find('.carbon-counter__number');
		var carbonSavings = ticker.data('carbon');
		var updateInterval = 8;
		var unitPerSecond = ticker.data('unit-per-second');
		// Get lang for the correct comma separation
		var lang = $('html').attr('lang');
		if (unitPerSecond != 0) {
			setInterval(function(){
				carbonSavings = carbonSavings + (unitPerSecond / updateInterval);
				carbonNumber.text(new Intl.NumberFormat(lang).format(Math.round(carbonSavings)));
			}, 1000/updateInterval);
		}
	});

	var stickyCounters;
	$.breakpoint((function () {
		return {
			condition: function () {
				return window.matchMedia('screen and (min-width:901px)').matches;
			},
			enter: function () {
				// Make counter stick to top of screen (using position:sticky if supported)
				stickyCounters = $('.carbon-counter--sticky').stickybits({useStickyClasses: true});
				stickyCounters.update();
			},
			exit: function () {
				stickyCounters.cleanup();
			}
		};
	}()));

});
