/* globals VerticalNavigation */

// eslint-disable-next-line no-unused-vars
function VerticalNavigation (element) {
	'use strict';
	var fetchUrl = element.attr('data-fetch-children-url');
	var LOADING_CLASS = 'c-vertical-navigation__toggler--is-loading';
	element.find('[data-has-children]').each(createExpandToggler);

	function createExpandToggler() {
		if (!this.getAttribute('data-page-id')) {
			return;
		}

		var $item = $(this);
		var isExpanded = $item.data('expanded');
		var $toggleButton = $('<button>', {
			html: isExpanded ? 'Minimera' : 'Expandera',
			'aria-expanded': isExpanded,
			class: 'c-vertical-navigation__toggler'
		});

		$item.find('> span').append($toggleButton);

		$toggleButton.on('click', function () {
			toggle($item, $toggleButton);
		});
	}

	function toggle($item, $button) {
		var loadingTimer;
		if (!$item.attr('data-page-id')) {
			throw new Error('missing page ID');
		}

		if ($item.find('ul').length) {
			toggleItem($item, $button);
		}
		else {
			// We don't want to show the loading indicator right away.
			loadingTimer = window.setTimeout(function () {
				$button.addClass(LOADING_CLASS);
			}, 500);

			fetchChildren($item, $button).then(function () {
				toggleItem($item, $button);
				window.clearTimeout(loadingTimer);
				$button.removeClass(LOADING_CLASS);
			});
		}
	}

	function toggleItem($item, $button) {
		var state = ($item.attr('data-expanded') == 'true') ? true : false;
		var newState = !state;

		$button.attr('aria-expanded', newState);
		$button.html(newState ? 'Minimera' : 'Expandera');
		$item.attr('data-expanded', newState);
	}

	function fetchChildren($parent, $button) {
		var pageId = $parent.attr('data-page-id');
		// Build url and query
		var url = new netr.URI(fetchUrl);
		url.query.navigationfor = pageId;
		
		// Fetch sub levels.
		return $.get(url.toString(), function (data) {
			var $data = $(data);

			if ($data.length) {
				$parent.find('> ul').remove();
				$data.find('[data-has-children]').each(createExpandToggler);
				$parent.append($data);
			}
			else {
				$parent.removeAttr('data-has-children');
				$parent.removeAttr('data-expanded');

				if ($button.length) {
					$button.remove();
				}
			}
		});
	}
}