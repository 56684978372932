$(function () {
	'use strict';

	var form = $('.listing-header-jobs form');

	form.find('button').addClass('structural');

	form.find('select').on('change', function () {
		form.find('[type="submit"]').click();
	});
});