/* globals VerticalNavigation, Collapsable, icon */
/* eslint-disable */
$(function () {
	'use strict';

	var mobileId = 'navigation-drawer';
	var searchId = 'search-drawer';
	var $html = $('html');
	var isPDF = window.location.search.substring(1) === 'html-to-pdf=true' ? true : false; //Get value after query (to test this, change the query to ?pdf)

	initStickyness();
	initMobileNavigation('#mobileButtonArea', $('.nav-sub').first());
	initSearch('#mobileSearchArea');

	function initStickyness() {
		var topHeaderBar = $('header-bar'); // If exists, always sticky.
		var unstickyPart = $('.unsticky-part'); // Not sticky, is inbetween sticky parts of header.
		var mainHeader = $('.c-header'); // if mobile: always sticky; if desktop: sticky if subHeader doesnt exist.
		var mainHeaderInner = $(".c-header__inner"); //mainHeader without subHeader inside it
		var subHeader = $('.c-sub-header-navigation').first(); // If exists, always sticky (doesnt exist on mobile).
		var mobileOverlay = $('.c-header__drawer-body .c-overlay');
		var oldHeader = $('.old-header');

		var topHeaderBarHeight = 0;

		if (oldHeader.length) { //Disable stickyness if we're using the old header and set topHeader to static to now cover the menu items
			topHeaderBar.css({
				'position': 'static',
				'margin-bottom': '20px'
			});
		} else {
			if (topHeaderBar.length) {
				//Make topHeaderBar sticky and add padding equal to its height to <html>
				topHeaderBarHeight = topHeaderBar.outerHeight(true);

				topHeaderBar.addClass('u-sticky');
				$html.addClass('have-sticky-header');
				$html.css('padding-top', topHeaderBarHeight);
				mobileOverlay.css('margin-top', topHeaderBarHeight);
			}
		}

		function toStickOrNotToStick() {
			var isMobile = !window.matchMedia('(min-width:900px)').matches;
			var stickyPart;
			var requiredScrollDistance = isMobile ? 0 : unstickyPart.outerHeight(true);
			var scrolledDistance = window.pageYOffset;

			// Define which part aside from the topHeaderBar that should be sticky
			// depending on if its mobile or desktop
			if (isMobile) {
				stickyPart = mainHeader;
			} else {
				if (oldHeader.length) { //Disable stickyness if we're using the old header
					return;
				} else {
					stickyPart = subHeader.length ? subHeader : mainHeader;
					// if subHeader is sticky, add mainHeaderInner's height to the required scroll distance
					// since it appears above subHeader.
					// <topBarHeader />
					// <unstickyPart />
					// <mainHeader />
					// <subHeader />
					requiredScrollDistance += subHeader.length ? mainHeaderInner.outerHeight(true) : 0;
				}
			}

			if (scrolledDistance >= requiredScrollDistance) {
				//Sticky

				$html.addClass('have-sticky-header');
				$html.css('padding-top', topHeaderBarHeight + stickyPart.outerHeight(true));

				stickyPart.addClass('u-sticky');
				stickyPart.css('top', topHeaderBarHeight);

				if (isMobile && subHeader.length) {
					// subHeader is never sticky in mobile, so remove class in case it went from desktop to mobile
					subHeader.removeClass("u-sticky");
				}

			} else {
				//Not sticky

				if (!topHeaderBar.length) {
					//If topHeaderBar doesnt exist, there are no sticky elements anymore
					$html.removeClass('have-sticky-header');
				}

				// reset to initial state
				$html.css('padding-top', topHeaderBarHeight);
				stickyPart.removeClass('u-sticky');
				stickyPart.css('top', 0);
			}

			if (!isMobile && stickyPart !== mainHeader) {
				// Remove u-sticky from mainHeader in case it went from desktop to mobile
				// and the stickyPart !== mainHeader because mainHeader is always sticky in mobile.
				mainHeader.removeClass("u-sticky");
				mainHeader.css('top', 0);
			}
		}
		if (!isPDF) { // Header is not visible when we're creating PDFs and therefore we don't want to apply all styles for when it's visible
			$(window).on('scroll resize', toStickOrNotToStick);
			toStickOrNotToStick();
		}
	}

	function initMobileNavigation(appendButtonSelector, subNavigation) {
		var button = $('<button>');
		var mobileDrawerElement = $('#' + mobileId);
		var mobileNavigation = mobileDrawerElement.find('.c-vertical-navigation');

		// Adding the sup-navigation
		$('.c-header-sup [data-page-id]').clone().appendTo(mobileNavigation.find('ul').first());

		// Adding the Sub header navigation
		addSubHeaderNavigation(mobileNavigation, $('.c-sub-header-navigation').first());

		// Adding Sub navigation
		addSubNavigation(mobileNavigation, subNavigation);

		// Create the vertical navigation so we can expand items and fetch sub levels.
		new VerticalNavigation(mobileNavigation);

		button
			.html(
				'<svg class="i--size-m" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 11">'
				+ '<path fill-rule="evenodd" clip-rule="evenodd" stroke="none" d="M0 0v1h18V0H0zm0 '
				+ '6h18V5H0v1zm0 5h18v-1H0v1z" /></svg><span class="structural">Menu</span>')
			.addClass('c-header__mobile-button u-mt-2@lg')
			.attr('aria-expanded', mobileDrawerElement.hasClass('is-expanded'))
			.attr('aria-controls', mobileId);

		button.on('click', function () {
			button.trigger('collapsable:action', [{ id: mobileId, action: 'toggle' }]);
			button.trigger('collapsable:action', [{ id: 'search-drawer', action: 'collapse' }]);
		});

		$(document).on('collapsable:state-change', function (event, data) {
			if (!data || !data.id) {
				return;
			}

			if (data.id === mobileId) {
				button.attr('aria-expanded', data.expanded);

				if (data.expanded) {
					button.addClass('is-active');
					$html.addClass('no-scroll');
				}
				else {
					button.removeClass('is-active');
					$html.removeClass('no-scroll');
				}
			}
		});

		new Collapsable({ element: mobileDrawerElement, id: mobileId });

		button.appendTo(appendButtonSelector);

		// We want to close the mobile navigation when the user enter the desktop breakpoint. 
		$.breakpoint((function () {
			return {
				condition: function () {
					return window.matchMedia('only screen and (min-width:900px)').matches;
				},

				enter: function () {
					$(document).trigger('collapsable:action', [{ id: 'navigation-drawer', action: 'collapse' }]);
				}
			};
		}()));
	}

	function initSearch() {
		var link = $('#header-search-toggler');
		var searchDrawerElement = $('#' + searchId);
		var $main = $('#main');
		var searchInputId = 'search';

		var closeButton = $('<button>', {
			'html': icon('remove-close', undefined, undefined, undefined, 'xs') + '<span class="structural">Close</span>',
			'type': 'button',
			'class': 'c-header-search__close'
		});

		var button = $('<button>', {
			'html': link.html(),
			'class': 'c-header__search-button',
			'type': 'button',
			'aria-expanded': searchDrawerElement.hasClass('is-expanded'),
			'aria-controls': searchId
		});

		button.on('click', function () {
			button.trigger('collapsable:action', [{ id: searchId, action: 'toggle' }]);
			button.trigger('collapsable:action', [{ id: mobileId, action: 'collapse' }]);
		});

		$(document).on('collapsable:state-change', function (event, data) {
			if (!data || !data.id) {
				return;
			}

			if (data.id === searchId) {
				if (data.expanded) {
					button.addClass('is-active');
					$main.addClass('c-backdrop-overlay');
					searchDrawerElement.find('input').first().focus();
					document.body.addEventListener('click', closeSearchDrawerWhenClickOutside);
				}
				else {
					button.removeClass('is-active');
					$main.removeClass('c-backdrop-overlay');
					document.body.removeEventListener('click', closeSearchDrawerWhenClickOutside);
				}
				button.attr('aria-expanded', data.expanded);
			}
		});

		closeButton.on('click', function () {
			button.trigger('collapsable:action', [{ id: searchId, action: 'collapse' }]);
		});

		searchDrawerElement.find('form').first().prepend(closeButton);

		function closeSearchDrawerWhenClickOutside(e) {
			if (e.target.id) {
				if (e.target.id != searchInputId && e.target.id != searchId) {
					document.body.removeEventListener('click', closeSearchDrawerWhenClickOutside);
					button.trigger('collapsable:action', [{ id: searchId, action: 'collapse' }]);
				}
			}
		}

		new Collapsable({ element: searchDrawerElement, id: searchId });
		link.replaceWith(button);
	}

	function addSubNavigation(mobileNavigation, subNavigation) {
		var selectedItem = mobileNavigation.find('li.is-selected').first();
		var subNavFirstItem = subNavigation.find('li').first().clone(); // Exists only when parent is printed on top of subnav

		if (!subNavigation.length) {
			return;
		}

		if (selectedItem.length) {
			if (selectedItem.data('page-id') === subNavFirstItem.data('page-id')) {
				selectedItem.replaceWith(subNavFirstItem);
			}
		}
		else {
			selectedItem
				.removeClass('is-selected')
				.data('expanded', false);
			mobileNavigation.find('ul').first().append(subNavFirstItem);
		}

	}

	function addSubHeaderNavigation(mobileNavigation, subHeader) {
		var selectedItem = mobileNavigation.find('li.is-selected').first();
		var itemList = subHeader.find('ul').clone();
		var pageId = subHeader.data('page-id');

		if (!subHeader) {
			return false;
		}

		if (selectedItem.length) {
			if (selectedItem.data('page-id') === pageId) {
				selectedItem.append(itemList);
				selectedItem.attr('data-expanded', true);
			}
		}
		else {
			selectedItem
				.removeClass('is-selected')
				.data('expanded', false);
			mobileNavigation.find('ul').first().append(itemList.find('li'));
		}

	}
});

/* Sup navigation */
$(function () {
	var isLoading = {
		'header-languages': false,
		'header-other-sites': false
	}
	// Languages
	sup({
		desktopLink: '#c-header__lang-link',
		mobileLink: '#mobile-navigation-language',
		desktopContainer: '#desktop-languages',
		mobileContainer: '#header-mobile-languages',
		id: 'header-languages'
	})
	// Other sites
	sup({
		desktopLink: '#c-header__sites-link',
		mobileLink: '#mobile-navigation-other-sites',
		desktopContainer: '#desktop-other-sites',
		mobileContainer: '#header-mobile-other-sites',
		id: 'header-other-sites'
	})

	/**
	 * 
	 * @param {Object} params
	 * @param {String} params.desktopLink Selector for the toggling link visible on desktop.
	 * @param {String} params.mobileLink Selector for the toggling link visible on mobile.
	 * @param {String} params.desktopContainer Selector for the container visible on desktop.
	 * @param {String} params.mobileContainer Selector for the container visible on mobile.
	 * @param {String} params.id The Id used with collapsable object.
	 */
	function sup(params) {
		var desktopLink = $(params.desktopLink);
		var desktopContainer = $(params.desktopContainer);
		var mobileLink = $($('.mobile-language-helper ' + params.mobileLink)[1]);

		// var mobileLink = $(params.mobileLink);
		var mobileContainer = $(params.mobileContainer);
		var id = params.id;
		var siblingId = (id === 'header-languages') ? 'header-other-sites' : 'header-languages';

		var desktopButton = $('<button>', {
			'html': desktopLink.html(),
			'class': 'link',
			'type': 'button',
			'aria-expanded': desktopContainer.hasClass('is-expanded'),
			'aria-controls': id
		});

		var mobileButton = $('<button>', {
			'html': mobileLink.html(),
			'class': 'link',
			'type': 'button',
			'aria-expanded': mobileContainer.hasClass('is-expanded'),
			'aria-controls': id
		});

		var buttons = $().add(desktopButton).add(mobileButton);

		mobileContainer.addClass('c-collapsable');
		desktopLink.replaceWith(desktopButton);
		mobileLink.replaceWith(mobileButton);

		buttons.on('click', function () {
			var button = $(this);

			button.trigger('collapsable:action', [{ id: siblingId, action: 'collapse' }]);
			button.trigger('collapsable:action', [{ id: id, action: 'expand' }]);

			addLangContent(id, desktopContainer, mobileContainer);

			event.preventDefault();
		});

		$(document).on('collapsable:state-change', function (event, data) {
			if (!data || !data.id) {
				return;
			}

			if (data.id === id) {
				if (data.expanded) {
					desktopButton.addClass('is-selected');
				}
				else {
					desktopButton.removeClass('is-selected');
				}

				desktopButton.attr('aria-expanded', data.expanded);
			}
		});

		new Collapsable({ element: desktopContainer, id: id });
		new Collapsable({ element: mobileContainer, id: id });
	}

	function addLangContent(id, desktopContainer, mobileContainer) {
		if (isLoading[id] === true) {
			return;
		}
		isLoading[id] = true;

		mobileContainer = mobileContainer.find('.group-inner').first();

		mobileContainer.addClass('is-loading');
		desktopContainer.addClass('is-loading');
		var url = desktopContainer.data('fetch-url');

		var closeButton = $('<button>', {
			'html': icon('remove-close', undefined, undefined, undefined, 'xs') + '<span class="structural">Close</span>',
			'type': 'button',
			'class': 'infobox-close'
		});

		$(document).on('click', '.unsticky-part .infobox-close, .c-header .infobox-close', function () {
			$(this).trigger('collapsable:action', [{ id: id, action: 'collapse' }]);
		});

		$.get(
			url,
			function (data) {
				var $data = $(data);
				$data.prepend(closeButton);
				desktopContainer.append($data);
				mobileContainer.append($data.clone());
				mobileContainer.removeClass('is-loading');
				desktopContainer.removeClass('is-loading');
			}
		);
	}
});