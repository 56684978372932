(function ($) {
	'use strict';
	$.fn.displayLoaderOnNavigation = function () {
		function displayLoader() {
			$('body').addClass('is-loading').append($('<div>', {
				'class': 'c-loading-message',
				'aria-live': 'polite',
				'aria-label': 'Page loading, please wait.'
			}));

			// Remove the loader when the page is unloading to prevent
			// browsers from displaying it when the user navigates back
			// to the same page with the browser back button.
			window.addEventListener('pagehide', function() {
				$('body').removeClass('is-loading');
				$('.c-loading-message').remove();
			});
		}
		return this.each(function () {
			$(this).on($(this).is('form') ? 'submit' : 'click', displayLoader);
		});
	};
}(jQuery));