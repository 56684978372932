// Collapsable
(function () {
	'use strict';

	var items = $('[data-collapsable]');
	var	item;
	var original = [];

	$.breakpoint((function () {
		return {
			condition: function () {
				return window.matchMedia && window.matchMedia('only screen and (max-width: 900px)').matches;
			},

			enter: function () {
				items.each(function (index) {
					item = $(this);
					if (!item.hasClass('exp-section')) {
						original[index] = item.html();
						item.addClass('exp-section');
						if (item.data('init-collapsed')) {
							item.addClass('collapsed');
						}
						item.wrapInner('<div class="exp-section-content" />');
						item.find('[data-collapsable-heading]').insertBefore(item.find('.exp-section-content'));

						// Expandable sections
						if (typeof item.collapsableSection !== 'undefined') {
							item.collapsableSection({
								headingSelector: '[data-collapsable-heading]',
								contentSelector: '.exp-section-content',
								contentIdBase: 'collapsable-section-',
								collapsedClass: 'collapsed',
								expandedClass: 'expanded'
							});
						}
					}
				});
			},

			exit: function () {
				items.each(function (index) {
					item = $(this);
					if (item.hasClass('exp-section')) {
						item.html(original[index]).removeClass('exp-section collapsed');
					}
				});
			}
		};
	}()));
}());