
var originalDay = '';
var days = [];
setTimeout(function () {
	var previousTimezone = $('#timeZoneSelection').val();

	$('#timeZoneSelection').change(function () {

		var select = this;
		var newtimezoneOffsetHours = select.value.split(':')[0];
		var newtimezoneOffsetMinutes = select.value.split(':')[1];

		if (newtimezoneOffsetHours < 0) {
			newtimezoneOffsetMinutes = -newtimezoneOffsetMinutes;
		}

		$('time').each(function (index) {
			var originalTime = this.innerHTML;
			if (originalTime.indexOf('1 Day')) {
				originalTime = originalTime.substring(0, 5);
			}

			var hours = originalTime.split(':')[0];
			var minutes = originalTime.split(':').length > 0 ? originalTime.split(':')[1] : '0';
			var dayToUse = days[index] == undefined ? 2 : days[index];
			
			var oldTime = new Date(1, 1, dayToUse, hours, minutes);

			var oldDay = oldTime.getDate();

			if (originalDay == '') {
				originalDay = oldDay;
			}
			
			var neutralTimeUsedForDecidingNewTime = generateNeutralTimeUsedForDecidingNewTime(oldTime, previousTimezone);

			var newTime = generateNewTime(neutralTimeUsedForDecidingNewTime, newtimezoneOffsetHours, newtimezoneOffsetMinutes);
			
			if (days[index] == undefined) {
				days.push(newTime.getDate());
			}
			else {
				days[index] = newTime.getDate();
			}
			
			var extraDayString = generateExtraDayString(newTime);

			var newHours = newTime.getHours();
			var newMinutes = newTime.getMinutes();
			this.innerHTML = (newHours < 10 ? '0' : '') + newHours + ':' + (newMinutes < 10 ? '0' : '') + newMinutes + extraDayString;

			//	 return false;
		});

		previousTimezone = this.value;
	});

}, 500);

function generateNeutralTimeUsedForDecidingNewTime(oldTime, previousTimezone) {
	var datetoreturn = oldTime.changeHoursToNeutralTimezone(previousTimezone);
	return datetoreturn;
}

function generateNewTime(neutralTimeUsedForDecidingNewTime, newtimezoneOffsetHours, newtimezoneOffsetMinutes) {
	var datetoreturn = neutralTimeUsedForDecidingNewTime;
	datetoreturn.alterTime(newtimezoneOffsetHours, newtimezoneOffsetMinutes);
	return datetoreturn;
}

function generateExtraDayString(newTime) {
	var extraDayString = '';
	var newDay = newTime.getDate();

	if (newDay > originalDay) {
		extraDayString = ' +1 Day';
	}
	else if (newDay < originalDay) {
		extraDayString = ' -1 Day';
	}
	return extraDayString;
}

Date.prototype.changeHoursToNeutralTimezone = function (previousTimezone) {
	var h = parseInt(previousTimezone.split(':')[0]);
	var m = parseInt(previousTimezone.split(':')[1]);

	if (h < 0) {
		m = -m;
	}
	this.setTime(this.getTime() - (h * 60 * 60 * 1000) - (m * 60 * 1000));

	return this;
};

Date.prototype.alterTime = function (h, m) {
	this.setTime(this.getTime() + (h * 60 * 60 * 1000) + (m * 60 * 1000));
	return this;
};