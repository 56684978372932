// This solution is tested on a regular page for Group
$(function () {
	'use strict';
	
	function scrollToContent(destination, headerHeight) {
		$('html, body').animate({
			scrollTop: destination.offset().top - headerHeight
		}, 750, 'easeInOutQuad');
	}

	$('.alphabetical-navigation a').click(function() {
		var link = $(this).attr('href');
		var destination = $(link); // Find element with the same id as the links href attribute
		var headerHeight = $('.c-header').outerHeight();
		
		scrollToContent(destination, headerHeight);
	});
});