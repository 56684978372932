$(function () {
	'use strict';

	// Larger click area
	$(document).on('newcontent', function (event) {
		var $context = $(event.target);

		$context
			.find('.teaser, .icon-teaser')
			.not('.exp-teasers .teaser')
			.each(function () {
				var teaser = $(this);
				// This should not happen if the only link in the teaser is for the video
				if (teaser.find('a').not('.youtube-thumb').length === 1) {
					teaser.addClass('teaser-clickable');
				}

				teaser.on('mouseup', function (e) {
					if (teaser.is('.teaser-clickable') && e.which !== 3) {
						if (!$(e.target).is('a') && $(e.target).parents('a').length === 0) {
							if (
								!teaser.find(
									'.open-in-dialog, .around-the-globe-link, .youtube-thumb'
								).length
							) {
								teaser.find('a').smartclick(e);
							}
							else {
								teaser.find('a').click();
							}
						}
					}
				});
			});
	});

	// Add class to images in teaser-floated-image that are
	// more portrait than landscape in their width/height ratio
	$.breakpoint(
		(function () {
			var meta = $(
				'.teaser-floated-image:not(.teaser-floated-image-square) .teaser-content .date-category'
			);

			return {
				condition: function () {
					return window.matchMedia('only screen and (max-width:400px)').matches;
				},
				enter: function () {
					var total_length = $('.teaser-floated-image img').length;
					var square_length = 0;

					$('.teaser-floated-image img').each(function () {
						var image = $(this);
						var ratio = 1.2;

						if (
							image.width() > 0 &&
              typeof image.attr('width') !== 'undefined' &&
              image.attr('width').length !== 0
						) {
							if (
								parseInt(image.attr('width'), 10) /
                  parseInt(image.attr('height'), 10) <
                ratio
							) {
								square_length++;
							}
						}
						else {
							if (image.width() / image.height() < ratio) {
								square_length++;
							}
						}
					});

					if (square_length > total_length / 2) {
						$('.teaser-floated-image').addClass('teaser-floated-image-square');
					}

					// Move meta to above image
					meta.each(function () {
						var $this = $(this);
						$this.parents('.teaser').find('.teaser-heading').after($this);
					});
				},
				exit: function () {
					$('.teaser-floated-image-square').removeClass(
						'teaser-floated-image-square'
					);

					meta.each(function () {
						var $this = $(this);
						$this.parents('.teaser').find('.teaser-content').prepend($this);
					});
				},
			};
		})()
	);
});
