var BarConverter = {
	init: function () {
		'use strict';

		var self = this;
		this.form = $('.bc-form');
		this.steel_selector = $('.steel-selector');
		this.steel_selector.inner = this.steel_selector.find('.steel-selector-inner');

		this.form.on('submit', function (e) {
			e.preventDefault();

			var submit_btn = self.form.find('[type="submit"]');
			self.form.off('submit');

			setTimeout(function () {
				submit_btn.click();
			}, 1500);
		});

		$('.unit-switch').on('change', function (e) {
			var target = e.target;

			if (target.checked && target.value) {
				self.setUnit(target.value);
			}
		});

		// $(window).on('load resize', function () {
		// 	self.justifyCols();
		// });

		// this.handleSteelSelectorShadows();
		this.handleSteelSelectorMode();
	},

	setUnit: function (unit) {
		'use strict';

		var units = $('.unit');
		var currency_choser = $('#currency');

		if (unit === 'mm') {
			units.html('mm');
			currency_choser.val('eur').trigger('change');
		}
		else {
			units.html('in');
			currency_choser.val('usd').trigger('change');
		}
	},

	justifyCols: function () {
		'use strict';

		var self = this;
		var right_col_height = $('.sub-input').innerHeight();

		self.steel_selector.inner.css('max-height', (right_col_height - $('.sub-input h3').outerHeight(true)));

		if (self.steel_selector.inner.height() <= self.steel_selector.height()) {
			self.steel_selector.inner.addClass('no-shadows');
		}
		else {
			self.steel_selector.inner.removeClass('no-shadows');
		}
	},

	handleSteelSelectorShadows: function () {
		'use strict';

		var self = this;

		this.steel_selector.inner.on('scroll', function () {
			if (self.steel_selector.inner.scrollTop() > 0) {
				self.steel_selector.addClass('shadow-top');
			}
			else {
				self.steel_selector.removeClass('shadow-top');
			}

			if (self.steel_selector.inner.scrollTop() + 1 >= $(this).find('.steel-selector-inner-inner').outerHeight() - self.steel_selector.outerHeight()) {
				self.steel_selector.addClass('no-shadow-bottom');
			}
			else {
				self.steel_selector.removeClass('no-shadow-bottom');
			}
		});
	},

	handleSteelSelectorMode: function () {
		'use strict';

		var self = this;

		$.breakpoint((function () {
			var steel_toggler;
			var steel_description;
			var dialog;

			return {
				condition: function () {
					return window.matchMedia('only screen and (max-width:700px)').matches;
				},

				enter: function () {
					if (!steel_toggler) {
						var selected_steel_type = self.steel_selector.find(':checked + label span').first().text();

						steel_toggler = $('<button>', {
							'class': 'link steel-toggler',
							'type': 'button',
							'html': '<span>' + selected_steel_type + '</span>',
							'click': function (e) {
								e.stopPropagation();

								dialog = $.netrdialog();
								dialog.setContent(self.steel_selector.detachWithPlaceholder());
								dialog.open();
							}
						});
					}

					if (!steel_description) {
						var selected_steel_description = self.steel_selector.find(':checked + label').clone();
						selected_steel_description.find('span').remove();

						steel_description = $('<div>', {
							'class': 'steel-description',
							'html': '<p>' + selected_steel_description.html() + '</p>'
						});
					}

					self.steel_selector.before(steel_toggler, steel_description);

					$(document).on('close.netrdialog', function () {
						self.steel_selector.attachToPlaceholder();
					});

					$(document).on('change.steel', self.steel_selector.find(':checkbox'), function () {
						steel_toggler.find('span').text(self.steel_selector.find(':checked + label').find('span').first().text());

						var selected_steel_description = self.steel_selector.find(':checked + label').clone();
						selected_steel_description.find('span').remove();

						steel_description.find('p').html(selected_steel_description.html());

						if (dialog) {
							dialog.close();
						}
					});
				},

				exit: function () {
					steel_toggler.detach();
					steel_description.detach();

					if (dialog) {
						dialog.close();
					}

					$(document).off('change.steel');
				}
			};
		}()));
	}
};

$(function () {
	'use strict';

	if ($('.bc-form').length) {
		BarConverter.init();
	}
});