(function () {
	'use strict';

	var CarouselBig = {
		init: function () {
			var self = this;

			this.slides_wrapper = $('.carousel-big-slides');
			this.slides = this.slides_wrapper.find('.carousel-big-slide');
			this.navigation = $('<ul>', {
				'class': 'carousel-big-slides-nav'
			});

			this.slides.not(':first').hide();

			// Create navigation
			if (this.slides.length > 1) {
				this.slides_wrapper.addClass('carousel-big-slides-initiated');
				this.slides.each(function (slideIndex) {
					var slide = $(this);
					var li = $('<li>');
					var button = $('<button>', {
						'type': 'button',
						'class': 'link title-tooltip',
						'title': slide.find('h2, h3').text(),
						'html': '<span class="structural">Slide ' + (slideIndex + 1) + '</span>',
						'click': function (e) {
							e.preventDefault();
							self.changeSlide(false, slideIndex);
							// clearInterval(interval);
						}
					});

					var img = $('<img />', {
						'src': slide.data('thumb'),
						'alt': '',
						'width': '66',
						'height': '41'
					});

					if (slideIndex === 0) {
						li.addClass('active');
					}

					button.append(img);
					li.append(button);
					self.navigation.append(li);
				});

				if (!window.matchMedia('only screen and (max-width:700px)').matches) {
					this.slides.first().find('.carousel-big-slide-content').append(this.navigation);
				}
				else {
					this.navigation.prependTo(this.slides_wrapper.parent());
				}

				// Auto-change slide
				// var interval = setInterval(function () {
				// 	self.changeSlide();
				// }, this.slides_wrapper.data('interval'));
			}

			if (this.slides.length > 1) {
				this.checkHeight();
				this.handleSwipe();
			}

			this.do_resize = true;

			$(window).on('resize.bigcarousel', function () {
				if (self.do_resize) {
					var current_slide = self.slides.filter(':visible');
					var current_slide_content = current_slide.find('.carousel-big-slide-content');

					current_slide_content
						.css('min-height', '0')
						.css('min-height', current_slide.height() - self.getPadding(current_slide_content));

					self.slides_wrapper.css('min-height', current_slide.height());

					if (window.matchMedia('only screen and (max-width:700px)').matches) {
						self.navigation.prependTo(self.slides_wrapper.parent());
					}
					else {
						current_slide.find('.carousel-big-slide-content').append(self.navigation);
					}

					self.do_resize = false;

					setTimeout(function () {
						self.do_resize = true;
					}, 100);
				}
			});
		},

		changeSlide: function (isSwipeRight, slideIndex) {
			var self = this;
			var current_slide = this.slides.filter(':visible');
			var current_nav_item = this.navigation.find('.active');

			this.hideSlide(current_slide);
			current_nav_item.removeClass('active');

			setTimeout(function () {
				if ((typeof slideIndex === 'undefined') && !isSwipeRight) {
					if (current_slide.next().is('.carousel-big-slide')) {
						// Change to next slide…
						self.showSlide(current_slide.next());
						current_nav_item.next().addClass('active');
					}
					else {
						// …or first when on last slide
						self.showSlide(self.slides.eq(0));
						self.navigation.find('li').first().addClass('active');
					}
				}
				else if (isSwipeRight) {
					if (current_slide.prev().is('.carousel-big-slide')) {
						// Change to prev slide…
						self.showSlide(current_slide.prev());
						current_nav_item.prev().addClass('active');
					}
					else {
						// …or last when on first slide
						self.showSlide(self.slides.last());
						self.navigation.find('li').last().addClass('active');
					}
				}
				else {
					// Change to a specific slide
					self.showSlide(self.slides.eq(slideIndex));
					self.navigation.find('li').eq(slideIndex).addClass('active');
				}
			}, 700);
		},

		hideSlide: function (slide) {
			slide.animate({
				opacity: 0
			}, 300, function () {
				slide.hide();
			});
		},

		showSlide: function (slide) {
			var slide_content = slide.find('.carousel-big-slide-content');

			slide.css('opacity', '0').css('display', 'block').animate({
				opacity: 1
			});

			this.slides_wrapper.animate({
				minHeight: slide.outerHeight()
			}, 300, 'easeInOutQuad');

			slide_content.css('min-height', '0').css('min-height', slide.outerHeight() - this.getPadding(slide_content));

			if (!window.matchMedia('only screen and (max-width:700px)').matches) {
				this.navigation.appendTo(slide.find('.carousel-big-slide-content'));
			}
		},

		// checkHeight: function () {
		// 	// Set height of parent since we use position: absolute,
		// 	// but do it only when all images has loaded
		// 	var self = this;
		// 	var total_images = this.slides_wrapper.find('.carousel-big-image').length;
		// 	var loaded_images = 0;

		// 	this.slides_wrapper.find('.carousel-big-image').each(function () {
		// 		var image = $(this);

		// 		if (!image.height() > 0) {
		// 			image.on('load', function () {
		// 				loaded_images++;

		// 			});
		// 		}
		// 		else {
		// 			loaded_images++;
		// 		}
		// 	});

		// 	var interval = setInterval(function () {
		// 		if (loaded_images === total_images) {
		// 			self.setHeight();
		// 			clearInterval(interval);
		// 		}
		// 	}, 50);

		// 	// Fallback
		// 	setTimeout(function () {
		// 		if (self.slides_wrapper.height() < 10) {
		// 			self.setHeight();
		// 		}
		// 		clearInterval(interval);
		// 	}, 2500);
		// },

		// setHeight: function () {
		// 	var highest_height = 0;

		// 	this.slides.each(function () {
		// 		var current_slide = $(this);

		// 		if (current_slide.outerHeight() > highest_height) {
		// 			highest_height = current_slide.outerHeight();
		// 		}
		// 	});

		// 	this.slides_wrapper.css('min-height', highest_height);
		// 	this.slides.filter(':visible').find('.carousel-big-slide-content').css('min-height', highest_height);
		// },

		checkHeight: function () {
			var self = this;
			var image = this.slides_wrapper.find('.carousel-big-image').first();

			var interval = setInterval(function () {
				if (image.outerHeight() > 10) {
					self.setHeight();
					clearInterval(interval);
				}
			}, 50);

			image.on('load', function () {
				self.setHeight();
				clearInterval(interval);
			});
		},

		setHeight: function () {
			var current_slide = this.slides.filter(':visible');
			var current_slide_content = current_slide.find('.carousel-big-slide-content');

			this.slides_wrapper.css('min-height', current_slide.height());
			current_slide_content.css('min-height', current_slide.height() - this.getPadding(current_slide_content));
		},

		handleSwipe: function () {
			var self = this;

			var x_on_down = null;
			var y_on_down = null;

			this.slides_wrapper.on('touchstart.bigcarousel', function (e) {
				x_on_down = e.originalEvent.touches[0].clientX;
				y_on_down = e.originalEvent.touches[0].clientY;
			});

			this.slides_wrapper.on('touchmove.bigcarousel', function (e) {
				if (!x_on_down || !y_on_down) {
					return;
				}

				var x_on_up = e.originalEvent.touches[0].clientX;
				var y_on_up = e.originalEvent.touches[0].clientY;

				var x_diff = x_on_down - x_on_up;
				var y_diff = y_on_down - y_on_up;

				if (Math.abs(x_diff) > Math.abs(y_diff)) {
					if (x_diff > 0) {
						self.changeSlide(false);
					}
					else {
						self.changeSlide(true);
					}
				}
			});
		},

		getPadding: function (element) {
			return parseInt(element.css('padding-top'), 10) + parseInt(element.css('padding-bottom'), 10);
		}
	};

	$(function () {
		if ($('.carousel-big').length) {
			CarouselBig.init();
		}
	});
}());