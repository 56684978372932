/* globals documentReadyFunctions */
(function ($) {

	'use strict';

	var InstantSearch = function (wrapper, options) {
		var self = this;
		var instantTimeout;

		this.options = $.extend({
			// Selector for input element to attach autocomplete to
			inputSelector: '#searchtext',
			// Selector for submit button
			submitButtonSelector: 'button[type="submit"]',
			// Url to search instant search results
			searchResultsUrl: '/templates/searchinstant.aspx',
			// Content of close button
			closeButtonHTML: '<span class="structural">Avbryt sökning</span>',
			hijackSubmit: false,
			showOverlay: true,
			timeout: 300,
			hideSubmitButton: false,
			partial: true,
			includeSelects: false
		}, options || {});

		// Keep track of whether the powersearch is active or not
		this.isActive = false;

		// Get/create elements
		this.wrapper = wrapper;
		this.input = this.wrapper.find(this.options.inputSelector);
		this.submitButton = this.wrapper.find(this.options.submitButtonSelector);

		if (this.options.partial) {
			this.searchresultsBox = $('<div class="search-results-popup" tabindex="0">');
			this.searchresults = $('<div class="search-results-container">').appendTo(this.searchresultsBox);
			this.searchresultsclose = $('<a>', {
				href: '#',
				'class': 'search-results-popup-close',
				html: this.options.closeButtonHTML
			}).appendTo(this.searchresultsBox);
			this.searchresultsOverlay = $('<div class="search-results-overlay">');

			this.searchresults.keydown(function (e) {
				// Find current link
				var active = $(e.target).closest('a');
				var focusLink;

				if (e.keyCode === 27) { // ESC
					self.escape();
				}
				else if (e.keyCode === 40) { // Down arrow
					e.stopPropagation();
					e.preventDefault();
					focusLink = active.closest('li').next('li').find('a:first');
					if (focusLink.length) {
						focusLink.focus();
					}
				}
				else if (e.keyCode === 38) { // Up arrow
					e.stopPropagation();
					e.preventDefault();
					focusLink = active.closest('li').prev('li').find('a:first');
					if (focusLink.length) {
						focusLink.focus();
					}
					else {
						self.input.focus();
					}
				}
				else {
					self.input.focus();
				}
			});

			// Bind close button event handlers
			this.searchresultsclose.click(function (e) {
				e.preventDefault();
				self.escape();
				self.reset();
			});
		}

		// Disable browser autocomplete
		this.input.attr('autocomplete', 'off');

		// Get results if page loads with a value in the field
		if (this.input.val() && this.options.partial) {
			this.getSearchResults();
		}

		// Hide submit button if opton set to true
		if (this.options.hideSubmitButton) {
			this.submitButton.addClass('structural');
		}

		// Bind input event handlers
		if (self.options.partial) {
			this.input.bind({
				focus: function () {
					if (!self.isActive && self.options.partial) {
						if (self.input.val() && !(self.input.is('.placeholder') && self.input.val() === self.input.attr('placeholder'))) {
							self.showSearchResults();
							self.showSearchResultsOverlay();
							self.input.autocomplete('close');
						}
						self.isActive = true;
					}
				},
				keyup: function (e) {
					if (e.keyCode === 27) { // ESC
						self.escape();
					}
					else if (e.keyCode === 40) { // Down arrow
						e.stopPropagation();
						e.preventDefault();
						self.searchresults.find('li a:first').focus();
					}
					else {
						if (self.input.val().length >= 3) {
							clearTimeout(instantTimeout);
							instantTimeout = setTimeout(function () {
								self.showSearchResults();
								self.getSearchResults();
							}, self.options.timeout);
						}
						else {
							self.hideSearchResultsOverlay();
							self.hideSearchResults();
						}
					}
				}
			});
		}
		else {
			this.input.unbind('keyup').bind({
				keyup: function (e) {
					if (self.input.val().length >= 3 && e.keyCode !== 27 && e.keyCode !== 40) { // ESC & Down arrow
						clearTimeout(instantTimeout);
						instantTimeout = setTimeout(function () {
							self.getSearchResults();
						}, self.options.timeout);
					}
				}
			});
		}

		if (this.options.includeSelects) {
			this.wrapper.find('select').each(function () {
				$(this).bind('change', function () {
					self.getSearchResults();
				});
			});
		}

		// Bind form event handlers
		this.input.closest('form').submit(function (e) {

			if (self.options.hijackSubmit) {
				e.preventDefault();
				self.showSearchResults();
				self.getSearchResults();
				self.searchresultsBox.focus();
			}

			self.input.val(self.input.val());
		});

		$.breakpoint((function () {
			return {
				condition: function () {
					return window.matchMedia('screen and (max-width:699px)').matches && !netr.forceDesktopLayout;
				},
				enter: function () {
					self.submitButton.removeClass('button button-style-2 button-color-2 button-big').addClass('search-submit');
				},
				exit: function () {
					self.submitButton.addClass('button button-style-2 button-color-2 button-big').removeClass('search-submit');
				}
			};
		}()));
	};
	InstantSearch.prototype = {
		escape: function () {
			// Hide all elements and blur the input
			this.hideSearchResultsOverlay();
			this.hideSearchResults();
			this.input.blur();
			this.isActive = false;
		},
		reset: function () {
			// Hide search results and clear the input value
			this.hideSearchResults();
			this.input.val('');
			this.input.focus();
		},
		showSearchResults: function () {
			this.showSearchResultsOverlay();
			this.input.attr('data-instant-search-active', '');
			// Add searchresults to DOM
			this.searchresultsBox.insertAfter(this.input);
		},
		hideSearchResults: function () {
			this.input.removeAttr('data-instant-search-active');
			// Detach searchresults from DOM
			this.hideSearchResultsOverlay();
			this.searchresultsBox.detach();
		},
		showSearchResultsOverlay: function () {
			var under;
			if (this.options.showOverlay) {
				under = $('#content-2');
				this.searchresultsOverlay.height(under.height());
			}
			// Add searchresults overlay to DOM
			this.searchresultsOverlay.appendTo(under);
		},
		hideSearchResultsOverlay: function () {
			// Detach searchresults overlay from DOM
			
			this.searchresultsOverlay.detach();
		},
		getSearchResults: function (query) {
			var self = this;
			var postData = self.wrapper.serialize();

			if (query) {
				postData.q = query;
			}
			if (self.options.partial) {
				$.get(netr.string.stripHash(this.options.searchResultsUrl), postData, function (data) {
					self.searchresultsclose.detach();
					self.searchresults.html(data);
					self.searchresults.prepend(self.searchresultsclose);
				});
			}
			else {
				$.getFragment({
					url: self.wrapper.attr('action'),
					type: (self.wrapper.attr('method') || 'post'),
					data: postData,
					async: false,
					success: function (data) {
						$('.material-datasheet-search > .row:first').html($(data).find('.material-datasheet-search > .row:first').html());
						$('.datasheets-listing').html($(data).find('.datasheets-listing').html());
						$('.paging').html($(data).find('.paging').html());
						documentReadyFunctions($('#content'));
					}
				});
			}
		}
	};

	$.fn.instantsearch = function (options) {
		new InstantSearch(this, options);
	};

} (jQuery));