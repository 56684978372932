// eslint-disable-next-line no-unused-vars
var globalCarouselsInitiated=false;

/* globals icon */
$(function () {
	'use strict';
	initCarousels();
});

function initCarousels() {
	// Build custom slide navigation and init Flexslider
	$('.carousel:not(.initiated-carousel)').each(function () {
		var carousel = $(this);

		if (carousel.find('.slide').length > 1) {
			var carousel_nav = $('<ol>', {
				'class': 'carousel-nav'
			});

			carousel.find('.slide').each(function (e) {
				var $this = $(this);
				var li = $('<li>');
				var headline = $this.find('h2, h3');
				var html = headline.length ? '<span>' + headline.text() + '</span>' : '';
				var button = $('<button>', {
					'type': 'button',
					'class': 'link',
					'html': html,
					'data-icon-parent': 'data-icon-parent'
				});
				var img = $('<img />', {
					'src': $this.data('thumb'),
					'alt': 'Slide ' + (e + 1),
					'width': '82',
					'height': '51'
				});

				button.append(img);

				// Add video icon to thumbnail
				if ($this.is('[data-video-slide]')) {
					var videoIcon = icon('play-button', '2', 'white', undefined, 'l');
					button.append(videoIcon);
				}
				li.append(button);
				carousel_nav.append(li);
			});

			carousel.append(carousel_nav);
		}

		carousel.flexslider({
			manualControls: carousel.find('.carousel-nav button'),
			controlNav: true,
			slideshow: false,
			directionNav: false,
			animationSpeed: 350,
			smoothHeight: false,
			touch: true
		});

		carousel.addClass('initiated-carousel');
	});
	globalCarouselsInitiated = true;
}
